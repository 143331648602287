import React from 'react';
import Config from '../../helpers/config/GanttChartConfigs';
import './GanttChartDataTask.scss';
import { GanttChartItem } from '../../models/GanttChartItem.model';

export interface GanttChartDataTask {
    backgroundColor: string;
    color: string;
    left: number;
    width: number;
    height: number;
    item: GanttChartItem;
}
export const GanttChartDataTask = (props: GanttChartDataTask) => {
    const calculateStyle = () => {
        let configStyle = Config.values.dataViewPort.task.style;
        let backgroundColor = props.backgroundColor
            ? props.backgroundColor
            : configStyle.backgroundColor;
        let color = props.color ? props.color : configStyle.color;

        return {
            ...configStyle,
            backgroundColor,
            color,
            left: props.left,
            width: props.width,
            height: props.height,
            top: 0,
        };
    };

    let style = calculateStyle();
    const itemName = Config.values.dataViewPort.task.showLabel
        ? props.item.name
        : '';

    return (
        <div
            style={style as any}>
            <div
                style={{ overflow: 'hidden' }}
                className="gantt-chart-data-task-text"
                title={itemName}>
                {itemName}
            </div>
        </div>
    );
};
