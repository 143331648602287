import { createAsyncThunk } from '@reduxjs/toolkit';
import { DataRequestNotificationsState } from '../data-request-notifications.state';
import { resetQuestionsNotificationsByFormId } from '../data-forms-status-mapper';
import { updateTabsState } from '../project-tabs-status-mapper';

export interface ResetQuestionsSeenStatusByFormIdArguments {
    formIdToReset: number;
}

export const resetQuestionsSeenStatusByFormId = createAsyncThunk(
    'dataRequestNotifications/resetQuestionsSeenStatusByFormId',
    async (args: ResetQuestionsSeenStatusByFormIdArguments, dispatch: any) => {
        const notificationsState =
            dispatch.getState().dataRequestsNotifications;
        const result = await resetNotifications(
            args.formIdToReset,
            notificationsState
        );

        return result;
    }
);

const resetNotifications = (
    formId: number,
    state: DataRequestNotificationsState
) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            const updatedState = { ...state };

            const updatedQuestionsStatus = resetQuestionsNotificationsByFormId(
                updatedState.questionsSeenStatus,
                formId
            );
            updateTabsState(
                updatedState,
                updatedQuestionsStatus,
                updatedState.tableCellsSeenStatus,
                updatedState.tablesNotificationsStatus
            );

            updatedState.questionsSeenStatus = updatedQuestionsStatus;
            resolve(updatedState);
        });
    });
};
