import { TimelineActionStatus } from './TimelineActionStatus';
import { TimelineActionType } from './TimelineActionType';

export interface TimelineAction {
    id: number;
    actionType: TimelineActionType;
    status: TimelineActionStatus;
    referenceItemId?: number;
}

export const defaultTimelineAction: TimelineAction = {
    id: 0,
    actionType: TimelineActionType.BuyerExpressionOfInterest,
    status: TimelineActionStatus.InProgress,
};
