import React from 'react';
import Config from '../../helpers/config/GanttChartConfigs';

interface GanttChartDataRowProps {
    rowNumber?: number;
    top: number;
    itemheight: number;
    children: any;
}
export const GanttChartDataRow = (props: GanttChartDataRowProps) => {
    const backgroundColor =
        props.rowNumber % 2 === 0 || typeof props.rowNumber !== 'number'
            ? 'white'
            : '#F7F7F7';
    const borderBottom =
        typeof props.rowNumber !== 'number' ? 'solid 1px #000' : '';

    return (
        <div
            className="timeLine-main-data-row gantt-chart-data-row"
            style={{
                ...Config.values.dataViewPort.rows.style,
                top: props.top,
                height: props.itemheight,
                backgroundColor: backgroundColor,
                borderBottom: borderBottom,
            }}>
            {props.children}
        </div>
    );
};
