import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
} from 'react';
import {
    DataTableColumn,
    DataTableViewColumn,
    ViewColumnFilterData,
} from '../../../../../models/DataRequestHub/DataTable';
import {
    useGridApiRef,
    GridColDef,
    GridPreProcessEditCellProps,
    GridValidRowModel,
} from '@mui/x-data-grid';
import EditableTable from '../../../../../components/EditableTable/EditableTable';
import RowVisibilitySwitcherColumn from '../../../../../components/EditableTable/CustomColumns/RowVisibilitySwitcherColumn';
import { DataTableViewGridRow } from '../../../../../models/DataRequestHub/DataTableViewGridRow';
import {
    removeErrorClassFromCellContent,
    validateGridEditorRowDisplayOrder,
    validateRequiredCell,
} from '../../../../../components/EditableTable/EditableTableValidationHelper';
import { EditorTypeEnum } from '../../../../../models/DataRequestHub/ProjectEditorEnum';
import {
    AnswerTypeVariable,
    AnswerTypeVariableNamesList,
    FormTypeVariable,
    FormTypeVariableNamesList,
    ProjectVariable,
    YesNoDropdownOptionsList,
} from '../../../../../models/DataRequestHub/ProjectVariable';
import { Box } from '@mui/material';
import { NumericCell } from '../../../../DataRequest/DataTableForm/NumericCell/NumericCell';
import moment from 'moment';
import DisplayLogicSwitcherColumn from '../../../../../components/EditableTable/CustomColumns/DisplayLogicSwitcherColumn';
import FilterSwitcherColumn from '../../../../../components/EditableTable/CustomColumns/FilterSwitcherColumn';
import { MultiSelectEditCell } from '../../../../../components/EditableTable/CustomColumns/MultiselectInputColumn/MultiselectInputColumn';
import { ConditionValue } from '../../../../../models/DataRequestHub/ItemDisplayCondition';
import PortalTooltip from '../../../../../components/PortalTooltip/PortalTooltip';
import CustomRenderCell from '../../../../../components/EditableTable/CustomRenderCell/CustomRenderCell';

interface DataTableViewEditorProps {
    tableViewGridRows: DataTableViewGridRow[];
    setTableViewGridRows(rows: DataTableViewColumn[]): void;
    isLoading: boolean;
    notSystemVariables: ProjectVariable[];
}

const DataTableViewEditor = forwardRef(
    (props: DataTableViewEditorProps, ref) => {
        const apiRef = useGridApiRef();
        const {
            tableViewGridRows,
            setTableViewGridRows,
            isLoading,
            notSystemVariables,
        } = props;

        const gridTableRef = useRef(null);
        useImperativeHandle(ref, () => ({
            addRow() {
                gridTableRef.current.addRow();
            },
            validateAllRows() {
                return validateAllRows();
            },
            getEditorState() {
                return apiRef.current.state;
            },
        }));

        const updateRows = (
            newRows: (
                oldColumns: DataTableViewGridRow[]
            ) => DataTableViewColumn[]
        ): void => {
            const result = newRows(tableViewGridRows).map((viewRow) => ({
                ...viewRow,
            }));
            setTableViewGridRows(result);
        };

        const getRowId = (row: GridValidRowModel) => {
            return row.customColumnId;
        };

        const isColumnIncludedSwitcherColumn = RowVisibilitySwitcherColumn({
            setRows: updateRows,
            gridApiRef: apiRef,
            getRowId: getRowId,
            columnHeader: 'Column Included',
            isDataTableView: true,
        });

        const isFilterEnabledSwitcherColumn = FilterSwitcherColumn({
            setRows: updateRows,
            gridApiRef: apiRef,
            getRowId: getRowId,
        });

        const getColumnGridModelByCusomtId = (
            customColumnId: string
        ): DataTableColumn => {
            const latestStateMap = apiRef.current.getRowModels();
            const latestStateArray = Array.from(
                latestStateMap.values()
            ) as DataTableColumn[];
            return latestStateArray?.find(
                (questionModel) =>
                    questionModel.customColumnId === customColumnId
            );
        };

        const displayLogicSwitcherColumn = DisplayLogicSwitcherColumn({
            setRows: () => {},
            gridApiRef: null,
            getRowId: () => {},
            customCellClasses: 'disabled',
        });

        const getFilterValueOptions = (
            currentColumn: DataTableColumn
        ): ViewColumnFilterData[] => {
            const formType = FormTypeVariableNamesList.find(
                (f) => f.name === currentColumn?.formType
            );

            if (formType?.id === FormTypeVariable.YesNo) {
                return YesNoDropdownOptionsList.map((m) => ({
                    data: m.name,
                    id: 0,
                })).reverse();
            }

            if (
                formType?.id === FormTypeVariable.DropDown &&
                currentColumn?.formList
            ) {
                const variableOptions =
                    notSystemVariables.find(
                        (variable) => variable.name === currentColumn?.formList
                    )?.options ?? [];
                return variableOptions?.map((option) => ({
                    data: option.option,
                    id: 0,
                }));
            }

            return [];
        };

        const getCellTooltip = (
            anchorId: string,
            content: React.JSX.Element
        ) => {
            return (
                <PortalTooltip
                    anchorId={anchorId}
                    delayShow={1000}
                    place="bottom"
                    content={content}
                />
            );
        };

        const columns = (): GridColDef[] => [
            isColumnIncludedSwitcherColumn,
            {
                field: 'displayOrder',
                headerName: 'View Order',
                minWidth: 140,
                flex: 0.25,
                type: 'number',
                cellClassName: (params) => {
                    let cellClass = 'cell-input display-order';

                    if (!params.row.isVisible) {
                        cellClass = `${cellClass} disabled`;
                    }

                    return cellClass;
                },
                editable: true,
                align: 'left',
                headerAlign: 'left',
                preProcessEditCellProps: (
                    params: GridPreProcessEditCellProps
                ) => {
                    const value = params.props.value;
                    const hasError = value <= 0;
                    return { ...params.props, error: hasError };
                },
                renderCell: (params) => {
                    const displayOrderCellId = `display-order-cell-${params.id}`;

                    return (
                        <CustomRenderCell
                            {...params}
                            anchorTooltipId={displayOrderCellId}
                            renderValue={params.value}
                        />
                    );
                },
            },
            isFilterEnabledSwitcherColumn,
            {
                field: 'filterData',
                headerName: 'Filter Data',
                minWidth: 170,
                type: 'singleSelect',
                valueOptions: (params) => getFilterValueOptions(params.row),
                flex: 0.25,
                cellClassName: (params) => {
                    let cellClass = 'cell-text-input filter-data';
                    const isFilterEnabled = params.row.isFilterEnabled;
                    if (!isFilterEnabled) {
                        cellClass = `${cellClass} disabled`;
                    }

                    return cellClass;
                },
                editable: true,
                align: 'left',
                headerAlign: 'left',
                preProcessEditCellProps: (
                    params: GridPreProcessEditCellProps
                ) => {
                    const valueLenght = params.props.value?.lenght;
                    const hasError = valueLenght <= 0;
                    return { ...params.props, error: hasError };
                },
                valueFormatter: (value: ViewColumnFilterData[]) => {
                    const data = value?.map((x) => x.data);
                    return data
                        ? data
                              .sort((a, b) => {
                                  return a > b ? -1 : 1;
                              })
                              .join(', ')
                        : '';
                },
                renderCell: (params) => {
                    const filterDataCellId = `filter-data-cell-${params.id}`;

                    return (
                        <CustomRenderCell
                            {...params}
                            anchorTooltipId={filterDataCellId}
                            renderValue={params.formattedValue}
                        />
                    );
                },
                renderEditCell: (params) =>
                    MultiSelectEditCell(
                        params,
                        getFilterValueOptions(params.row)
                    ),
            },
            {
                field: 'customColumnId',
                headerName: 'C-ID',
                minWidth: 150,
                flex: 0.5,
                cellClassName: 'cell-text-input custom-column-id disabled',
                editable: false,
            },
            {
                field: 'name',
                headerName: 'Column Name',
                minWidth: 150,
                flex: 0.5,
                cellClassName: 'cell-text-input column-name disabled',
                editable: false,
                renderCell: (params) => {
                    return (
                        <Box className="MuiDataGrid-cellContent custom-render-cell">
                            <div id="mynewid">{params.value}</div>
                        </Box>
                    );
                },
            },
            {
                field: 'columnTooltip',
                headerName: 'Column Tooltip',
                minWidth: 150,
                flex: 0.5,
                cellClassName: 'cell-text-input column-tooltip disabled',
                renderCell: (params) => {
                    return (
                        <Box className="MuiDataGrid-cellContent custom-render-cell">
                            <div>{params.value}</div>
                        </Box>
                    );
                },
                editable: false,
            },
            {
                field: 'answerType',
                headerName: 'Answer Type',
                minWidth: 170,
                cellClassName: 'answer-type disabled',
                flex: 0.5,
                editable: false,
            },
            {
                field: 'formType',
                headerName: 'Form Type',
                minWidth: 170,
                cellClassName: 'form-type disabled',
                flex: 0.5,
                editable: false,
            },
            {
                field: 'formList',
                headerName: 'Form List',
                minWidth: 170,
                cellClassName: 'form-list disabled',
                flex: 0.5,
                editable: false,
            },
            displayLogicSwitcherColumn,
            {
                field: 'logicParentCustomId',
                headerName: 'Conditional Column',
                minWidth: 150,
                flex: 0.5,
                cellClassName: 'logic-parent-column-id disabled',
                type: 'singleSelect',
                editable: false,
            },
            {
                field: 'conditionValues',
                headerName: 'Condition Value',
                minWidth: 140,
                flex: 0.25,
                cellClassName: 'condition-value disabled',
                editable: false,
                align: 'left',
                headerAlign: 'left',
                valueFormatter: (value: any, params) => {
                    const currentColumn = params as DataTableColumn;
                    const parentColumnDefinition = getColumnGridModelByCusomtId(
                        currentColumn?.logicParentCustomId
                    );
                    const parentFormType = FormTypeVariableNamesList.find(
                        (x) => x.name === parentColumnDefinition?.formType
                    )?.id;

                    if (parentFormType !== FormTypeVariable.DropDown) {
                        return Array.isArray(value) ? value[0]?.data : value;
                    }

                    if (!Array.isArray(value)) {
                        return [{ id: 0, data: value }];
                    }

                    return value;
                },
                renderCell: (params) => {
                    const currentColumn = params.row as DataTableColumn;
                    const parentColumnDefinition = getColumnGridModelByCusomtId(
                        currentColumn.logicParentCustomId
                    );
                    const parentAnswerType = AnswerTypeVariableNamesList.find(
                        (type) =>
                            type.name === parentColumnDefinition?.answerType
                    )?.id;

                    switch (parentAnswerType) {
                        case AnswerTypeVariable.Number:
                            return (
                                <Box className="MuiDataGrid-cellContent custom-render-cell">
                                    <NumericCell
                                        isReadonly={true}
                                        formType={
                                            parentColumnDefinition.formType
                                        }
                                        {...params}
                                        value={params.formattedValue}
                                    />
                                </Box>
                            );
                        case AnswerTypeVariable.Date:
                            return (
                                <Box className="MuiDataGrid-cellContent custom-render-cell">
                                    <div>
                                        {params?.formattedValue
                                            ? moment(
                                                  params?.formattedValue
                                              ).format('L')
                                            : null}
                                    </div>
                                </Box>
                            );
                        case AnswerTypeVariable.PickFromList:
                            const stringArray = Array.isArray(
                                params.formattedValue
                            )
                                ? params.formattedValue?.map(
                                      (condition: ConditionValue) =>
                                          condition.data
                                  )
                                : [params.formattedValue];

                            const displayValue = stringArray.join(', ');
                            return (
                                <Box className="MuiDataGrid-cellContent custom-render-cell">
                                    <div>{displayValue}</div>
                                </Box>
                            );
                        default:
                            return (
                                <Box className="MuiDataGrid-cellContent custom-render-cell">
                                    <div>{params.formattedValue}</div>
                                </Box>
                            );
                    }
                },
            },
            {
                field: 'defaultValue',
                headerName: 'Default',
                minWidth: 140,
                flex: 0.25,
                cellClassName: 'default-value disabled',
                editable: false,
                align: 'left',
                headerAlign: 'left',
                renderCell: (params) => {
                    const currentColumn = params.row as DataTableColumn;
                    const answerType = AnswerTypeVariableNamesList.find(
                        (type) => type.name === currentColumn?.answerType
                    )?.id;

                    switch (answerType) {
                        case AnswerTypeVariable.Number:
                            return (
                                <Box className="MuiDataGrid-cellContent custom-render-cell">
                                    <NumericCell
                                        isReadonly={true}
                                        formType={currentColumn.formType}
                                        {...params}
                                    />
                                </Box>
                            );
                        case AnswerTypeVariable.Date:
                            return (
                                <Box className="MuiDataGrid-cellContent custom-render-cell">
                                    <div>
                                        {params?.value
                                            ? moment(params?.value).format('L')
                                            : null}
                                    </div>
                                </Box>
                            );
                        default:
                            return (
                                <Box className="MuiDataGrid-cellContent custom-render-cell">
                                    <div>{params.value}</div>
                                </Box>
                            );
                    }
                },
            },
        ];

        const runValidation = (
            column: DataTableViewColumn,
            element: Element
        ): boolean => {
            let isValid = true;
            const displayOrderElement = element.querySelector('.display-order');
            const filterDataElement = element.querySelector('.filter-data');
            if (
                column.isIncluded &&
                validateRequiredCell(
                    displayOrderElement,
                    column.overridenDisplayOrder
                )
            ) {
                isValid = false;
            } else {
                const cellElement = element.querySelector('.display-order');
                removeErrorClassFromCellContent(cellElement);
            }

            if (
                column.isFilterEnabled &&
                validateRequiredCell(filterDataElement, column.filterData)
            ) {
                isValid = false;
            } else {
                const cellElement = element.querySelector('.filter-data');
                removeErrorClassFromCellContent(cellElement);
            }

            return isValid;
        };

        const validateAllRows = () => {
            let isValid = true;
            for (let index = 0; index < tableViewGridRows.length; index++) {
                const rowId = tableViewGridRows[index].customColumnId;
                const element = document.querySelector(
                    '[data-id="' + rowId + '"]'
                );
                const column = tableViewGridRows[index];

                if (element) {
                    const isColumnValid = runValidation(column, element);
                    if (!isColumnValid) {
                        isValid = false;
                    }
                }
            }

            apiRef?.current?.forceUpdate();
            const visibleRows = tableViewGridRows.filter((f) => f.isVisible);
            if (
                !validateGridEditorRowDisplayOrder(
                    visibleRows,
                    apiRef.current.state,
                    EditorTypeEnum.DataTableView
                )
            ) {
                isValid = false;
            }

            return isValid;
        };

        useEffect(() => {
            validateAllRows();
        }, [tableViewGridRows]);

        return (
            <EditableTable
                editorType={EditorTypeEnum.DataTableView}
                columns={columns()}
                rows={tableViewGridRows}
                setRows={updateRows}
                isLoading={isLoading}
                fieldToFocus="overridenDisplayOrder"
                ref={gridTableRef}
                gridApiRef={apiRef}
                validateAllRows={validateAllRows}
                disableActionColumn={true}
                disableVisibilityColumn={true}
            />
        );
    }
);

export default DataTableViewEditor;
