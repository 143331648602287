import React, { useEffect, useState } from 'react';
import Rodal from '../../../../../components/Rodal/Rodal';
import { ModalBody, ModalHeader, Col, Row } from 'reactstrap';
import { Field, Form, Input } from '@availity/form';
import './ManageStageModal.scss';
import * as yup from 'yup';

interface ManageStageModalProps {
    isVisible: boolean;
    onClose: () => void;
    mode: 'create' | 'edit';
    onAddStage: (stageName: string) => Promise<boolean>;
    onUpdateStage?: (stageId: number, name: string) => Promise<boolean>;
    initialStageName?: string;
    rowId: number | null;
}

const ManageStageModal = (props: ManageStageModalProps) => {

    const [isSendingRequest, setIsSendingRequest] = useState(false);

    const validationSchema = yup.object().shape({
        stageName: yup.string().trim().required()
    });

    const isEditMode = props.mode === 'edit';

    return (
        <div className="new-stage-modal-container">
            <Rodal
                visible={props.isVisible}
                animation={'slideTop'}
                onClose={props.onClose}
                showMask={true}
                width={300}
                closeOnEsc
                className="new-stage-modal"
                center>
                <ModalBody>
                    <div className="mt-4" style={{ width: '100%' }}>
                        <Form
                            initialValues={{stageName: props.initialStageName || '' }}
                            validationSchema={validationSchema}
                            enableReinitialize
                            onSubmit={() => {}}
                        >
                            {({values, errors, touched, handleSubmit, handleChange, resetForm, isValid, dirty}) => (
                                <>
                                    <Row>
                                        <Col md={12}>
                                            <Field
                                                name="stageName"
                                                label={isEditMode ? "Edit stage name:" : "Enter the name of the new stage:"}
                                                placeholder="Name"
                                                type="text"
                                                className="form-control"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mt-2">
                                        <Col className="d-flex justify-content-between">
                                            <button
                                                disabled={isSendingRequest}
                                                className="btn btn-outline-secondary w-50 mr-2"
                                                onClick={() => {
                                                    props.onClose();
                                                    resetForm();
                                                }}
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                type="submit"
                                                className="btn btn-primary w-50"
                                                disabled={!isValid || !dirty || isSendingRequest}
                                                onClick={async (e) => {
                                                    e.preventDefault();

                                                    if (isValid && dirty) {
                                                        setIsSendingRequest(true);

                                                        let isSuccessful = false;

                                                        if (isEditMode && props.onUpdateStage) {
                                                            isSuccessful = await props.onUpdateStage(props.rowId, values.stageName);
                                                        } else {
                                                            isSuccessful = await props.onAddStage(values.stageName);
                                                        }
                                                        
                                                        if (isSuccessful) {
                                                            props.onClose();
                                                            resetForm();
                                                        }

                                                        setIsSendingRequest(false);
                                                    }
                                                }}
                                            >
                                                {isEditMode ? 'Update' : 'Add'}
                                            </button>
                                        </Col>
                                    </Row>
                                </>
                            )}
                        </Form>
                    </div>
                </ModalBody>
            </Rodal>
        </div>
    )
};

export default ManageStageModal;