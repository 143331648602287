// Define types for the structure of defvalues and the configuration
interface HeaderStyle {
    backgroundColor: string;
    fontSize?: number;
    color?: string;
    textAlign?: string;
    fontWeight?: string;
}

interface TaskStyle {
    position: string;
    borderRadius: number;
    color: string;
    textAlign: string;
    backgroundColor: string;
    border?: string;
}

interface HeaderConfig {
    top: {
        style: HeaderStyle;
    };
    middle: {
        style: HeaderStyle;
        selectedStyle: HeaderStyle;
    };
    bottom: {
        style: HeaderStyle;
        selectedStyle: HeaderStyle;
    };
}

interface DataViewPortConfig {
    rows: {
        style: {
            backgroundColor: string;
            borderBottom: string;
        };
    };
    task: {
        showLabel: boolean;
        style: TaskStyle;
        selectedStyle: TaskStyle;
    };
}

interface GanttChartConfigValues {
    header: HeaderConfig;
    dataViewPort: DataViewPortConfig;
}

const defvalues: GanttChartConfigValues = {
    header: {
        top: {
            style: {
                backgroundColor: 'white',
                fontSize: 12,
                color: 'grey',
                textAlign: 'center',
            },
        },
        middle: {
            style: {
                backgroundColor: 'white',
                color: 'grey',
            },
            selectedStyle: {
                backgroundColor: 'grey',
                fontWeight: 'bold',
            },
        },
        bottom: {
            style: {
                backgroundColor: 'white',
                fontSize: 12,
                color: 'grey',
            },
            selectedStyle: {
                backgroundColor: 'grey',
                fontWeight: 'bold',
            },
        },
    },
    dataViewPort: {
        rows: {
            style: {
                backgroundColor: '#fbf9f9',
                borderBottom: 'solid 0.5px #cfcfcd',
            },
        },
        task: {
            showLabel: false,
            style: {
                position: 'absolute',
                borderRadius: 14,
                color: 'white',
                textAlign: 'center',
                backgroundColor: 'grey',
            },
            selectedStyle: {
                position: 'absolute',
                borderRadius: 14,
                border: 'solid 1px #ff00fa',
                color: 'white',
                textAlign: 'center',
                backgroundColor: 'grey',
            },
        },
    },
};

// Class to manage Gantt chart configurations
class GanttChartConfigs {
    private data: GanttChartConfigValues;

    constructor() {
        this.data = defvalues;
    }

    // Method to load configuration values
    load = (values?: GanttChartConfigValues) => {
        this.data = values ? this.populate(values, defvalues, {}) : defvalues;
    };

    // Helper method to recursively populate config values
    private populate(values: any, defvalues: any, final: any): any {
        if (!this.isObject(defvalues)) return;
        for (let key in defvalues) {
            if (!values[key]) {
                final[key] = defvalues[key];
            } else {
                final[key] = values[key];
                this.populate(values[key], defvalues[key], final[key]);
            }
        }
        return final;
    }

    // Helper method to check if a value is an object
    private isObject(value: any): boolean {
        return typeof value === 'object' && value !== null;
    }

    // Getter for values
    get values(): GanttChartConfigValues {
        return this.data;
    }
}

// Export default configuration instance
const config = new GanttChartConfigs();
export default config;
