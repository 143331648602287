import { GanttChartItem } from '../../GanttChartComponent/models/GanttChartItem.model';
import { TimelineStage } from '../Model/TimelineStage';
import { TimelineParty } from '../Model/TimelineParty';
import { TimelineEvent } from '../Model/TimelineEvent';
import { STAGE_ITEMS } from '../Constants/dropdownItems';

interface TimelineSummary {
    stage: number;
    backgroundColor: string;
    dayStart: number;
    dayEnd: number;
}

export class DataGenerator {
    generateData(stages: TimelineStage[], events: TimelineEvent[]) {
        let data: GanttChartItem[] = [];

        const grouppedTimelineEvents = this.groupTimelineEvents(events);
        const firstParty =
            events.findIndex(e => e.party == TimelineParty.Seller) > -1 ? TimelineParty.Seller
            : events.findIndex(e => e.party == TimelineParty.Buyer) > -1 ? TimelineParty.Buyer
            : TimelineParty.VetValue;

        this.addPartyData(
            data,
            stages,
            events,
            grouppedTimelineEvents,
            TimelineParty.Seller,
            'Seller',
            firstParty
        );
        this.addPartyData(
            data,
            stages,
            events,
            grouppedTimelineEvents,
            TimelineParty.Buyer,
            'Buyer',
            firstParty
        );
        this.addPartyData(
            data,
            stages,
            events,
            grouppedTimelineEvents,
            TimelineParty.VetValue,
            'VetValue',
            firstParty
        );

        return data;
    }

    private addPartyData(
        data: GanttChartItem[],
        stages: TimelineStage[],
        events: TimelineEvent[],
        grouppedTimelineEvents: Record<number, TimelineSummary>,
        party: TimelineParty,
        partyName: string,
        firstParty: TimelineParty
    ) {
        const partyEvents = events.filter((t) => t.party === party);
        const shouldShowStages = party == firstParty;

        if (partyEvents.length > 0) {
            data.push({
                id: `${partyName.toLowerCase()}-header`,
                name: partyName,
                start: undefined,
                end: undefined,
                color: undefined,
                backgroundColor: undefined,
                orderNo: undefined,
                type: 'header',
                groupName: shouldShowStages ? 'stages' : undefined,
            });

            if (shouldShowStages) {
                for (let i of Object.values(grouppedTimelineEvents)) {
                    const today = new Date();
                    today.setHours(0);
                    today.setDate(today.getDate() + i.dayStart);
                    const tomorrow = new Date();
                    tomorrow.setDate(tomorrow.getDate() + i.dayEnd);
                    const stage = stages.find((s) => s.id == i.stage);
                    if (!stage) continue;
                    data.push({
                        id: `stage-${i.stage}`,
                        name: stage.name,
                        start: today,
                        end: tomorrow,
                        color: 'black',
                        backgroundColor: i.backgroundColor,
                        orderNo: undefined,
                        type: 'header',
                        groupName: 'stages',
                    });
                }
            }

            let count = 1;
            for (let event of partyEvents) {
                const today = new Date();
                today.setHours(0);
                today.setDate(today.getDate() + event.dayStart);
                const tomorrow = new Date();
                tomorrow.setDate(tomorrow.getDate() + event.dayEnd);
                const backgroundColor =
                    grouppedTimelineEvents[event.stage]?.backgroundColor;
                data.push({
                    id: `event-${event.id}`,
                    name: event.name,
                    start: today,
                    end: tomorrow,
                    color: 'black',
                    backgroundColor: backgroundColor,
                    orderNo: count,
                    type: 'line',
                });
                count++;
            }
        }
    }

    groupTimelineEvents(
        events: TimelineEvent[]
    ): Record<number, TimelineSummary> {
        const result: Record<number, TimelineSummary> = {};
        const stageMap = new Map();
        events.forEach((event) => {
            const { stage, dayStart, dayEnd } = event;

            if (!result[stage]) {
                if (!stageMap.has(stage)) {
                    const index = stageMap.size;
                    const color =
                        index < STAGE_ITEMS.length
                            ? STAGE_ITEMS[index].color
                            : null;
                    stageMap.set(stage, { undefined, color });
                }
                const mappedStage = stageMap.get(stage);
                const backgroundColor = mappedStage?.color;
                result[stage] = { stage, backgroundColor, dayStart, dayEnd };
            } else {
                result[stage].dayStart = Math.min(
                    result[stage].dayStart,
                    dayStart
                );
                result[stage].dayEnd = Math.max(result[stage].dayEnd, dayEnd);
            }
        });

        return result;
    }
}
