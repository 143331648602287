import { createSlice } from '@reduxjs/toolkit';
import { DataRequestNotificationsState } from './data-request-notifications.state';
import { initializeDataRequestNotifications } from './thunks/initialize-data-request-notifications.thunk';
import { updateQuestionsAndCells } from './thunks/update-questions-and-cells.thunk';
import { resetQuestionsSeenStatusByFormType } from './thunks/reset-questions-seen-status-by-form-type.thunk';
import { resetQuestionsSeenStatusByFormId } from './thunks/reset-questions-seen-status-by-form-id.thunk';
import { resetQuestionsSeenStatusByIds } from './thunks/reset-questions-seen-status-by-ids.thunk';
import { addQuestionAnswer } from './thunks/add-question-answer-thunk';
import { resetCellSeenStatusByIds } from './thunks/reset-cell-seen-status-by-ids.thunk';

const initialState: DataRequestNotificationsState = {
    isAdminView: undefined,
    questionsSeenStatus: [],
    formsNotificationsStatus: [],
    projectTabsNotificationsStatus: [],
    moduleTabsNotificationsStatus: [],
    tablesNotificationsStatus: [],
    tableCellsSeenStatus: [],
};

export interface ResetQuestionInfo {
    questionId: number;
    isSubquestion: boolean;
}

export interface ResetCellInfo {
    customRowId: string;
    customColumnId: string;
}

const dataRequestNotificationsSlice = createSlice({
    name: 'DataRequestNotifications',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(
                initializeDataRequestNotifications.fulfilled,
                (state: DataRequestNotificationsState, action: any) => {
                    updateState(state, action.payload);
                }
            )
            .addCase(
                updateQuestionsAndCells.fulfilled,
                (state: DataRequestNotificationsState, action: any) => {
                    updateState(state, action.payload);
                }
            )
            .addCase(
                resetQuestionsSeenStatusByFormType.fulfilled,
                (state: DataRequestNotificationsState, action: any) => {
                    updateState(state, action.payload);
                }
            )
            .addCase(
                resetQuestionsSeenStatusByFormId.fulfilled,
                (state: DataRequestNotificationsState, action: any) => {
                    updateState(state, action.payload);
                }
            )
            .addCase(
                resetQuestionsSeenStatusByIds.fulfilled,
                (state: DataRequestNotificationsState, action: any) => {
                    updateState(state, action.payload);
                }
            )
            .addCase(
                addQuestionAnswer.fulfilled,
                (state: DataRequestNotificationsState, action: any) => {
                    updateState(state, action.payload);
                }
            )
            .addCase(
                resetCellSeenStatusByIds.fulfilled,
                (state: DataRequestNotificationsState, action: any) => {
                    updateState(state, action.payload);
                }
            );
    },
});

const updateState = (
    currentState: DataRequestNotificationsState,
    newState: DataRequestNotificationsState
) => {
    currentState.isAdminView = newState.isAdminView;
    currentState.questionsSeenStatus = newState.questionsSeenStatus;
    currentState.formsNotificationsStatus = newState.formsNotificationsStatus;
    currentState.projectTabsNotificationsStatus =
        newState.projectTabsNotificationsStatus;
    currentState.moduleTabsNotificationsStatus =
        newState.moduleTabsNotificationsStatus;
    currentState.tablesNotificationsStatus = newState.tablesNotificationsStatus;
    currentState.tableCellsSeenStatus = newState.tableCellsSeenStatus;
};

export const {} = dataRequestNotificationsSlice.actions;
export default dataRequestNotificationsSlice.reducer;
