import React from 'react';
import moment from 'moment';
import { DAY_WIDTH, FIRST_COLUMN_GAP } from '../../GanttChartConstants';
import Config from '../../helpers/config/GanttChartConfigs';
import GanttChartDateHelper from '../../helpers/GanttChartDateHelper';
import './GanttChartHeaders.scss';
import { GanttChartHeaderItem } from './GanttChartHeaderItem';

interface GanttChartHeadersProps {
    upperBoundary: number;
    nowposition: number;
}
export const GanttChartHeaders = (props: GanttChartHeadersProps) => {
    const getBox = (date: moment.Moment, lastLeft: number) => {
        let increment = DAY_WIDTH;
        if (!lastLeft) {
            const starDate = date.startOf('day');
            let now = moment().startOf('day');
            let daysInBetween = starDate.diff(now, 'days');
            lastLeft = GanttChartDateHelper.dayToPosition(
                daysInBetween,
                props.nowposition,
                DAY_WIDTH
            );
        }

        return { left: lastLeft, width: increment };
    };

    const renderHeaderRows = () => {
        let result: {
            top: React.JSX.Element[];
            middle: React.JSX.Element[];
            bottom: React.JSX.Element[];
        } = {
            top: [],
            middle: [],
            bottom: [],
        };
        const firstLineMode = DAY_WIDTH * FIRST_COLUMN_GAP;
        let lastLeft = {
            top: firstLineMode,
            middle: firstLineMode,
            bottom: firstLineMode,
        };
        let currentTop = '';
        let currentMiddle = '';
        let currentBottom = '';
        let currentDate = null;
        let box = null;

        let start = 0;
        let end = props.upperBoundary;
        result.top.push(
            <GanttChartHeaderItem
                key={'top-1'}
                left={0}
                width={firstLineMode}
                label="Date"
                styles={{ justifyContent: 'left', paddingLeft: '10px' }}
            />
        );
        result.middle.push(
            <GanttChartHeaderItem
                key={'middle-1'}
                left={0}
                width={firstLineMode}
                label="Day"
                styles={{ justifyContent: 'left', paddingLeft: '10px' }}
            />
        );
        result.bottom.push(
            <GanttChartHeaderItem
                key={'bottom-1'}
                left={0}
                width={firstLineMode}
                label="Days Since Start"
                styles={{ justifyContent: 'left', paddingLeft: '10px' }}
            />
        );

        for (let i = start; i < end; i++) {
            //The unit of iteration is day
            currentDate = moment().add(i, 'days');
            if (currentTop != currentDate.format('D-MMM')) {
                currentTop = currentDate.format('D-MMM');
                box = getBox(currentDate, lastLeft.top);
                lastLeft.top = box.left + box.width;
                result.top.push(
                    <GanttChartHeaderItem
                        key={i}
                        left={box.left}
                        width={box.width}
                        label={currentTop}
                    />
                );
            }

            if (currentMiddle != currentDate.format('dddd')) {
                currentMiddle = currentDate.format('dddd');
                box = getBox(currentDate, lastLeft.middle);
                lastLeft.middle = box.left + box.width;
                result.middle.push(
                    <GanttChartHeaderItem
                        key={i}
                        left={box.left}
                        width={box.width}
                        label={currentMiddle}
                    />
                );
            }

            if (currentBottom != currentDate.format('dddd')) {
                currentBottom = currentDate.format('dddd');
                box = getBox(currentDate, lastLeft.bottom);
                lastLeft.bottom = box.left + box.width;
                result.bottom.push(
                    <GanttChartHeaderItem
                        key={i}
                        left={box.left}
                        width={box.width}
                        label={i}
                    />
                );
            }
        }

        return (
            <div
                className="timeLine-main-header-container"
                style={{
                    width: props.upperBoundary,
                    maxWidth: props.upperBoundary,
                }}>
                <div
                    className="header-top"
                    style={{ ...Config.values.header.top.style } as any}>
                    {result.top}
                </div>
                <div
                    className="header-middle"
                    style={{ ...Config.values.header.middle.style } as any}>
                    {result.middle}
                </div>
                <div
                    className="header-bottom"
                    style={{ ...Config.values.header.bottom.style } as any}>
                    {result.bottom}
                </div>
            </div>
        );
    };

    return (
        <div className="timeLine-main-header-viewPort">
            {renderHeaderRows()}
        </div>
    );
};
