import { createAsyncThunk } from '@reduxjs/toolkit';
import { DataRequestNotificationsState } from '../data-request-notifications.state';
import { DataFormType } from '../../DataFormTypeEnum';
import { resetFormTypeQuestionsNotifications } from '../data-forms-status-mapper';
import { updateTabsState } from '../project-tabs-status-mapper';

export interface ResetQuestionsSeenStatusByFormTypeArguments {
    formTypeToReset: DataFormType;
}

export const resetQuestionsSeenStatusByFormType = createAsyncThunk(
    'dataRequestNotifications/resetQuestionsSeenStatusByFormType',
    async (
        args: ResetQuestionsSeenStatusByFormTypeArguments,
        dispatch: any
    ) => {
        const notificationsState =
            dispatch.getState().dataRequestsNotifications;
        const result = await resetNotifications(
            args.formTypeToReset,
            notificationsState
        );

        return result;
    }
);

const resetNotifications = (
    formType: DataFormType,
    state: DataRequestNotificationsState
) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            const updatedState = { ...state };

            const updatedQuestionsStatus = resetFormTypeQuestionsNotifications(
                updatedState.questionsSeenStatus,
                formType
            );
            updateTabsState(
                updatedState,
                updatedQuestionsStatus,
                updatedState.tableCellsSeenStatus,
                updatedState.tablesNotificationsStatus
            );

            updatedState.questionsSeenStatus = updatedQuestionsStatus;
            resolve(updatedState);
        });
    });
};
