import React, { useRef } from 'react';
import './EditableDateLabel.scss';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { InputGroupAddon } from 'reactstrap';
import ReactDatePicker from 'react-datepicker';

interface EditableDateLabelProps {
    value: Date;
    isEditable: boolean;
    onChange: (event: Date) => void;
}

const EditableDateLabel = (props: EditableDateLabelProps) => {
    const { value, isEditable, onChange } = props;
    const startDateRef = useRef(null);

    return (
        <div className="date-label-wrapper">
            <div className="editable-date-label">
                {isEditable ? (
                    <div
                        className="date-picker-container"
                        onClick={() => {
                            startDateRef.current.setOpen(true);
                        }}>
                        <div className="d-flex align-items-center small-border">
                            <ReactDatePicker
                                portalId="due-date-picker-root-portal"
                                className={value ? '' : 'd-none'}
                                ref={startDateRef}
                                selected={value ? new Date(value) : new Date(0)}
                                onKeyDown={(e) => {
                                    e.preventDefault();
                                }}
                                showYearDropdown
                                showMonthDropdown
                                todayButton={<>Today</>}
                                name="dueDate"
                                dateFormat="MM/dd/yyyy"
                                onChange={(date: any) => {
                                    const utcDate = new Date(
                                        Date.UTC(
                                            date.getFullYear(),
                                            date.getMonth(),
                                            date.getDate()
                                        )
                                    );
                                    onChange(utcDate);
                                }}
                                openToDate={
                                    value ? new Date(value) : new Date()
                                }
                                showPopperArrow={false}
                            />
                            {value ? (
                                ''
                            ) : (
                                <div className="na-label">{'NA'}</div>
                            )}
                            <InputGroupAddon addonType="prepend">
                                <div className="input-group-text calendar-icon">
                                    <FontAwesomeIcon icon={faCalendarAlt} />
                                </div>
                            </InputGroupAddon>
                        </div>
                    </div>
                ) : (
                    <>{value ? moment(value).format('L') : 'NA'}</>
                )}
            </div>
        </div>
    );
};

export default EditableDateLabel;
