import React, { useState } from 'react';
import { Box } from '@mui/material';
import PortalTooltip from '../../../../components/PortalTooltip/PortalTooltip';
import {
    DataTable,
    DataTableViewProgressStatus,
    DataTableProgressStatus,
    DataTableColumn,
    DataTableView,
} from '../../../../models/DataRequestHub/DataTable';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { RenderCellBadgeNotification } from './CellBadgeNotification';

interface ColDefRenderCellProps {
    params: GridRenderCellParams;
    isCellAvailable(targetColumn: DataTableColumn, gridRow: any): boolean;
    columnDefinition: DataTableColumn;
    dataTable: DataTable;
    getCurrentTableView(): DataTableView;
    isAdminView: boolean;
    selectedTableViewId: number;
    tooltipContent?: React.JSX.Element;
    renerValue?: React.JSX.Element | string;
}

export const ColDefRenderCell = (props: ColDefRenderCellProps) => {
    const [isHovered, setIsHovered] = useState(false);
    const anchorId = `${props.dataTable.id}-${props.params?.field}-${
        props.params?.id ?? 0
    }`;
    const anchorElement = document.getElementById(anchorId);

    const handleMouseMove = () => {
        setIsHovered(true);
        if (anchorElement) {
            const event = new MouseEvent('mouseover', {
                bubbles: true,
                cancelable: true,
            });
            anchorElement.dispatchEvent(event);
        }
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const isAvailable = props.isCellAvailable(
        props.columnDefinition,
        props.params.row
    );

    if (!isAvailable) {
        return <></>;
    }

    const renderTooltip = (content: React.JSX.Element) => {
        return (
            <PortalTooltip
                anchorId={anchorId}
                delayShow={1000}
                place="bottom"
                content={content}
            />
        );
    };

    const tooltipElement = props.tooltipContent ?? (
        <div>{props.params.value}</div>
    );
    const isAnswerEmpty = !!!props.params.value;
    const targetView = props.getCurrentTableView();
    const isFormComplete =
        targetView?.progressStatus === DataTableViewProgressStatus.Complete ||
        props.dataTable.progressStatus === DataTableProgressStatus.Complete;

    const isAlertVisible =
        isAnswerEmpty && !isFormComplete && !props.dataTable.isSnapshot;

    const renderValue =
        props.renerValue ?? props.params.row[props.params.field];

    return isAlertVisible ? (
        <div className="empty-answer-cell-alert"></div>
    ) : (
        <Box
            className="custom-render-cell"
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}>
            {isHovered && renderTooltip(tooltipElement)}
            <div className="MuiDataGrid-cellContent" id={anchorId}>
                {renderValue}
            </div>

            <RenderCellBadgeNotification
                {...props}
                columnCustomId={props.columnDefinition.customColumnId}
                rowCustomId={props.params.row.customRowId}
            />
        </Box>
    );
};
