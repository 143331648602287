import { createAsyncThunk } from '@reduxjs/toolkit';
import { DataRequestNotificationsState } from '../data-request-notifications.state';
import { ResetCellInfo } from '../data-request-notifications.slice';
import {
    resetCellsNotifications,
    resetTableNotifications,
    updateTablesNotifications,
} from '../data-tables-status-mapper';
import { mapProjectTabsStatuses } from '../project-tabs-status-mapper';

export interface ResetCellSeenStatusByIdsArgumetns {
    resetCellsInfo: ResetCellInfo[];
    tableId: number;
    viewId: number;
}

export const resetCellSeenStatusByIds = createAsyncThunk(
    'dataRequestNotifications/resetCellSeenStatusByIds',
    (args: ResetCellSeenStatusByIdsArgumetns, dispatch: any) => {
        const notificationsState =
            dispatch.getState().dataRequestsNotifications;
        const result = resetNotifications(
            args.resetCellsInfo,
            args.tableId,
            args.viewId,
            notificationsState
        );

        return result;
    }
);

const resetNotifications = (
    resetCellsInfo: ResetCellInfo[],
    tableId: number,
    viewId: number,
    state: DataRequestNotificationsState
) => {
    if (state.isAdminView) return state;

    const updatedState = { ...state };

    const targetView = state.tablesNotificationsStatus
        .flatMap((table) => table.viewsNotificationsStatus)
        .find((view) => view.viewId === viewId);
    const updatedQuestionsStatus = resetCellsNotifications(
        updatedState.tableCellsSeenStatus,
        resetCellsInfo,
        tableId,
        targetView
    );

    const updatedTableStatus = updatedState.tablesNotificationsStatus.map(
        (tableStatus) =>
            tableStatus.tableId === tableId
                ? resetTableNotifications(tableStatus, resetCellsInfo, viewId)
                : tableStatus
    );

    const updatedTablesNotifications = updateTablesNotifications(
        updatedQuestionsStatus,
        updatedTableStatus,
        updatedState.isAdminView
    );

    const updatedTabsStatus = mapProjectTabsStatuses(
        updatedState.formsNotificationsStatus,
        updatedTablesNotifications,
        updatedState.isAdminView
    );

    updatedState.tableCellsSeenStatus = updatedQuestionsStatus;
    updatedState.tablesNotificationsStatus = updatedTablesNotifications;
    updatedState.projectTabsNotificationsStatus = updatedTabsStatus;

    return updatedState;
};
