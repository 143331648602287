import React, { useEffect, useState } from 'react';
import { ModalBody, ModalHeader } from 'reactstrap';
import Loader from 'react-loaders';
import Rodal from '../../../../components/Rodal/Rodal';
import './StageEditorModal.scss';
import { TimelineStage } from '../../Model/TimelineStage';
import { useStateSelector } from '../../../../store/selectors';
import StagesTable from './StagesTable/StagesTable';
import {
    faEye,
    faPlus,
} from '@fortawesome/free-solid-svg-icons';
import CreateNewStageModal from './ManageStageModal/ManageStageModal';
import ConfirmationModal from '../../../../components/Modal/ConfirmationModal';
import createNotification from '../../../../utils/createNotification';


interface StageEditorModal {
    isVisible: boolean;
    onClose: () => void;
    stagesUsedLocally: number[];
    setFlagToFetch: () => void;
}

const StageEditorModal = (props: StageEditorModal) => {
    const axios = useStateSelector((state) => state.core.axios);

    const [isLoading, setIsLoading] = useState(false);
    const [stages, setStages] = useState<TimelineStage[]>([]);

    const [stagesAreLoading, setStagesAreLoading] = useState(false);

    const [isCreateStageModalVisible, setIsCreateStageModalVisible] = useState(false);

    const [selectedStageId, setSelectedStageId] = useState<number | null>(null);
    const [mode, setMode] = useState<'create' | "edit">('create');
    const [isRemoveConfirmationPopupVisible, setIsRemoveConfirmationPopupVisible] = useState(false);

    const fetchStages = async () => {
        setStagesAreLoading(true);
        try {
            const response = await axios.get('api/Marketplace/TimelineStages');
            const filteredStages = response.data.map((stage: TimelineStage) => ({
                id: stage.id,
                name: stage.name,
                isInUse: stage.isInUse,
            }));
            setStages(filteredStages);
        } catch (error) {
            console.error('Error fetching timeline stages:', error);
        } finally {
            setStagesAreLoading(false);
        }
    };

    useEffect(() => {
        if (props.isVisible) {
            fetchStages();
        }
    }, [props.isVisible]);

    const handleStageToUpdate = (stageId: number) => {
        setMode('edit');
        setSelectedStageId(stageId);
        setIsCreateStageModalVisible(true);
    }

    const handleStageToRemove = (stageId: number) => {
        setSelectedStageId(stageId);
        setIsRemoveConfirmationPopupVisible(true);
    }

    const addStage = async (stageName: string): Promise<boolean> => {
        try {
            const response = await axios.post('api/Marketplace/TimelineStages', {
                name: stageName,
            });

            if (response && response.status === 200) {
                createNotification('New stage added successfully', 'success');
                setIsCreateStageModalVisible(false);
                await fetchStages();
                props.setFlagToFetch();
                return true;
            } else {
                createNotification(
                    'Timeline Stage with such name already exists.',
                    'error'
                );
                return false;
            }
        } catch (error: any) {
            const errorDetail = error.response?.data?.detail || 'An unexpected error occurred.';
            createNotification(errorDetail, 'error');
            return false;
        }
    };

    const editStage = async (stageId: number, stageName: string): Promise<boolean> => {
        try {
            const response = await axios.put(`api/Marketplace/TimelineStages/${stageId}`, {
                name: stageName,
            });

            if (response && response.status === 200) {
                createNotification('The stage was updated successfully', 'success');
                setIsCreateStageModalVisible(false);
                await fetchStages();
                props.setFlagToFetch();
                return true;
            } else {
                createNotification(
                    'Timeline Stage with such name already exists.',
                    'error'
                );
                return false;
            }
        } catch (error: any) {
            const errorDetail = error.response?.data?.detail || 'An unexpected error occurred.';
            createNotification(errorDetail, 'error');
            return false;
        }
    };

    const removeStage = async (stageId: number): Promise<boolean> => {
        try {
            const response = await axios.delete(`api/Marketplace/TimelineStages/${stageId}`);

            if (response && response.status === 200) {
                createNotification('The stage was removed successfully', 'success');
                await fetchStages();
                props.setFlagToFetch();
                return true;
            } else {
                createNotification(
                    `Can't remove selected stage. Try again later.`,
                    'error'
                );
                return false;
            }
        } catch (error: any) {
            const errorDetail = error.response?.data?.detail || 'An unexpected error occurred.';
            createNotification(errorDetail, 'error');
            return false;
        }
    };

    const renderLoader = () => {
        return (
            <div
                className="loader-container"
                style={{ height: '100%', width: '100%' }}>
                <div className="loader-container-inner">
                    <br />
                    <div className="text-center">
                        <Loader active={isLoading} type="ball-scale-multiple" />
                    </div>
                    <h6 className="mt-5">Loading stages...</h6>
                </div>
            </div>
        );
    };

    const renderContent = () => {
        return (
            <div>
                {stages.length > 0 ? (
                    <div>
                        <StagesTable
                            stagesUsedLocally={props.stagesUsedLocally}
                            rows={stages}
                            stagesAreLoading={stagesAreLoading}
                            handleStageToUpdate={handleStageToUpdate}
                            handleStageToRemove={handleStageToRemove}
                        />
                    </div>
                ) : (
                    <div className="w-100 d-flex flex-column align-items-center no-content">
                        <div className="mb-3">
                            No stage has been created yet
                        </div>
                        <div className="">
                            <button
                                className="btn btn-primary"
                                onClick={() => {
                                    setMode('create');
                                    setIsCreateStageModalVisible(true);
                                }}
                            >
                                Create new Stage
                            </button>
                        </div>
                    </div>
                )}
            </div>
        )
    }

    return (
        <div className="stage-editor-modal">
            <Rodal
                visible={props.isVisible}
                animation={'slideTop'}
                onClose={props.onClose}
                showMask={true}
                width={600}
                closeOnEsc
                className="modal-stages"
                center>
                    <ModalHeader className="popup-header">
                        <div className="d-flex align-items-center">
                            <span>
                                Stages
                            </span>
                            {stages.length > 0 && (
                                <div className="ml-3">
                                    <button
                                        className="btn btn-primary"
                                        onClick={() => {
                                            setMode('create');
                                            setIsCreateStageModalVisible(true)
                                        }}
                                    >
                                        Add new
                                    </button>
                                </div>
                            )}
                        </div>
                    </ModalHeader>
                <ModalBody>
                    {isLoading ? (
                        renderLoader()
                    ) : (
                        <div className="bootstrap-table-container">
                            {renderContent()}
                        </div>
                    )}
                </ModalBody>
            </Rodal>

            <CreateNewStageModal
                isVisible={isCreateStageModalVisible}
                onClose={() => setIsCreateStageModalVisible(false)}
                onAddStage={addStage}
                onUpdateStage={mode === 'create' ? null : editStage}
                mode={mode}
                initialStageName={ mode === 'create' ? null : stages.find(s => s.id === selectedStageId)?.name}
                rowId={mode === 'create' ? null : selectedStageId}
            />

            <div className='confirmation-modal-container'>
                <ConfirmationModal
                    isVisible={isRemoveConfirmationPopupVisible}
                    setIsVisible={
                        setIsRemoveConfirmationPopupVisible
                    }
                    header="Confirmation"
                    confirmationText={`Are you sure you want to remove '${stages.find(s => s.id === selectedStageId)?.name}' stage?`}
                    nextButtonText="Confirm"
                    cancelButtonText="Cancel"
                    onConfirm={removeStage}
                    params={selectedStageId}
                />
            </div>

        </div>
    )
}

export default StageEditorModal;