import { DataRequestProject } from '../DataRequestProject';
import {
    isCellVisibleByViewFilterCondition,
    isRowCellsAvailableByViewFilter,
    DataTableViewProgressStatus,
} from '../DataTable';
import { TableCellSeenStatus } from '../TableCellSeenStatus';
import {
    ViewColumnSeenStatus,
    RowSeenStatus,
    TableNotificationStatus,
    ViewNotificationStatus,
} from '../TableNotificationStatus';
import { ResetCellInfo } from './data-request-notifications.slice';

export const updateCellsStauts = (
    cellsStatus: TableCellSeenStatus[],
    projects: DataRequestProject[]
): TableCellSeenStatus[] => {
    const cellsStatusMap = new Map(cellsStatus.map((cell) => [cell.id, cell]));

    return projects.flatMap((project) => {
        const isNewProject = !project.isSeenByUser;

        return project.dataTables.flatMap((table) => {
            return table.rows.flatMap((row) => {
                return row.cells.map((cell) => {
                    const existingStatus = cellsStatusMap.get(cell.id);

                    if (existingStatus) {
                        return {
                            ...existingStatus,
                            isNewProject,
                        };
                    }

                    return {
                        ...cell,
                        projectId: project.id,
                        tableId: table.id,
                        customRowId: row.customRowId,
                        isNewProject,
                        isNewTable: false,
                        isNewRow: !row.isSeenByUser,
                        isSeenByUser:
                            isNewProject || !cell.isAvailableByLogic
                                ? true
                                : cell.isSeenByUser,
                    };
                });
            });
        });
    });
};

export const mapCellsSeenStatus = (
    projects: DataRequestProject[]
): TableCellSeenStatus[] => {
    const dataTables = projects.flatMap((project) => project.dataTables);
    if (dataTables) {
        const cellsSeenStatus = dataTables.flatMap((table) =>
            table.rows.flatMap((row): TableCellSeenStatus[] => {
                const sourceProject = projects.find((project) =>
                    project.dataTables.some(
                        (dataTable) => dataTable.id === table.id
                    )
                );

                const isNewProject = !sourceProject.isSeenByUser;

                const mappedCellsStatus = row.cells.map(
                    (cell): TableCellSeenStatus => {
                        const canCellNotificationBeVisible =
                            !isNewProject &&
                            row.isSeenByUser &&
                            !row.isRemovalRequested &&
                            row.isVisible;

                        return {
                            ...cell,
                            isSeenByUser:
                                canCellNotificationBeVisible &&
                                cell.isAvailableByLogic
                                    ? cell.isSeenByUser
                                    : true,
                            isSeenByAdmin: cell.isSeenByAdmin,
                            projectId: sourceProject.id,
                            isNewProject: isNewProject,
                            tableId: table.id,
                            customRowId: row.customRowId,
                            isNewTable: table.isNew,
                            isNewRow: !row.isSeenByUser,
                        };
                    }
                );

                return mappedCellsStatus;
            })
        );

        return cellsSeenStatus;
    }

    return [];
};

export const updateTablesSeenStatus = (
    currentTablesStatus: TableNotificationStatus[],
    projects: DataRequestProject[]
): TableNotificationStatus[] => {
    var updatedStatus = currentTablesStatus.map(
        (tableStatus): TableNotificationStatus => {
            const updatedTable = projects
                .find((project) => project.id === tableStatus.projectId)
                .dataTables.find(
                    (dataTable) => dataTable.id === tableStatus.tableId
                );
            const updatedViewsStatus = tableStatus.viewsNotificationsStatus.map(
                (viewStatus): ViewNotificationStatus => {
                    const updatedView = updatedTable.views.find(
                        (updatedView) => updatedView.id === viewStatus.viewId
                    );
                    const updatedColumnStatus =
                        viewStatus.columnsSeenStatus.map(
                            (viewColumn): ViewColumnSeenStatus => {
                                const updatedColumn =
                                    updatedView.viewColumns.find(
                                        (updatedColumn) =>
                                            updatedColumn.customColumnId ===
                                            viewColumn.customColumnId
                                    );

                                if (!updatedColumn) {
                                    return {
                                        ...viewColumn,
                                        isVisible: false,
                                    };
                                }

                                const isNewColumn =
                                    (!viewColumn.isVisible &&
                                        updatedColumn?.isVisible) ||
                                    !updatedColumn?.isSeenByUser;

                                return {
                                    ...viewColumn,
                                    isSeenByUser: isNewColumn
                                        ? false
                                        : viewColumn.isSeenByUser,
                                    isVisible: updatedColumn.isVisible,
                                };
                            }
                        );
                    const columnsStatusToAdd = updatedView.viewColumns
                        .filter(
                            (viewColumn) =>
                                !updatedColumnStatus.some(
                                    (updatedStatus) =>
                                        updatedStatus.customColumnId ===
                                        viewColumn.customColumnId
                                )
                        )
                        .map(
                            (viewColumn): ViewColumnSeenStatus => ({
                                id: viewColumn.id,
                                customColumnId: viewColumn.customColumnId,
                                isSeenByAdmin: viewColumn.isSeenByAdmin,
                                isSeenByUser: false,
                                isVisible: viewColumn.isVisible,
                                isFilterEnabled:
                                    viewColumn.isFilterEnabled ?? false,
                                filterData: viewColumn.filterData ?? [],
                            })
                        );
                    return {
                        ...viewStatus,
                        columnsSeenStatus: [
                            ...updatedColumnStatus,
                            ...columnsStatusToAdd,
                        ],
                    };
                }
            );
            return {
                ...tableStatus,
                viewsNotificationsStatus: updatedViewsStatus,
            };
        }
    );

    return updatedStatus;
};

export const mapTablesNotifications = (
    cellsSeenStatus: TableCellSeenStatus[],
    projects: DataRequestProject[],
    isAdminView: boolean
) => {
    const tablesStatuses = initializeTablesStatus(projects);
    const result = updateTablesNotifications(
        cellsSeenStatus,
        tablesStatuses,
        isAdminView
    );

    return result;
};

export const updateTablesNotifications = (
    cellsSeenStatus: TableCellSeenStatus[],
    tablesStatuses: TableNotificationStatus[],
    isAdminView: boolean
) => {
    const result = tablesStatuses.map((tableStatus) => {
        if (tableStatus.isNewProject) {
            return tableStatus;
        }

        if (tableStatus.isNew) {
            return { ...tableStatus, notificationsCount: 1 };
        }

        const viewNotifications = tableStatus.viewsNotificationsStatus.map(
            (viewStatus) => {
                const viewCells = cellsSeenStatus.filter((cell) =>
                    viewStatus.columnsSeenStatus.some(
                        (viewStatus) =>
                            viewStatus.customColumnId === cell.customColumnId &&
                            cell.tableId === tableStatus.tableId
                    )
                );

                let viewNotificationCellsCount = 0;

                if (!isAdminView) {
                    if (
                        viewStatus.progressStatus ===
                        DataTableViewProgressStatus.Complete
                    ) {
                        viewNotificationCellsCount = 0;
                    } else if (viewStatus.isSeenByUser) {
                        const visibleColumnStatuses =
                            viewStatus.columnsSeenStatus.filter(
                                (columnStatus) => columnStatus.isVisible
                            );
                        if (visibleColumnStatuses.length) {
                            viewNotificationCellsCount +=
                                getViewCellsNotifications(
                                    viewCells,
                                    tableStatus.rowsSeenStatus,
                                    visibleColumnStatuses,
                                    isAdminView
                                );

                            viewNotificationCellsCount += getNewRowsCount(
                                tableStatus.rowsSeenStatus,
                                isAdminView,
                                viewStatus,
                                cellsSeenStatus,
                                tableStatus.tableId
                            );
                            viewNotificationCellsCount += getNewColumnsCount(
                                visibleColumnStatuses,
                                isAdminView
                            );
                        }
                    } else {
                        viewNotificationCellsCount = 1;
                    }
                }

                return {
                    ...viewStatus,
                    notificationsCount: viewNotificationCellsCount,
                };
            }
        );

        const tableNotifications = viewNotifications.reduce(
            (a, b) => a + b.notificationsCount,
            0
        );

        return {
            ...tableStatus,
            viewsNotificationsStatus: viewNotifications,
            notificationsCount: tableNotifications,
        };
    });

    return result;
};

export const resetCellsNotifications = (
    cellsSeenStatus: TableCellSeenStatus[],
    cellsToReset: ResetCellInfo[],
    tableId: number,
    viewStatus: ViewNotificationStatus
): TableCellSeenStatus[] => {
    const hiddenRowIds = getHiddenRowIds(
        cellsSeenStatus.filter(
            (cellSeenStatus) => cellSeenStatus.tableId === tableId
        ),
        viewStatus.columnsSeenStatus
    );

    const updatedSeenStatuses = cellsSeenStatus.map((cell) => {
        const targetCell = cellsToReset.find(
            (cellToReset) =>
                cellToReset.customRowId === cell.customRowId &&
                cellToReset.customColumnId === cell.customColumnId &&
                tableId === cell.tableId &&
                !hiddenRowIds.some(
                    (hiddenRowId) => cellToReset.customRowId === hiddenRowId
                )
        );

        const targetViewColumn = viewStatus?.columnsSeenStatus?.find(
            (column) => column.customColumnId === cell.customColumnId
        );

        const isViewColumnSeenByUser = targetViewColumn?.isSeenByUser ?? true;

        if (
            targetCell ||
            cell.isNewTable ||
            cell.isNewRow ||
            !isViewColumnSeenByUser
        ) {
            return {
                ...cell,
                isSeenByAdmin: true,
                isSeenByUser: true,
                isNewTable: false,
                isNewRow: false,
                isNewColumn: false,
            };
        }

        return cell;
    });

    return updatedSeenStatuses;
};

export const resetTableNotifications = (
    tablesNotificationStatus: TableNotificationStatus,
    cellsToReset: ResetCellInfo[],
    viewId: number
): TableNotificationStatus => {
    const isNewView = !tablesNotificationStatus.viewsNotificationsStatus.find(
        (view) => view.viewId === viewId
    )?.isSeenByUser;

    const updatedRowsStauts = tablesNotificationStatus.rowsSeenStatus.map(
        (rowStauts) => {
            return cellsToReset.some(
                (cellToReset) =>
                    cellToReset.customRowId === rowStauts.customRowId
            ) ||
                tablesNotificationStatus.isNew ||
                tablesNotificationStatus.isNewProject ||
                isNewView
                ? { ...rowStauts, isSeenByAdmin: true, isSeenByUser: true }
                : rowStauts;
        }
    );

    const updatedViews = tablesNotificationStatus.viewsNotificationsStatus.map(
        (viewStatus): ViewNotificationStatus => ({
            ...viewStatus,
            columnsSeenStatus: viewStatus.columnsSeenStatus.map(
                (columnStatus) =>
                    (cellsToReset.some(
                        (cell) =>
                            cell.customColumnId === columnStatus.customColumnId
                    ) &&
                        viewStatus.viewId === viewId) ||
                    tablesNotificationStatus.isNew ||
                    tablesNotificationStatus.isNewProject
                        ? {
                              ...columnStatus,
                              isSeenByAdmin: true,
                              isSeenByUser: true,
                          }
                        : columnStatus
            ),
            isSeenByUser:
                viewStatus.viewId === viewId || tablesNotificationStatus.isNew
                    ? true
                    : viewStatus.isSeenByUser,
        })
    );

    return {
        ...tablesNotificationStatus,
        isNew: false,
        rowsSeenStatus: updatedRowsStauts,
        viewsNotificationsStatus: updatedViews,
    };
};

const initializeTablesStatus = (projects: DataRequestProject[]) => {
    const tablesNotificationsStatuses = projects.flatMap((project) =>
        project.dataTables.map((table): TableNotificationStatus => {
            return {
                projectId: project.id,
                tableId: table.id,
                notificationsCount: 0,
                isNew: table.isNew,
                isNewProject: !project.isSeenByUser,
                viewsNotificationsStatus: table.views.map(
                    (view): ViewNotificationStatus => ({
                        viewId: view.id,
                        notificationsCount: 0,
                        progressStatus: view.progressStatus,
                        columnsSeenStatus: view.viewColumns.map(
                            (viewColumn): ViewColumnSeenStatus => {
                                return {
                                    id: view.id,
                                    customColumnId: viewColumn.customColumnId,
                                    isSeenByAdmin: viewColumn.isSeenByAdmin,
                                    isSeenByUser: view.isSeenByUser
                                        ? viewColumn.isSeenByUser
                                        : true,
                                    isVisible: viewColumn.isVisible,
                                    isFilterEnabled:
                                        viewColumn?.isFilterEnabled ?? false,
                                    filterData: viewColumn?.filterData ?? [],
                                };
                            }
                        ),
                        isSeenByUser: view.isSeenByUser,
                    })
                ),
                rowsSeenStatus: table.rows.map(
                    (row): RowSeenStatus => ({
                        customRowId: row.customRowId,
                        isSeenByAdmin: row.isSeenByAdmin,
                        isSeenByUser: row.isSeenByUser,
                        isVisible: row.isVisible && !row.isRemovalRequested,
                    })
                ),
            };
        })
    );

    return tablesNotificationsStatuses;
};

const getViewCellsNotifications = (
    viewCells: TableCellSeenStatus[],
    rowsStatus: RowSeenStatus[],
    columnsStatus: ViewColumnSeenStatus[],
    isAdmin: boolean
) => {
    const hiddenRowIdsByViewFilter = getHiddenRowIds(viewCells, columnsStatus);

    const cellsWithNotifications = viewCells.filter((viewCell) => {
        const isNewRow = !rowsStatus.some(
            (rowStatus) =>
                rowStatus.customRowId === viewCell.customRowId &&
                getItemSeenStatus(rowStatus, isAdmin)
        );

        const targetViewColumn = columnsStatus.find(
            (columnStatus) =>
                columnStatus.customColumnId === viewCell.customColumnId
        );

        const isNewColumn = !getItemSeenStatus(targetViewColumn, isAdmin);

        const isRowHidden = hiddenRowIdsByViewFilter.some(
            (rowId) => rowId === viewCell.customRowId
        );

        const result =
            !viewCell.isSeenByUser && !isNewRow && !isNewColumn && !isRowHidden;

        return result;
    });

    return cellsWithNotifications?.length ?? 0;
};

const getItemSeenStatus = (
    item: RowSeenStatus | ViewColumnSeenStatus,
    isAdmin: boolean
) => (isAdmin ? item?.isSeenByAdmin : item?.isSeenByUser);

const getNewRowsCount = (
    rowsStatus: RowSeenStatus[],
    isAdmin: boolean,
    viewStatus: ViewNotificationStatus,
    cellsSeenStatus: TableCellSeenStatus[],
    tableId: number
) => {
    let result = 0;

    const tableCells = cellsSeenStatus.filter(
        (cell) => cell.tableId === tableId
    );

    rowsStatus.forEach((rowStatus) => {
        const rowCells = tableCells.filter(
            (f) => f.customRowId === rowStatus.customRowId
        );
        const isAvailable = isRowCellsAvailableByViewFilter(
            rowCells,
            viewStatus.columnsSeenStatus
        );

        if (
            isAvailable &&
            !getItemSeenStatus(rowStatus, isAdmin) &&
            rowStatus.isVisible
        ) {
            result++;
        }
    });

    return result;
};

const getNewColumnsCount = (
    columnsStatus: ViewColumnSeenStatus[],
    isAdmin: boolean
) =>
    columnsStatus.filter((column) => !getItemSeenStatus(column, isAdmin))
        ?.length ?? 0;

const getHiddenRowIds = (
    viewCells: TableCellSeenStatus[],
    columnsStatus: ViewColumnSeenStatus[]
): string[] => {
    const visibleCells = viewCells.filter((viewCell) => {
        const targetViewColumn = columnsStatus.find(
            (columnStatus) =>
                columnStatus.customColumnId === viewCell.customColumnId
        );
        const isVisibleByViewFilter = isCellVisibleByViewFilterCondition(
            viewCell,
            targetViewColumn
        );

        return !isVisibleByViewFilter;
    });

    const result = visibleCells.map((viewCell) => viewCell.customRowId);

    return result;
};
