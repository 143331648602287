import { createAsyncThunk } from '@reduxjs/toolkit';
import { DataRequestProject } from '../../DataRequestProject';
import { DataRequestNotificationsState } from '../data-request-notifications.state';
import { updateQuestionsStatus } from '../data-forms-status-mapper';
import {
    updateCellsStauts,
    updateTablesSeenStatus,
} from '../data-tables-status-mapper';
import { updateTabsState } from '../project-tabs-status-mapper';

export interface UpdateQuestionsAndCellsArguments {
    projects: DataRequestProject[];
}

export const updateQuestionsAndCells = createAsyncThunk(
    'dataRequestNotifications/updateQuestionsAndCells',
    async (args: UpdateQuestionsAndCellsArguments, dispatch: any) => {
        const notificationsState =
            dispatch.getState().dataRequestsNotifications;
        const result = await update(args.projects, notificationsState);

        return result;
    }
);

const update = (
    projects: DataRequestProject[],
    state: DataRequestNotificationsState
) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            const updatedState = { ...state };
            const [
                updatedQuestionsStatus,
                updatedCellsStatus,
                tablesNotifications,
            ] = projects.reduce(
                ([questionsStatus, cellsStatus, tableStatus], project) => {
                    const projectQuestions = updateQuestionsStatus(
                        questionsStatus,
                        [project]
                    );

                    const projectCells = updateCellsStauts(cellsStatus, [
                        project,
                    ]);

                    const projectTables = updateTablesSeenStatus(tableStatus, [
                        project,
                    ]);

                    return [projectQuestions, projectCells, projectTables];
                },
                [
                    [...updatedState.questionsSeenStatus],
                    [...updatedState.tableCellsSeenStatus],
                    [...updatedState.tablesNotificationsStatus],
                ]
            );

            updateTabsState(
                updatedState,
                updatedQuestionsStatus,
                updatedCellsStatus,
                tablesNotifications
            );

            resolve({
                ...updatedState,
                questionsSeenStatus: updatedQuestionsStatus,
                tableCellsSeenStatus: updatedCellsStatus,
                tablesNotificationsStatus: tablesNotifications,
            });
        });
    });
};
