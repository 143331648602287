import React from 'react';
import { DAY_WIDTH, FIRST_COLUMN_GAP } from '../../GanttChartConstants';
import GanttChartDateHelper from '../../helpers/GanttChartDateHelper';
import sizeMe from 'react-sizeme';
import GanttChartRegistry from '../../helpers/registry/GanttChartRegistry';
import { GanttChartDataRow } from './GanttChartDataRow';
import { GanttChartDataTask } from './GanttChartDataTask';
import { GanttChartItem } from '../../models/GanttChartItem.model';

export interface DataViewPortProps {
    itemheight: number;
    data: GanttChartItem[];
    nowposition: number;
    upperBoundary: number;
}
export const DataViewPort = (props: DataViewPortProps) => {
    const getContainerHeight = () => {
        const rows = GanttChartRegistry.getNumberOfRows(props.data);
        let new_height = rows > 0 ? rows * props.itemheight : 10;
        return new_height;
    };

    function normalizeDate(date: Date | string): Date {
        const normalizedDate = new Date(date);
        normalizedDate.setHours(12, 40, 0, 0); 
        return normalizedDate;
    }

    const renderRows = () => {
        let result: React.JSX.Element[] = [];
        const groups = GanttChartRegistry.groupData(props.data);

        Object.keys(groups).forEach((key, i) => {
            const group = groups[key];
            if (group[0].type === 'header') {
                const otherItems = group.slice(1);
                result.push(
                    <GanttChartDataRow
                        key={key}
                        top={i * props.itemheight}
                        itemheight={props.itemheight}>
                        <div
                            className="row-header"
                            style={{
                                width: DAY_WIDTH * FIRST_COLUMN_GAP,
                            }}>
                            {group[0].name}
                        </div>

                        {otherItems.map((item: GanttChartItem) => {
                            let new_position =
                                GanttChartDateHelper.dateToPixel(
                                    item.start,
                                    props.nowposition,
                                    DAY_WIDTH
                                ) +
                                DAY_WIDTH * FIRST_COLUMN_GAP;
                            let new_width =
                                GanttChartDateHelper.dateToPixel(
                                    normalizeDate(item.end),
                                    props.nowposition,
                                    DAY_WIDTH
                                ) +
                                DAY_WIDTH -
                                new_position;
                            return (
                                <GanttChartDataTask
                                    key={item.id}
                                    item={item}
                                    color={item.color}
                                    backgroundColor={item.backgroundColor}
                                    left={new_position}
                                    width={new_width}
                                    height={props.itemheight - 1}
                                />
                            );
                        })}
                    </GanttChartDataRow>
                );
            } else {
                result.push(
                    <GanttChartDataRow
                        rowNumber={group[0].orderNo}
                        key={key}
                        top={i * props.itemheight}
                        itemheight={props.itemheight}>
                        <div
                            className="row-number"
                            style={{
                                width: DAY_WIDTH * FIRST_COLUMN_GAP,
                            }}>
                            {group[0].orderNo}
                        </div>
                        {group.map((item: GanttChartItem) => {
                            let new_position =
                                GanttChartDateHelper.dateToPixel(
                                    item.start,
                                    props.nowposition,
                                    DAY_WIDTH
                                ) +
                                DAY_WIDTH * FIRST_COLUMN_GAP;
                            let new_width =
                                GanttChartDateHelper.dateToPixel(
                                    normalizeDate(item.end),
                                    props.nowposition,
                                    DAY_WIDTH
                                ) +
                                DAY_WIDTH -
                                new_position;
                            return (
                                <GanttChartDataTask
                                    key={item.id}
                                    item={item}
                                    color={item.color}
                                    backgroundColor={item.backgroundColor}
                                    left={new_position}
                                    width={new_width}
                                    height={props.itemheight}
                                />
                            );
                        })}
                    </GanttChartDataRow>
                );
            }
        });
        return result;
    };

    return (
        <div id="timeLinedataViewPort" className="timeLine-main-data-viewPort">
            <div
                className="timeLine-main-data-container"
                style={{
                    height: getContainerHeight(),
                    width: props.upperBoundary,
                    maxWidth: props.upperBoundary,
                }}>
                {renderRows()}
            </div>
        </div>
    );
};

export default sizeMe({ monitorWidth: false, monitorHeight: true })(
    DataViewPort
);
