const MIL_IN_HOUR = 1000 * 3600;

class GanttChartDateHelper {
    dateToPixel(
        input: Date | string,
        nowPosition: number,
        dayWidth: number
    ): number {
        const nowDate = this.getToday();
        const inputTime = new Date(input);

        // Daylight saving patch
        const lightSavingDiff =
            (inputTime.getTimezoneOffset() - nowDate.getTimezoneOffset()) *
            60 *
            1000;
        const timeDiff =
            inputTime.getTime() - nowDate.getTime() - lightSavingDiff;
        const pixelWeight = dayWidth / 24; // Value in pixels of one hour

        return (timeDiff / MIL_IN_HOUR) * pixelWeight + nowPosition;
    }

    pixelToDate(position: number, nowPosition: number, dayWidth: number): Date {
        const hoursInPixel = 24 / dayWidth;
        const pixelsFromNow = position - nowPosition;
        const today = this.getToday();
        const millisecondsFromNow =
            today.getTime() + pixelsFromNow * hoursInPixel * MIL_IN_HOUR;
        const result = new Date(millisecondsFromNow);

        const lightSavingDiff =
            (result.getTimezoneOffset() - today.getTimezoneOffset()) *
            60 *
            1000;
        result.setTime(result.getTime() + lightSavingDiff);

        return result;
    }

    getToday(): Date {
        const date = new Date();
        date.setHours(0, 0, 0, 0);
        return date;
    }

    dayToPosition(day: number, now: number, dayWidth: number): number {
        return day * dayWidth + now;
    }

    getDifferenceInDays(date1: Date, date2: Date): number {
        const oneDayInMs = 24 * 60 * 60 * 1000;
        const diffInMs = Math.abs(date2.getTime() - date1.getTime());
        const diffInDays = Math.floor(diffInMs / oneDayInMs);
        return diffInDays < 30 ? 30 : diffInDays;
    }
}

const helper = new GanttChartDateHelper();
export default helper;
