export enum TimelineParty {
    VetValue = 0,
    Seller = 1,
    Buyer = 2,
}

export const TimelinePartyList = [
    { id: TimelineParty.VetValue, name: 'VetValue' },
    { id: TimelineParty.Seller, name: 'Seller' },
    { id: TimelineParty.Buyer, name: 'Buyer' },
];
