import React, { useEffect, useRef, useState } from 'react';
import './ProjectEditorHost.scss';
import {
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Card,
    CardBody,
    Col,
    Label,
    Row,
} from 'reactstrap';
import { HomeButton } from '../../../../components/HomeButton/home-button';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Field, Form } from '@availity/form';
import * as yup from 'yup';
import { useStateSelector } from '../../../../store/selectors';
import createNotification from '../../../../utils/createNotification';
import { DataFormType } from '../../../../models/DataRequestHub/DataFormTypeEnum';
import DataFormQuestion from '../../../../models/DataRequestHub/DataFormQuestion';
import ButtonLoader from '../../../../components/Layout/Buttons/ButtonLoader';
import { EditProjectVariablePopup } from '../Popups/EditProjectVariablePopup/EditProjectVariablePopup';
import {
    ProjectVariable,
    ProjectVariableOption,
    SystemVariableNames,
    SystemVariableNamesList,
} from '../../../../models/DataRequestHub/ProjectVariable';
import DataRequestProjectNameEditor from '../DataRequestProjectNameEditor/DataRequestProjectNameEditor';
import DocumentEditor from './DocumentEditor/DocumentEditor';
import FinancialRequestEditor from './FinancialRequestEditor/FinancialRequestEditor';
import { TableSettingsPopup } from '../Popups/TableSettingsPopup/TableSettingsPopup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWrench } from '@fortawesome/free-solid-svg-icons';
import DataLoader from '../../../../components/DataLoader/DataLoader';
import { ProjectVariableHost } from '../DataAndDocumentRequestsDashboard/ProjectVariableHost/ProjectVariableHost';
import QuestionnaireEditorHost from './QuestionnaireEditor/QuestionnaireEditorHost';
import {
    DataRequestProject,
    DataRequestProjectState,
    emptyProject,
} from '../../../../models/DataRequestHub/DataRequestProject';
import {
    DataForm,
    emptyDataForm,
} from '../../../../models/DataRequestHub/DataForm';
import { DesignModeSwitcher } from '../../../DataRequest/DesignModeSwitcher/DesignModeSwitcher';
import {
    DataTable,
    DataTableView,
    emptyDataTable,
    emptyTableView,
} from '../../../../models/DataRequestHub/DataTable';
import DataTableViewEditorHost from './DataTableViewEditor/DataTableViewEditorHost';
import DataTableEditorHost from './DataTableEditor/DataTableEditorHost';
import { validateProjectDataTables } from '../../../../components/EditableTable/EditableTableValidationHelper';
import { EditorModeEnum } from '../../../../models/DataRequestHub/ProjectEditorModeEnum';
import {
    EditorTypeEnum,
    EditorTypeNameList,
} from '../../../../models/DataRequestHub/ProjectEditorEnum';

export enum DataTableEditorModeEnum {
    Table,
    CreateView,
    EditView,
}

export const ProjectEditorHost = () => {
    const axios = useStateSelector((s) => s.core.axios);
    const gridTableRef = useRef(null);
    const [project, setProject] = useState<DataRequestProject>(emptyProject);
    const [mode, setMode] = useState<EditorModeEnum>(
        EditorModeEnum.CreateTemplate
    );
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [
        isProjectVariableEditPopupVisible,
        setIsProjectVariableEditPopupVisible,
    ] = useState(false);
    const [isTableSettingsPopupVisible, setIsTableSettingsPopupVisible] =
        useState(false);
    const [variableNameToEdit, setVariableNameToEdit] = useState('');
    const [varialbeIdToEdit, setVarialbeIdToEdit] = useState(0);
    const [likelySourceVariable, setLikelySourceVariable] =
        useState<ProjectVariable>({ id: 0, name: '', options: [] });
    const [answerTypeVariable, setAnswerTypeVariable] =
        useState<ProjectVariable>({ id: 0, name: '', options: [] });
    const [selectedDataTableId, setSelectedDataTableId] = useState<number>(0);
    const [selectedTableViewId, setSelectedTableViewId] = useState<number>(0);
    const [dataTableEditorMode, setDataTableEditorMode] =
        useState<DataTableEditorModeEnum>(DataTableEditorModeEnum.Table);
    const [priorityVariable, setPriorityVariable] = useState<ProjectVariable>({
        id: 0,
        name: '',
        options: [],
    });
    const [dataTableViewLinksVariable, setDataTableViewLinksVariable] =
        useState<ProjectVariable>({
            id: 0,
            name: '',
            options: [],
        });
    const [formTypeVariable, setFormTypeVariable] = useState<ProjectVariable>({
        id: 0,
        name: '',
        options: [],
    });
    const [numericFormatVariable, setNumericFormatVariable] =
        useState<ProjectVariable>({ id: 0, name: '', options: [] });
    const [textSizeVariable, setTextSizeVariable] = useState<ProjectVariable>({
        id: 0,
        name: '',
        options: [],
    });
    const [notSystemVariables, setNotSystemVariables] = useState<
        ProjectVariable[]
    >([
        {
            id: 0,
            name: '',
            options: [],
        },
    ]);

    const [displayFormatVariable, setDisplayFormatVariable] =
        useState<ProjectVariable>({ id: 0, name: '', options: [] });
    const [frequencyVariable, setFrequencyVariable] = useState<ProjectVariable>(
        {
            id: 0,
            name: '',
            options: [],
        }
    );
    const [projectVariableOptionsToEdit, setProjectVariableOptionsToEdit] =
        useState<Array<ProjectVariableOption>>([]);
    const templateRef = useRef(null);
    const location = useLocation();
    const [currentTab, setCurrentTab] = useState<number>();
    const [additionalUrlToReturn, setAdditionalUrlToReturn] = useState('');
    const [originalTemplateName, setOriginalTemplateName] = useState('');
    const [searchParams] = useSearchParams({});
    const [tabData] = useState([
        'Documents',
        'Financial Requests',
        'Questionnaire',
        'Data Tables',
        'Variables',
    ]);
    const [tabsHasErrorState, setTabsHasErrorState] = useState<any>({});
    const [shouldNavigateToProjectView, setShouldNavigateToProjectView] =
        useState<boolean>(false);

    useEffect(() => {
        let editorType = EditorTypeEnum.Documents;
        if (location.state) {
            editorType = location.state.type as EditorTypeEnum;
            configureInitialState(editorType);
        }

        const tab = searchParams.get('tab');
        const tabNumber = Number(tab);
        if (tabNumber) {
            const index = tabNumber - 1;
            setCurrentTab(index);
        } else {
            setCurrentTab(editorType);
        }
    }, []);

    const configureInitialState = (editorType: EditorTypeEnum) => {
        const additionalUrl = location.state.additionalUrlToReturn as string;
        setAdditionalUrlToReturn(additionalUrl);

        const shouldNavigateToProjectViewMode =
            location.state.shouldNavigateToProjectViewMode ?? false;
        setShouldNavigateToProjectView(shouldNavigateToProjectViewMode);

        const mode = location.state.mode as EditorModeEnum;
        setMode(mode);

        let project = location.state.project as DataRequestProject;
        const dataForms = location.state.dataForms as DataForm[];
        const dataTables = location.state.dataTables as DataTable[];

        setSelectedDataTableId(
            location.state.selectedDataTableId ??
                location.state.dataTableEditorParams?.targetTableId ??
                0
        );
        setSelectedTableViewId(
            location.state.selectedTableViewId ??
                location.state.dataTableEditorParams?.targetTableViewId ??
                0
        );
        setDataTableEditorMode(
            location.state.dataTableEditorMode ?? dataTableEditorMode
        );
        if (!project) {
            const defaultProject: DataRequestProject = {
                ...project,
                dataForms: isDataFormEditorType(editorType)
                    ? dataForms ?? [
                          {
                              ...emptyDataForm,
                              formType: mapDocumentFormType(editorType),
                          },
                      ]
                    : [],
                dataTables: isDataFormEditorType(editorType)
                    ? []
                    : dataTables ?? [
                          { ...emptyDataTable, views: [{ ...emptyTableView }] },
                      ],
            };
            project = defaultProject;
        }

        project = {
            ...project,
            dataForms: project.dataForms,
            dataTables: project.dataTables,
        };

        setProject(project);
        fetchProjectVariables(project?.id ?? 0);
    };

    const isDataFormEditorType = (editorType: EditorTypeEnum) => {
        switch (editorType) {
            case EditorTypeEnum.Documents:
                return true;

            case EditorTypeEnum.FinancialRequest:
                return true;

            case EditorTypeEnum.Questionnaire:
                return true;

            default:
                return false;
        }
    };

    const mapDocumentFormType = (editorType: EditorTypeEnum) => {
        switch (editorType) {
            case EditorTypeEnum.Documents:
                return DataFormType.Documents;

            case EditorTypeEnum.FinancialRequest:
                return DataFormType.FinancialRequest;

            case EditorTypeEnum.Questionnaire:
                return DataFormType.Questionnaire;

            default:
                return DataFormType.Documents;
        }
    };

    useEffect(() => {
        let templateName = '';

        switch (currentTab) {
            case EditorTypeEnum.Documents:
                templateName = project.dataForms?.find(
                    (x) => x.formType === DataFormType.Documents
                )?.originalTemplateName;
                break;
            case EditorTypeEnum.FinancialRequest:
                templateName = project.dataForms?.find(
                    (x) => x.formType === DataFormType.FinancialRequest
                )?.originalTemplateName;
                break;
        }

        setOriginalTemplateName(templateName);
    }, [currentTab]);

    const fetchProjectVariables = (projectId: number) => {
        setIsLoading(true);
        axios
            .get(`/api/DataRequestProject/${projectId}/ProjectVariables`)
            .then((response) => {
                if (response.status === 200) {
                    const variables = response.data as Array<ProjectVariable>;

                    const likelySourceVariable = variables.find(
                        (x) =>
                            x.name ==
                            SystemVariableNamesList.find(
                                (y) => y.id == SystemVariableNames.LikelySource
                            ).name
                    );

                    const displayFormatVariable = variables.find(
                        (x) =>
                            x.name ==
                            SystemVariableNamesList.find(
                                (y) => y.id == SystemVariableNames.DisplayFormat
                            ).name
                    );

                    const frequencyVariable = variables.find(
                        (x) =>
                            x.name ==
                            SystemVariableNamesList.find(
                                (y) => y.id == SystemVariableNames.Frequency
                            ).name
                    );

                    const answerTypeVariable = variables.find(
                        (x) =>
                            x.name ==
                            SystemVariableNamesList.find(
                                (y) => y.id == SystemVariableNames.AnswerType
                            ).name
                    );

                    const priorityVariable = variables.find(
                        (x) =>
                            x.name ==
                            SystemVariableNamesList.find(
                                (y) => y.id == SystemVariableNames.Priority
                            ).name
                    );

                    const dataTableViewLinksVariable = variables.find(
                        (x) =>
                            x.name ==
                            SystemVariableNamesList.find(
                                (y) =>
                                    y.id ==
                                    SystemVariableNames.DataTableViewLinksVariable
                            ).name
                    );

                    const textSizeVariable = variables.find(
                        (x) =>
                            x.name ==
                            SystemVariableNamesList.find(
                                (y) => y.id == SystemVariableNames.TextSize
                            ).name
                    );

                    const formTypeVariable = variables.find(
                        (x) =>
                            x.name ==
                            SystemVariableNamesList.find(
                                (y) => y.id == SystemVariableNames.FormType
                            ).name
                    );

                    const numericFormatVariable = variables.find(
                        (x) =>
                            x.name ==
                            SystemVariableNamesList.find(
                                (y) => y.id == SystemVariableNames.NumericFormat
                            ).name
                    );

                    const notSystemVariables = variables.filter(
                        (x) =>
                            !SystemVariableNamesList.some(
                                (y) => x.name === y.name
                            )
                    );

                    setLikelySourceVariable(likelySourceVariable);
                    setAnswerTypeVariable(answerTypeVariable);
                    setPriorityVariable(priorityVariable);
                    setDataTableViewLinksVariable(dataTableViewLinksVariable);
                    setFormTypeVariable(formTypeVariable);
                    setNumericFormatVariable(numericFormatVariable);
                    setTextSizeVariable(textSizeVariable);
                    setDisplayFormatVariable(displayFormatVariable);
                    setFrequencyVariable(frequencyVariable);
                    setNotSystemVariables(notSystemVariables);
                } else {
                    createNotification(
                        'An error occured while fetching project variables',
                        'error'
                    );
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const getHeaderTitle = () => {
        var typeName = getEditorTypeName();
        switch (mode) {
            case EditorModeEnum.CreateTemplate:
                return `Add New ${typeName} Template`;
            case EditorModeEnum.EditTempalte:
                return `Edit ${typeName} Template`;
            case EditorModeEnum.EditProject:
                return (
                    <div className="name-editor-container">
                        <DataRequestProjectNameEditor
                            projectName={project.name}
                            projectId={project.id}
                            onSuccessfulUpdate={(value) =>
                                setProject({ ...project, name: value })
                            }
                        />
                        <div>{` - Practice: ${project.practiceName}`}</div>
                    </div>
                );
            default:
                break;
        }
    };

    const getBreadcrumbItem = () => {
        const typeName = getEditorTypeName();
        switch (mode) {
            case EditorModeEnum.CreateTemplate:
                return `Add New ${typeName} Template`;
            case EditorModeEnum.EditTempalte:
                return `Edit ${typeName} Template`;
            case EditorModeEnum.EditProject:
                return `${project.name} - Practice: ${project.practiceName}`;
            default:
                break;
        }
    };

    const getEditorTypeName = () => {
        var typeName =
            isDataFormEditorType(currentTab) ||
            (currentTab === EditorTypeEnum.DataTable &&
                dataTableEditorMode !== DataTableEditorModeEnum.CreateView &&
                dataTableEditorMode !== DataTableEditorModeEnum.EditView)
                ? EditorTypeNameList.find((c) => c.id == currentTab)?.name
                : 'Table View';

        return typeName;
    };

    const getTemplateNameField = () => {
        if (mode === EditorModeEnum.EditProject) {
            return (
                <>
                    <Label>
                        <strong>{'Original Template Name: '}</strong>
                        {originalTemplateName}
                    </Label>
                </>
            );
        }

        const currentForm = getCurrentTemplateForm();
        const currentTable = getCurrentTemplateTable();
        const templateName =
            currentTab === EditorTypeEnum.DataTable
                ? currentTable?.originalTemplateName
                : currentForm?.originalTemplateName;
        return (
            <>
                <Label>
                    <strong>Template Name:</strong>
                </Label>
                <Field
                    name="templateName"
                    value={templateName}
                    onChange={(event: any) => {
                        if (currentTab === EditorTypeEnum.DataTable) {
                            const state: DataTable = {
                                ...currentTable,
                                originalTemplateName: event.target.value,
                            };
                            updateTable(state);
                        } else {
                            const state: DataForm = {
                                ...currentForm,
                                originalTemplateName: event.target.value,
                                formType: currentTab,
                                isVisible: true,
                            };
                            updateForm(state);
                        }
                    }}></Field>
            </>
        );
    };

    const hasAnyFormError = () =>
        Object.entries(tabsHasErrorState).some((formState) => {
            const [dataFormType, hasError] = formState;

            if (Number(dataFormType) === currentTab) {
                return false;
            }

            return hasError;
        });

    const getCurrentTemplateForm = () =>
        project.dataForms.find((form) => form.formType === currentTab);

    const getCurrentTemplateTable = () =>
        project.dataTables.find((x) => x.id === selectedDataTableId);
    const getCurrentTemplateTableView = () =>
        getCurrentTemplateTable()?.views?.find(
            (f) => f.id === selectedTableViewId
        );

    const getEmptyTableView = () =>
        getCurrentTemplateTable()?.views?.find((f) => f.id === 0);

    const onSuccessfulSubmit = (templateName: string, description?: string) => {
        let isRowsValid = true;
        if (currentTab !== EditorTypeEnum.Variable) {
            isRowsValid = gridTableRef?.current.validateAllRows();
            setTabsHasErrorState({
                ...tabsHasErrorState,
                [currentTab]: !isRowsValid,
            });
        }

        if (!isRowsValid || hasAnyFormError()) {
            return;
        }

        if (
            currentTab === EditorTypeEnum.DataTable &&
            dataTableEditorMode === DataTableEditorModeEnum.EditView
        ) {
            const areDataTablesValid = validateProjectDataTables(
                project.dataTables
            );

            if (!areDataTablesValid) {
                return;
            }
        }

        switch (mode) {
            case EditorModeEnum.CreateTemplate:
                createTemplate(templateName, description);
                break;
            case EditorModeEnum.EditTempalte:
                updateTemplate();
                break;
            case EditorModeEnum.EditProject:
                updateProject();
                break;
            default:
                break;
        }
    };

    const createTemplate = (templateName: string, description?: string) => {
        setIsLoading(true);

        let relativeCreateUrl = '';
        let params = {};
        switch (currentTab) {
            case EditorTypeEnum.DataTable:
                const templateColumns = getCurrentTemplateTable()?.columns;

                if (dataTableEditorMode === DataTableEditorModeEnum.Table) {
                    relativeCreateUrl = 'dataTables';
                    params = {
                        originalTemplateName: templateName,
                        columns: templateColumns,
                    };
                }

                if (
                    dataTableEditorMode === DataTableEditorModeEnum.CreateView
                ) {
                    const currentTable = getCurrentTemplateTable();
                    const currentTableView = getCurrentTemplateTableView();
                    relativeCreateUrl = `dataTables/${currentTable.id}/tableViews`;
                    params = {
                        name: templateName,
                        description: description,
                        viewColumns: currentTableView.viewColumns,
                    };
                }
                break;
            case EditorTypeEnum.Documents:
            case EditorTypeEnum.FinancialRequest:
            case EditorTypeEnum.Questionnaire:
                const templateQuestions = getCurrentTemplateForm()?.questions;

                relativeCreateUrl = 'dataForms';
                params = {
                    originalTemplateName: templateName,
                    formType: currentTab,
                    questions: templateQuestions,
                };
                break;
        }
        axios
            .post(
                `/api/dataRequestTemplateProject/${relativeCreateUrl}`,
                params
            )
            .then((response) => {
                if (response.status === 200) {
                    navigate(
                        `/data-and-document-templates-dashboard${additionalUrlToReturn}`
                    );
                } else {
                    createNotification(
                        'An error occured while creating template',
                        'error'
                    );
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const updateTemplate = () => {
        setIsLoading(true);

        let params = {};
        let relativeUpdateUrl = '';
        switch (currentTab) {
            case EditorTypeEnum.DataTable:
                if (dataTableEditorMode === DataTableEditorModeEnum.Table) {
                    const templateTable = getCurrentTemplateTable();

                    relativeUpdateUrl = 'dataTables';

                    params = {
                        id: templateTable.id,
                        originalTemplateName:
                            templateTable.originalTemplateName,
                        columns: templateTable.columns,
                        views: templateTable.views,
                    };
                } else {
                    const currentTable = getCurrentTemplateTable();
                    const currentTableView = getCurrentTemplateTableView();
                    relativeUpdateUrl = `dataTables/${currentTable.id}/tableViews`;
                    params = {
                        id: currentTableView.id,
                        name: currentTableView.name,
                        description: currentTableView.description,
                        viewColumns: currentTableView.viewColumns,
                    };
                }
                break;
            case EditorTypeEnum.Documents:
            case EditorTypeEnum.FinancialRequest:
            case EditorTypeEnum.Questionnaire:
                const templateForm = getCurrentTemplateForm();

                relativeUpdateUrl = 'dataForms';
                params = {
                    id: templateForm.id,
                    originalTemplateName: templateForm.originalTemplateName,
                    formType: currentTab,
                    questions: templateForm.questions,
                };
                break;
        }

        axios
            .put(`/api/dataRequestTemplateProject/${relativeUpdateUrl}`, params)
            .then((response) => {
                if (response.status === 200) {
                    navigate(
                        `/data-and-document-templates-dashboard${additionalUrlToReturn}`
                    );
                } else {
                    createNotification(
                        'An error occured while updating template',
                        'error'
                    );
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const updateProject = () => {
        setIsLoading(true);
        const params = {
            dataTables: project.dataTables,
            dataForms: project.dataForms,
        };

        axios
            .put(`/api/DataRequestProjects/${project.id}`, params)
            .then((response) => {
                if (response.status === 200) {
                    if (
                        dataTableEditorMode ===
                            DataTableEditorModeEnum.CreateView &&
                        mode === EditorModeEnum.EditProject
                    ) {
                        fetchUpdatedProject();
                    } else if (shouldNavigateToProjectView) {
                        navigateToProjectViewMode();
                    } else {
                        navigate('/data-and-document-requests-dashboard');
                    }
                } else {
                    createNotification(
                        'An error occured while updating template',
                        'error'
                    );
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const updateDocumentTemplateRows = (
        rows: DataFormQuestion[],
        currentType: DataFormType
    ) => {
        const updatedDataForms = [...project.dataForms];
        const updatedForm = project.dataForms.find(
            (form) => form.formType === currentType
        );

        const formIndex = project.dataForms.indexOf(updatedForm);

        updatedForm.questions = rows;
        updatedDataForms[formIndex] = updatedForm;

        const updatedProject = {
            ...project,
            dataForms: updatedDataForms,
        };

        setProject(updatedProject);
    };

    const updateForm = (newForm: DataForm) => {
        const updatedDataForms = [...project.dataForms];
        const targetForm = updatedDataForms.find(
            (form) => form.id === newForm.id
        );
        const targetIndex = updatedDataForms.indexOf(targetForm);

        updatedDataForms[targetIndex] = newForm;

        setProject({ ...project, dataForms: updatedDataForms });
    };

    const updateTableView = (
        tableId: number,
        updatedTableView: DataTableView
    ) => {
        const updatedDataTables = [...project.dataTables];
        const targetTable = updatedDataTables.find(
            (table) => table.id === tableId
        );
        const targetTableIndex = updatedDataTables.indexOf(targetTable);

        const updatedTableViews = [...targetTable.views];
        const targetView = updatedTableViews.find(
            (f) => f.id === updatedTableView.id
        );
        const targetViewIndex = updatedTableViews.indexOf(targetView);

        updatedTableViews[targetViewIndex] = updatedTableView;

        updatedDataTables[targetTableIndex].views = updatedTableViews;

        setProject({ ...project, dataTables: updatedDataTables });
    };

    const updateMultipleForms = (updatedForms: DataForm[]) =>
        updatedForms.forEach((form) => updateForm(form));

    const updateTable = (newTable: DataTable) => {
        const updatedDataTables = [...project.dataTables];
        const targetTable = updatedDataTables.find(
            (table) => table.id === newTable.id
        );
        const targetIndex = updatedDataTables.indexOf(targetTable);

        updatedDataTables[targetIndex] = newTable;
        setProject({ ...project, dataTables: updatedDataTables });
    };

    const updateMultipleTables = (updatedTables: DataTable[]) =>
        updatedTables.forEach((table) => updateTable(table));

    const updateMultipleTableViews = (
        tableId: number,
        updatedTableViews: DataTableView[]
    ) => {
        updatedTableViews.forEach((view) => updateTableView(tableId, view));
    };

    const getTemplateRows = (formType: DataFormType) => [
        ...(project.dataForms.find((form) => form.formType === formType)
            ?.questions ?? []),
    ];

    const getForms = (formType: DataFormType) => [
        ...project.dataForms.filter((form) => form.formType === formType),
    ];

    const getDataTables = () => [...project.dataTables];

    const getDataTable = (id: number) =>
        project.dataTables.find((f) => f.id === id);

    const closeDataTableViewEditor = () => {
        const currentTable = getDataTable(selectedDataTableId);
        currentTable.views = currentTable.views.filter((f) => f.id);
        updateTable({ ...currentTable });
        setSelectedDataTableId(null);
        setSelectedTableViewId(null);
        setDataTableEditorMode(DataTableEditorModeEnum.Table);
        location.state.dataTableEditorParams = null;
    };

    const renderEditor = () => {
        switch (currentTab) {
            case EditorTypeEnum.Documents:
                return (
                    <DocumentEditor
                        setIsProjectVariableEditPopupVisible={
                            setIsProjectVariableEditPopupVisible
                        }
                        setVarialbeIdToEdit={setVarialbeIdToEdit}
                        setVariableNameToEdit={setVariableNameToEdit}
                        templateRows={getTemplateRows(DataFormType.Documents)}
                        setTemplateRows={(rows) => {
                            updateDocumentTemplateRows(
                                rows,
                                DataFormType.Documents
                            );
                        }}
                        setProjectVariableOptionsToEdit={
                            setProjectVariableOptionsToEdit
                        }
                        isLoading={isLoading}
                        likelySourceVariable={likelySourceVariable}
                        displayFormatVariable={displayFormatVariable}
                        ref={gridTableRef}
                    />
                );
            case EditorTypeEnum.FinancialRequest:
                return (
                    <FinancialRequestEditor
                        setIsProjectVariableEditPopupVisible={
                            setIsProjectVariableEditPopupVisible
                        }
                        setVarialbeIdToEdit={setVarialbeIdToEdit}
                        setVariableNameToEdit={setVariableNameToEdit}
                        templateRows={getTemplateRows(
                            DataFormType.FinancialRequest
                        )}
                        setTemplateRows={(rows) => {
                            updateDocumentTemplateRows(
                                rows,
                                DataFormType.FinancialRequest
                            );
                        }}
                        setProjectVariableOptionsToEdit={
                            setProjectVariableOptionsToEdit
                        }
                        isLoading={isLoading}
                        likelySourceVariable={likelySourceVariable}
                        displayFormatVariable={displayFormatVariable}
                        frequencyVariable={frequencyVariable}
                        ref={gridTableRef}
                    />
                );
            case EditorTypeEnum.Questionnaire:
                return (
                    <QuestionnaireEditorHost
                        editMode={mode}
                        isAdminView={true}
                        templateForms={getForms(DataFormType.Questionnaire)}
                        dataTableViewLinksVariable={dataTableViewLinksVariable}
                        availableDataTableViewLinksVariable={getAvailableDataTableViewLinksVariable()}
                        setForms={updateMultipleForms}
                        notSystemVariables={notSystemVariables}
                        isLoading={isLoading}
                        answerType={answerTypeVariable}
                        priority={priorityVariable}
                        formType={formTypeVariable}
                        numericFormat={numericFormatVariable}
                        textSize={textSizeVariable}
                        ref={gridTableRef}
                        project={project}
                        updateProjectData={fetchUpdatedProject}
                        updateProjectVariables={() =>
                            fetchProjectVariables(project.id)
                        }
                    />
                );
            case EditorTypeEnum.DataTable:
                if (dataTableEditorMode === DataTableEditorModeEnum.Table) {
                    return (
                        <DataTableEditorHost
                            editMode={mode}
                            isAdminView={true}
                            dataTables={getDataTables()}
                            setDataTables={updateMultipleTables}
                            setDataTableViews={(views) =>
                                updateMultipleTableViews(
                                    selectedDataTableId,
                                    views
                                )
                            }
                            isLoading={isLoading}
                            ref={gridTableRef}
                            project={project}
                            answerType={answerTypeVariable}
                            formType={formTypeVariable}
                            numericFormat={numericFormatVariable}
                            notSystemVariables={notSystemVariables}
                            updateProjectData={fetchUpdatedProject}
                            handleCreateViewClick={handleCreateViewClick}
                            updateProjectVariables={() =>
                                fetchProjectVariables(project.id)
                            }
                            handleEditViewClick={handleEditViewClick}
                        />
                    );
                } else {
                    return (
                        <DataTableViewEditorHost
                            editMode={mode}
                            dataTable={getDataTable(selectedDataTableId)}
                            setDataTableViews={(views) =>
                                updateMultipleTableViews(
                                    selectedDataTableId,
                                    views
                                )
                            }
                            isLoading={isLoading}
                            ref={gridTableRef}
                            project={project}
                            updateProjectData={fetchUpdatedProject}
                            updateProjectVariables={() =>
                                fetchProjectVariables(project.id)
                            }
                            notSystemVariables={notSystemVariables}
                            selectedTableViewId={selectedTableViewId}
                            closeEditor={closeDataTableViewEditor}
                            viewEditorMode={dataTableEditorMode}
                        />
                    );
                }
            case EditorTypeEnum.Variable:
                return (
                    <ProjectVariableHost
                        onUpdate={() => {
                            fetchProjectVariables(project.id);
                        }}
                        projectId={project.id}></ProjectVariableHost>
                );
            default:
                return <DataLoader />;
        }
    };

    const getAvailableDataTableViewLinksVariable = () => {
        const questionnaires = project.dataForms.filter(
            (x) => x.formType === DataFormType.Questionnaire
        );
        const questions = questionnaires.flatMap((x) => x.questions);
        const tableViewIds = questions
            .map((x) => Number(x.tableViewId))
            .filter((x) => x !== 0);
        const result = {
            ...dataTableViewLinksVariable,
            options: dataTableViewLinksVariable.options.filter(
                (option) => !tableViewIds.includes(Number(option.name))
            ),
        };
        return result;
    };

    const handleEditViewClick = (tableId: number, viewId: number) => {
        setSelectedDataTableId(tableId);
        setSelectedTableViewId(viewId);
        setDataTableEditorMode(DataTableEditorModeEnum.EditView);
    };

    const handleCreateViewClick = (tableId: number) => {
        const updatedProject = {
            ...project,
            dataTables: project.dataTables.map((x) => {
                if (x.id === tableId) {
                    return { ...x, views: [...x.views, { ...emptyTableView }] };
                } else return x;
            }),
        };

        setProject(updatedProject);
        setSelectedDataTableId(tableId);
        setSelectedTableViewId(0);
        setDataTableEditorMode(DataTableEditorModeEnum.CreateView);
    };

    const changeTab = (index: number) => {
        if (currentTab !== EditorTypeEnum.Variable) {
            const isRowsValid = gridTableRef?.current.validateAllRows();
            setTabsHasErrorState({
                ...tabsHasErrorState,
                [currentTab]: !isRowsValid,
            });
        }

        if (
            currentTab === EditorTypeEnum.DataTable &&
            dataTableEditorMode !== DataTableEditorModeEnum.Table
        ) {
            closeDataTableViewEditor();
        }

        const tabNumber = index + 1;

        navigate(`/project-editor?tab=${tabNumber}`, {
            state: {
                project: project,
                mode: mode,
                type: currentTab,
            },
        });

        setSelectedDataTableId(0);
        setSelectedTableViewId(0);
        setDataTableEditorMode(DataTableEditorModeEnum.Table);
        setCurrentTab(index);
    };

    const getTabClasses = (index: number) => {
        let tabClasses = 'RRT__tab';

        if (
            !project.dataForms.some((x) => x.formType == index) &&
            EditorTypeEnum.Variable !== index &&
            EditorTypeEnum.DataTable !== index
        ) {
            return `${tabClasses} d-none`;
        }

        if (
            project.dataTables.length <= 0 &&
            EditorTypeEnum.DataTable === index
        ) {
            return `${tabClasses} d-none`;
        }

        if (currentTab == index) {
            return `${tabClasses} RRT__tab--first RRT__tab--selected`;
        }

        if (tabsHasErrorState && tabsHasErrorState[index]) {
            return `${tabClasses} error-tab`;
        }
        return tabClasses;
    };

    const renderTableTemplateViewNameInputForm = () => {
        if (project.dataTables.length) {
            const currentTable = getCurrentTemplateTable();
            const currentView =
                mode === EditorModeEnum.EditProject
                    ? getEmptyTableView()
                    : getCurrentTemplateTableView();

            return (
                <div>
                    {dataTableEditorMode ===
                        DataTableEditorModeEnum.CreateView &&
                    mode === EditorModeEnum.EditProject ? (
                        <div className="stage-navigation-breadcrumb">
                            <label
                                className="link-text"
                                onClick={() => closeDataTableViewEditor()}>
                                {'Table'}
                            </label>
                            <label className="node-separator">{' / '}</label>
                            <label
                                className="link-text"
                                onClick={() => closeDataTableViewEditor()}>
                                {currentTable.name}
                            </label>
                        </div>
                    ) : (
                        <></>
                    )}
                    <Form
                        innerRef={templateRef}
                        initialValues={{
                            viewName: currentView?.name,
                            viewDescription: currentView?.description,
                        }}
                        validationSchema={yup.object().shape({
                            viewName: yup.string().trim().required().max(100),
                            viewDescription: yup.string().trim(),
                        })}
                        onSubmit={(fields) => {
                            onSuccessfulSubmit(
                                fields.viewName,
                                fields.viewDescription
                            );
                        }}>
                        <Col>
                            <Row className="header-row">
                                <div>
                                    <Label>
                                        <strong>{'Data Table Name: '}</strong>
                                        {currentTable.name}
                                    </Label>
                                </div>

                                <div className="header-row-item">
                                    <Label>
                                        <strong>
                                            {mode === EditorModeEnum.EditProject
                                                ? 'Data Table View Name:'
                                                : 'View Template Name:'}
                                        </strong>
                                    </Label>
                                    <Field
                                        name="viewName"
                                        value={currentView?.name}
                                        onChange={(event: any) => {
                                            const state: DataTableView = {
                                                ...currentView,
                                                name: event.target.value,
                                            };
                                            updateTableView(
                                                currentTable.id,
                                                state
                                            );
                                        }}></Field>
                                </div>
                                <div className="header-row-item">
                                    <Label>
                                        <strong>{'Description: '}</strong>
                                    </Label>
                                    <Field
                                        name="viewDescription"
                                        value={currentView?.description}
                                        onChange={(event: any) => {
                                            const state: DataTableView = {
                                                ...currentView,
                                                description: event.target.value,
                                            };
                                            updateTableView(
                                                currentTable.id,
                                                state
                                            );
                                        }}></Field>
                                </div>
                            </Row>
                        </Col>
                    </Form>
                </div>
            );
        }
    };

    const renderDataFormTemplateNameInputForm = () => {
        if (project.dataForms.length || project.dataTables) {
            return (
                <Form
                    innerRef={templateRef}
                    initialValues={{
                        templateName:
                            currentTab === EditorTypeEnum.DataTable
                                ? getCurrentTemplateTable()
                                      ?.originalTemplateName
                                : getCurrentTemplateForm()
                                      ?.originalTemplateName,
                    }}
                    validationSchema={yup.object().shape({
                        templateName: yup.string().trim().required().max(100),
                    })}
                    onSubmit={(fields) => {
                        onSuccessfulSubmit(fields.templateName);
                    }}>
                    <Col>
                        <Row className="header-row">
                            {getTemplateNameField()}
                            {currentTab === EditorTypeEnum.DataTableView ? (
                                <></>
                            ) : (
                                <Button
                                    className="btn btn-primary"
                                    type="button"
                                    onClick={() => {
                                        gridTableRef?.current.addRow();
                                    }}>
                                    + Add New Row
                                </Button>
                            )}
                        </Row>
                    </Col>
                </Form>
            );
        }
    };

    const renderInputForm = () => {
        if (
            (currentTab === EditorTypeEnum.DataTable &&
                dataTableEditorMode === DataTableEditorModeEnum.CreateView) ||
            (mode === EditorModeEnum.EditTempalte &&
                dataTableEditorMode === DataTableEditorModeEnum.EditView)
        ) {
            return renderTableTemplateViewNameInputForm();
        }

        return renderDataFormTemplateNameInputForm();
    };

    const renderTemplateForm = () => {
        if (project.dataForms.length || project.dataTables.length) {
            return (
                <div className="editor-header">
                    {renderInputForm()}
                    {mode === EditorModeEnum.EditProject && (
                        <div className="project-settings-section">
                            <div
                                className="clickable"
                                onClick={() => {
                                    setIsTableSettingsPopupVisible(true);
                                }}>
                                <FontAwesomeIcon icon={faWrench} />
                                {'User View Settings'}
                            </div>
                            {project.state ===
                                DataRequestProjectState.Active && (
                                <DesignModeSwitcher
                                    formId={0}
                                    defaultValue={true}
                                    onDisable={() => {
                                        navigateToProjectViewMode();
                                    }}></DesignModeSwitcher>
                            )}
                        </div>
                    )}
                </div>
            );
        }
    };

    const navigateToProjectViewMode = () => {
        navigate(`/data-request-dashboard?tab=${currentTab + 1}`, {
            state: {
                projectId: project.id,
                isAdmin: true,
                projectName: project.name,
                practiceName: project.practiceName,
            },
        });
    };

    const navigateToProjectEditMode = () => {
        setSelectedDataTableId(null);
        setSelectedTableViewId(null);
        setDataTableEditorMode(DataTableEditorModeEnum.Table);
    };

    const fetchUpdatedProject = () => {
        axios
            .get(`api/DataRequestProjects/${project.id}`)
            .then((response) => {
                if (response?.status === 200) {
                    const newForms = response.data.dataForms as DataForm[];
                    let updatedDataForms = newForms.map((form) => {
                        const editableForm = project.dataForms.find(
                            (f) => f.id === form.id
                        );

                        return {
                            ...form,
                            questions: editableForm
                                ? editableForm.questions
                                : form.questions,
                            name: form.originalTemplateName,
                        };
                    });

                    const newTables = response.data.dataTables as DataTable[];
                    let updatedDataTables = newTables.map((table) => {
                        const editableTable = project.dataTables.find(
                            (f) => f.id === table.id
                        );

                        return {
                            ...table,
                            questions: editableTable
                                ? editableTable.columns
                                : table.columns,
                        };
                    });

                    const updatedProject: DataRequestProject = {
                        ...project,
                        dataForms: updatedDataForms,
                        dataTables: updatedDataTables,
                    };

                    setProject(updatedProject);
                    navigateToProjectEditMode();
                } else {
                    createNotification(
                        'Error occured while fetching project',
                        'error'
                    );
                }
            })
            .catch(() => {
                createNotification(
                    'Error occured while fetching project',
                    'error'
                );
            });
    };

    const isTemplateFormNameVisible = () => {
        const result =
            currentTab !== EditorTypeEnum.Variable &&
            !(
                currentTab === EditorTypeEnum.Questionnaire &&
                mode === EditorModeEnum.EditProject
            ) &&
            !(
                currentTab === EditorTypeEnum.DataTable &&
                mode === EditorModeEnum.EditProject &&
                dataTableEditorMode !== DataTableEditorModeEnum.CreateView
            );
        return result;
    };

    const isTemplateNameFormVisible =
        currentTab === EditorTypeEnum.Variable ||
        (mode === EditorModeEnum.EditProject &&
            currentTab === EditorTypeEnum.Questionnaire) ||
        (mode === EditorModeEnum.EditProject &&
            currentTab === EditorTypeEnum.DataTable) ||
        (mode === EditorModeEnum.EditProject &&
            currentTab === EditorTypeEnum.DataTableView);

    return (
        <div className="project-editor">
            <div className="title-content">
                <div className="page-title">
                    <h3>{getHeaderTitle()}</h3>
                </div>
                <Breadcrumb>
                    <BreadcrumbItem>
                        <HomeButton></HomeButton>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>
                        {getBreadcrumbItem()}
                    </BreadcrumbItem>
                </Breadcrumb>
            </div>
            <Card className="main-card">
                <CardBody className="card-body-custom">
                    {mode === EditorModeEnum.EditProject ? (
                        <>
                            <div className="RRT__container">
                                <div className="RRT__tabs body-tabs">
                                    {tabData?.map((item, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className={getTabClasses(index)}
                                                onClick={() => {
                                                    changeTab(index);
                                                }}
                                                id={'tab-info-' + index}>
                                                {item}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </>
                    ) : (
                        <></>
                    )}
                    {isTemplateFormNameVisible() ? renderTemplateForm() : []}
                    {renderEditor()}
                    <Col>
                        <Row className="buttons-wrapper">
                            <Button
                                type="button"
                                className="btn btn-cancel"
                                disabled={isLoading}
                                onClick={() => {
                                    if (mode === EditorModeEnum.EditProject) {
                                        if (
                                            dataTableEditorMode ===
                                                DataTableEditorModeEnum.CreateView &&
                                            mode === EditorModeEnum.EditProject
                                        ) {
                                            closeDataTableViewEditor();
                                        } else if (
                                            shouldNavigateToProjectView
                                        ) {
                                            navigateToProjectViewMode();
                                        } else {
                                            navigate(
                                                '/data-and-document-requests-dashboard'
                                            );
                                        }
                                    } else {
                                        navigate(
                                            `/data-and-document-templates-dashboard${additionalUrlToReturn}`
                                        );
                                    }
                                }}>
                                Cancel & Close
                            </Button>
                            <ButtonLoader
                                type={'button'}
                                buttonText={'Save & Close'}
                                loaderButtonText={''}
                                disabled={false}
                                isLoading={isLoading}
                                className="btn btn-primary"
                                onClick={() => {
                                    if (
                                        isTemplateNameFormVisible &&
                                        (dataTableEditorMode !==
                                            DataTableEditorModeEnum.CreateView ||
                                            mode !== EditorModeEnum.EditProject)
                                    ) {
                                        onSuccessfulSubmit('');
                                    } else {
                                        templateRef?.current.handleSubmit();
                                    }
                                }}
                            />
                        </Row>
                    </Col>
                </CardBody>
            </Card>
            {isProjectVariableEditPopupVisible && (
                <EditProjectVariablePopup
                    projectId={project?.id ?? 0}
                    options={projectVariableOptionsToEdit}
                    variableId={varialbeIdToEdit}
                    onSubmit={() => {
                        fetchProjectVariables(project?.id ?? 0);
                        setIsProjectVariableEditPopupVisible(false);
                    }}
                    variableName={variableNameToEdit}
                    close={() =>
                        setIsProjectVariableEditPopupVisible(false)
                    }></EditProjectVariablePopup>
            )}
            {isTableSettingsPopupVisible && (
                <TableSettingsPopup
                    isVisible={isTableSettingsPopupVisible}
                    setIsVisible={setIsTableSettingsPopupVisible}
                    onSubmit={() => {
                        fetchUpdatedProject();
                        fetchProjectVariables(project?.id ?? 0);
                    }}
                    projectId={project?.id ?? 0}
                    dataTables={project.dataTables}
                    dataForms={project.dataForms}></TableSettingsPopup>
            )}
        </div>
    );
};
