import DataFormQuestion from '../../models/DataRequestHub/DataFormQuestion';
import { GridStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity';
import {
    AnswerTypeVariable,
    AnswerTypeVariableNamesList,
    FormTypeVariable,
    FormTypeVariableNamesList,
    NumericFormatVariableNamesList,
} from '../../models/DataRequestHub/ProjectVariable';
import {
    DataTable,
    DataTableColumn,
    DataTableViewColumn,
} from '../../models/DataRequestHub/DataTable';
import { EditorTypeEnum } from '../../models/DataRequestHub/ProjectEditorEnum';
import { ConditionValue } from '../../models/DataRequestHub/ItemDisplayCondition';

export const validateRequiredCell = (
    element: Element,
    valueToValidate: any
) => {
    if (element) {
        const stringValue = valueToValidate?.toString()?.trim();
        if (!stringValue) {
            addErrorClassToCellContent(element);
            return true;
        } else {
            removeErrorClassFromCellContent(element);
            return false;
        }
    }
};

export const validateConditionValueCell = <T extends ConditionValue[]>(
    element: Element,
    classNameOfCell: string,
    conditionValues: T
) => {
    const displayNameCellElement = element.querySelector(classNameOfCell);
    if (displayNameCellElement) {
        const hasEmptyString = conditionValues?.some(
            (item) => !!!item?.data?.toString().trim().length
        );
        const isArrayEmpty = !conditionValues?.length;

        const hasError = hasEmptyString || isArrayEmpty;

        if (hasError) {
            addErrorClassToCellContent(displayNameCellElement);
            return true;
        } else {
            removeErrorClassFromCellContent(displayNameCellElement);
            return false;
        }
    }
};

export const isDateInvalid = (dateToValidate: any) => {
    const date = new Date(dateToValidate);
    return isNaN(date.getTime());
};

export const validateDateAndAdjustClasses = (
    element: Element,
    dateToValidate: any
) => {
    const date = new Date(dateToValidate);
    const result = !isNaN(date.getTime());
    if (!result) {
        addErrorClassToCellContent(element);
        return true;
    } else {
        removeErrorClassFromCellContent(element);
        return false;
    }
};

const addErrorClassToCellContent = (cellElement: Element) => {
    setTimeout(() => {
        const cellContent = cellElement?.querySelector(
            '.MuiDataGrid-cellContent'
        );
        if (cellContent) {
            cellContent.classList.add('Mui-error');
        }
    }, 100);
};

export const removeErrorClassFromCellContent = (cellElement: Element) => {
    setTimeout(() => {
        const cellContent = cellElement?.querySelector(
            '.MuiDataGrid-cellContent'
        );
        if (cellContent) {
            cellContent.classList.remove('Mui-error');
        }
    }, 100);
};

export const validateRowDisplayOrder = <T extends DisplayOrderType>(
    rows: T[]
): boolean => {
    const displayOrderMap = new Map<number, number>();

    rows.forEach((row) => {
        const displayOrder = row.displayOrder;
        if (displayOrderMap.has(displayOrder)) {
            displayOrderMap.set(
                displayOrder,
                displayOrderMap.get(displayOrder)! + 1
            );
        } else {
            displayOrderMap.set(displayOrder, 1);
        }
    });

    return rows.some(
        (row) =>
            !row.displayOrder ||
            row.displayOrder < 1 ||
            displayOrderMap.get(row.displayOrder)! > 1
    );
};

export const validateRowUniqueName = <T extends DisplayNameType>(
    rows: T[]
): boolean => {
    const displayNameMap = new Map<string, number>();

    rows.forEach((row) => {
        const displayName = row.displayName.toLocaleLowerCase().trim();
        displayNameMap.set(
            displayName,
            (displayNameMap.get(displayName) || 0) + 1
        );
    });

    return rows.some((row) => {
        const displayName = row.displayName.toLocaleLowerCase().trim();
        return !displayName || displayNameMap.get(displayName)! > 1;
    });
};

export const validateSubQuestionsFormDisplayOrder = (
    subQuestions: DataFormQuestion[]
) => {
    const groupedSubQuestions = subQuestions.reduce(
        (mapResult, question): Map<string, DataFormQuestion[]> => {
            const key = question.parentQuestionId;
            if (!mapResult.has(key)) {
                mapResult.set(key, []);
            }
            mapResult.get(key).push(question);
            return mapResult;
        },
        new Map<string, DataFormQuestion[]>()
    );

    const hasError = Array.from(groupedSubQuestions).some(
        ([_, subQuestionsGroup]) =>
            hasSubQuestionsGroupDisplayOrderError(subQuestionsGroup)
    );

    return hasError;
};

const hasSubQuestionsGroupDisplayOrderError = (
    subQuestionsGroup: DataFormQuestion[]
) => {
    const displayOrderMap = new Map<number, [DataFormQuestion]>();
    for (const row of subQuestionsGroup) {
        const displayOrderSub = row.displayOrderSub;
        if (!displayOrderMap.has(displayOrderSub)) {
            displayOrderMap.set(displayOrderSub, [row]);
        } else {
            displayOrderMap.get(displayOrderSub).push(row);
        }
    }

    const invalidOrderCells = Array.from(displayOrderMap.values()).filter(
        (rows) =>
            rows.length > 1 ||
            !rows[0]?.displayOrderSub ||
            rows[0]?.displayOrderSub < 1
    );

    return invalidOrderCells.length > 0 ? true : false;
};

export const validateQuestionsFormDisplayTextSize = (
    rows: DataFormQuestion[]
) => {
    const invalidRows = rows.filter(
        (rows) =>
            rows.answerType === getAnswerTypeName(AnswerTypeVariable.Text) &&
            (!rows.displayTextSize || !rows.maxTextSize)
    ) as DataFormQuestion[];

    return invalidRows.length > 0 ? true : false;
};

export const validateRequiredFormType = <T extends FormTypeType>(rows: T[]) => {
    const invalidRows = rows.filter(
        (rows) =>
            rows.answerType !== getAnswerTypeName(AnswerTypeVariable.Text) &&
            rows.answerType !== getAnswerTypeName(AnswerTypeVariable.Date) &&
            !rows.formType
    );

    return invalidRows.length > 0 ? true : false;
};

export const validateRequiredFormList = <T extends FormListType>(rows: T[]) => {
    const invalidRows = rows.filter(
        (rows) =>
            rows.formType === getFormTypeName(FormTypeVariable.DropDown) &&
            !rows.formList
    );

    return invalidRows.length > 0 ? true : false;
};

const addDisableClassToCellContent = (cellElement: Element) => {
    setTimeout(() => {
        if (cellElement) {
            cellElement.classList.add('disabled');
        }
        removeInnerHtml(cellElement);
    }, 100);
};

const removeInnerHtml = (cellElement: Element) => {
    const cellContent = cellElement?.querySelector('.MuiDataGrid-cellContent');
    if (cellContent) {
        cellContent.innerHTML = '';
    }
};

const removeDisableClassFromCellContent = (cellElement: Element) => {
    setTimeout(() => {
        if (cellElement) {
            cellElement.classList.remove('disabled');
        }
    }, 100);
};
function getRowId(
    row: DataFormQuestion,
    editorType: EditorTypeEnum
): string | number;
function getRowId(
    row: DataTableColumn,
    editorType: EditorTypeEnum
): string | number;
function getRowId(
    row: DisplayOrderType,
    editorType: EditorTypeEnum
): string | number;
function getRowId(
    row: FormTypeType,
    editorType: EditorTypeEnum
): string | number;
function getRowId(
    row: FormListType,
    editorType: EditorTypeEnum
): string | number;
function getRowId(
    row: DisplayNameType,
    editorType: EditorTypeEnum
): string | number;

function getRowId(row: any, editorType: EditorTypeEnum): string | number {
    if (
        editorType === EditorTypeEnum.Questionnaire &&
        'customQuestionId' in row
    ) {
        return row.customQuestionId;
    }

    if (editorType === EditorTypeEnum.DataTable && 'customColumnId' in row) {
        return row.customColumnId;
    }

    if (
        editorType === EditorTypeEnum.DataTableView &&
        'customColumnId' in row
    ) {
        return row.customColumnId;
    }

    if ('gridId' in row && row.gridId !== undefined) {
        return row.gridId;
    }

    return row.id;
}

export const removeTextSizeIfAnswerTypeIsNotText = (
    templateRows: DataFormQuestion[]
) => {
    const rowsWithoutTextAnswerType = templateRows.filter(
        (rows) => rows.answerType !== 'Text'
    ) as DataFormQuestion[];

    const updatedTemplateRows = templateRows.map((x) => {
        const id = getRowId(x, EditorTypeEnum.Questionnaire);

        if (
            rowsWithoutTextAnswerType.some(
                (y) => getRowId(y, EditorTypeEnum.Questionnaire) === id
            )
        ) {
            return { ...x, displayTextSize: '', maxTextSize: '' };
        }

        return x;
    }) as DataFormQuestion[];

    return updatedTemplateRows;
};

const getAnswerTypeName = (variable: AnswerTypeVariable) => {
    return AnswerTypeVariableNamesList.find((x) => x.id === variable).name;
};

const isNumericFormType = (formType: string) => {
    return NumericFormatVariableNamesList.some((x) => x.name === formType);
};

const isFormType = (formType: string) => {
    return FormTypeVariableNamesList.some((x) => x.name === formType);
};

const getFormTypeName = (variable: FormTypeVariable) => {
    return FormTypeVariableNamesList.find((x) => x.id === variable).name;
};

export const removeFormTypeIfNeeded = <T extends FormTypeType>(
    templateRows: T[],
    type: EditorTypeEnum
) => {
    const rowsToRemoveFormType = templateRows.filter(
        (rows) =>
            rows.answerType === getAnswerTypeName(AnswerTypeVariable.Text) ||
            rows.answerType === getAnswerTypeName(AnswerTypeVariable.Date) ||
            (rows.answerType ===
                getAnswerTypeName(AnswerTypeVariable.PickFromList) &&
                !isFormType(rows.formType)) ||
            (rows.answerType === getAnswerTypeName(AnswerTypeVariable.Number) &&
                !isNumericFormType(rows.formType))
    );

    const updatedTemplateRows = templateRows.map((x) => {
        const id = getRowId(x, type);        
        if (rowsToRemoveFormType.some((y) => getRowId(y, type) === id)) {
            const element = document.querySelector('[data-id="' + id + '"]');
            const cellElement = element?.querySelector('.form-type');
            removeInnerHtml(cellElement);
            return { ...x, formType: '' };
        }

        return x;
    });    

    return updatedTemplateRows;
};

export const removeFormListIfNeeded = <T extends FormListType>(
    templateRows: T[],
    type: EditorTypeEnum
) => {
    const rowsToRemoveFormListIds = new Set(
        templateRows
            .filter(
                (row) =>
                    row.formType !== getFormTypeName(FormTypeVariable.DropDown)
            )
            .map((row) => getRowId(row, type))
    );

    return templateRows.map((row) => {
        const id = getRowId(row, type);

        if (rowsToRemoveFormListIds.has(id)) {
            const element = document.querySelector(`[data-id="${id}"]`);
            const cellElement = element?.querySelector('.form-list');
            removeInnerHtml(cellElement);

            return { ...row, formList: '' };
        }

        return row;
    });
};

export const validateFormType = <T extends FormTypeType>(
    templateRows: T[],
    gridState: GridStateCommunity,
    editorType: EditorTypeEnum
) => {
    const invalidRowIds = new Set<string>();
    const validRowIds = new Set<string>();
    const rowsToDisable = [] as string[];
    const rowsToEnable = [] as string[];

    templateRows.forEach((row) => {
        const id = getRowId(row, editorType);
        const isTextOrDateAnswer =
            row.answerType === getAnswerTypeName(AnswerTypeVariable.Text) ||
            row.answerType === getAnswerTypeName(AnswerTypeVariable.Date);

        if (!row.answerType) {
            rowsToDisable.push(id.toString());
        } else if (isTextOrDateAnswer) {
            rowsToDisable.push(id.toString());
        } else {
            rowsToEnable.push(id.toString());

            if (!row.formType) {
                invalidRowIds.add(id.toString());
            } else {
                validRowIds.add(id.toString());
            }
        }
    });

    rowsToDisable.forEach((id) => {
        const element = document.querySelector(`[data-id="${id}"]`);
        const cellElement = element?.querySelector('.form-type');
        addDisableClassToCellContent(cellElement);
        removeErrorClassFromCellContent(cellElement);
    });

    rowsToEnable.forEach((id) => {
        const element = document.querySelector(`[data-id="${id}"]`);
        const cellElement = element?.querySelector('.form-type');
        removeDisableClassFromCellContent(cellElement);
    });

    invalidRowIds.forEach((id) => {
        const element = document.querySelector(`[data-id="${id}"]`);
        const cellElement = element?.querySelector('.form-type');
        addErrorClassToCellContent(cellElement);
    });
    
    validRowIds.forEach((id) => {
        const element = document.querySelector(`[data-id="${id}"]`);
        const cellElement = element?.querySelector('.form-type');
        removeErrorClassFromCellContent(cellElement);
    });

    const isEditing = Object.keys(gridState.editRows).length > 0;
    if (isEditing) {
        return false;
    }

    return invalidRowIds.size === 0;
};

export const validateFormList = <T extends FormListType>(
    templateRows: T[],
    gridState: GridStateCommunity,
    editorType: EditorTypeEnum
): boolean => {
    const rowsToDisable = new Set(
        templateRows
            .filter(
                (row) =>
                    row.formType !== getFormTypeName(FormTypeVariable.DropDown)
            )
            .map((row) => getRowId(row, editorType))
    );

    const rowsToEnable = new Set(
        templateRows
            .filter(
                (row) =>
                    row.formType === getFormTypeName(FormTypeVariable.DropDown)
            )
            .map((row) => getRowId(row, editorType))
    );

    const invalidRows = new Set(
        templateRows
            .filter(
                (row) =>
                    row.formType ===
                        getFormTypeName(FormTypeVariable.DropDown) &&
                    !row.formList
            )
            .map((row) => getRowId(row, editorType))
    );

    templateRows.forEach((row) => {
        const id = getRowId(row, editorType);
        const element = document.querySelector(`[data-id="${id}"]`);
        const cellElement = element?.querySelector('.form-list');

        if (!cellElement) return;

        if (rowsToDisable.has(id)) {
            addDisableClassToCellContent(cellElement);
        } else if (rowsToEnable.has(id)) {
            removeDisableClassFromCellContent(cellElement);
        }

        if (invalidRows.has(id)) {
            addErrorClassToCellContent(cellElement);
        } else {
            removeErrorClassFromCellContent(cellElement);
        }
    });
    const editRows = gridState.editRows;
    const isEditing = Object.keys(editRows).length > 0;

    if (isEditing) {
        return false;
    }
    return invalidRows.size === 0;
};

export const validateDisplayTextSize = (
    templateRows: DataFormQuestion[],
    gridState: GridStateCommunity
) => {
    const rowsToDisableTextSize = templateRows.filter(
        (rows) => rows.answerType !== getAnswerTypeName(AnswerTypeVariable.Text)
    ) as DataFormQuestion[];
    rowsToDisableTextSize.forEach((x) => {
        const id = getRowId(x, EditorTypeEnum.Questionnaire);

        const element = document.querySelector('[data-id="' + id + '"]');
        const cellElement = element?.querySelector('.display-text-size');
        addDisableClassToCellContent(cellElement);
    });

    const rowsToEnableTextSize = templateRows.filter(
        (rows) => rows.answerType === getAnswerTypeName(AnswerTypeVariable.Text)
    ) as DataFormQuestion[];

    rowsToEnableTextSize.forEach((x) => {
        const id = getRowId(x, EditorTypeEnum.Questionnaire);

        const element = document.querySelector('[data-id="' + id + '"]');
        const cellElement = element?.querySelector('.display-text-size');
        removeDisableClassFromCellContent(cellElement);
    });

    const invalidRows = templateRows.filter(
        (rows) =>
            rows.answerType === getAnswerTypeName(AnswerTypeVariable.Text) &&
            (!rows.displayTextSize || !rows.maxTextSize)
    ) as DataFormQuestion[];

    invalidRows.forEach((x) => {
        const id = getRowId(x, EditorTypeEnum.Questionnaire);

        const element = document.querySelector('[data-id="' + id + '"]');
        const cellElement = element?.querySelector('.display-text-size');
        addErrorClassToCellContent(cellElement);
    });

    const validRows = templateRows.filter((x) => {
        const result = !invalidRows.some((y) => {
            const invalidRowId = getRowId(y, EditorTypeEnum.Questionnaire);
            const originalRowId = getRowId(x, EditorTypeEnum.Questionnaire);
            return invalidRowId === originalRowId;
        });

        return result;
    });

    validRows.forEach((x) => {
        const id = getRowId(x, EditorTypeEnum.Questionnaire);

        const element = document.querySelector('[data-id="' + id + '"]');
        const cellElement = element?.querySelector('.display-text-size');
        removeErrorClassFromCellContent(cellElement);
    });

    const editRows = gridState.editRows;
    const isEditing = Object.keys(editRows).length > 0;
    if (isEditing) {
        return false;
    }

    return invalidRows.length === 0;
};

interface DisplayOrderType {
    id: number;
    displayOrder: number;
}

interface FormTypeType {
    id: number;
    answerType: string;
    formType: string;
}

interface FormListType {
    id: number;
    answerType: string;
    formType: string;
    formList: string;
}

interface DisplayNameType {
    id: number;
    displayName: string;
}

export const validateGridEditorRowDisplayOrder = <T extends DisplayOrderType>(
    templateRows: T[],
    gridState: GridStateCommunity,
    editorType: EditorTypeEnum,
    isSubQuestionCheck?: (row: T) => boolean
): boolean => {
    if (!gridState) return false;

    const displayOrderMap = new Map<number, T[]>();

    templateRows.forEach((row) => {
        if (isSubQuestionCheck && isSubQuestionCheck(row)) return;

        const displayOrder = row.displayOrder;
        if (!displayOrderMap.has(displayOrder)) {
            displayOrderMap.set(displayOrder, [row]);
        } else {
            displayOrderMap.get(displayOrder)!.push(row);
        }
    });

    const invalidRows = new Set<T>();
    displayOrderMap.forEach((rows) => {
        if (
            rows.length > 1 ||
            !rows[0]?.displayOrder ||
            rows[0].displayOrder < 1
        ) {
            rows.forEach((row) => invalidRows.add(row));
        }
    });

    templateRows.forEach((row) => {
        const id = getRowId(row, editorType);
        const element = document.querySelector(`[data-id="${id}"]`);
        const cellElement = element?.querySelector('.display-order');

        if (!cellElement) return;

        if (invalidRows.has(row)) {
            addErrorClassToCellContent(cellElement);
        } else {
            removeErrorClassFromCellContent(cellElement);
        }
    });
    const isEditing = Object.keys(gridState.editRows).length > 0;
    if (isEditing) return false;
    return invalidRows.size === 0;
};

export const validateUniqueItemName = <T extends DisplayNameType>(
    templateRows: T[],
    gridState: GridStateCommunity,
    editorType: EditorTypeEnum
): boolean => {
    if (!gridState) {
        return false;
    }
    const displayOrderMap = new Map<string, T[]>();

    for (const row of templateRows) {
        const displayName = row.displayName?.toLocaleLowerCase().trim();
        if (!displayOrderMap.has(displayName)) {
            displayOrderMap.set(displayName, [row]);
        } else {
            displayOrderMap.get(displayName).push(row);
        }
    }

    const invalidOrderCells = Array.from(displayOrderMap.values()).filter(
        (rows) => rows.length > 1 || !rows[0]?.displayName
    );

    const rowsWithInvalidOrder = invalidOrderCells.flat() as T[];

    rowsWithInvalidOrder.forEach((x) => {
        const id = getRowId(x, editorType);
        const element = document.querySelector('[data-id="' + id + '"]');
        const cellElement = element?.querySelector('.display-name');
        addErrorClassToCellContent(cellElement);
    });

    const rowsWithValidOrder = templateRows.filter((x) => {
        const result = !rowsWithInvalidOrder.some((y) => {
            const invalidRowId = getRowId(y, editorType);
            const originalRowId = getRowId(x, editorType);
            return invalidRowId === originalRowId;
        });

        return result;
    });

    rowsWithValidOrder.forEach((x) => {
        const id = getRowId(x, editorType);

        const element = document.querySelector('[data-id="' + id + '"]');
        const cellElement = element?.querySelector('.display-name');
        removeErrorClassFromCellContent(cellElement);
    });

    const editRows = gridState.editRows;
    const isEditing = Object.keys(editRows).length > 0;
    if (isEditing) {
        return false;
    }

    return rowsWithInvalidOrder.length === 0;
};

export const validateSubQuestionDisplayOrder = (
    templateRows: DataFormQuestion[],
    gridState: GridStateCommunity
) => {
    if (!gridState) {
        return false;
    }

    let isValid = true;
    const subQuestions = templateRows.filter(
        (question) => question.isSubQuestion
    );

    const groupedSubQuestions = subQuestions.reduce(
        (mapResult, question): Map<string, DataFormQuestion[]> => {
            const key = question.parentQuestionId;
            if (!mapResult.has(key)) {
                mapResult.set(key, []);
            }
            mapResult.get(key).push(question);
            return mapResult;
        },
        new Map<string, DataFormQuestion[]>()
    );

    groupedSubQuestions.forEach((subQuestionsGroup) => {
        if (!validateSubQuestionGroup(subQuestionsGroup)) {
            isValid = false;
        }
    });

    const editRows = gridState.editRows;
    const isEditing = Object.keys(editRows).length > 0;
    if (isEditing) {
        return false;
    }

    return isValid;
};

const validateSubQuestionGroup = (
    subQuestions: DataFormQuestion[]
): boolean => {
    const displayOrderMap = new Map<number, [DataFormQuestion]>();
    for (const row of subQuestions) {
        const displayOrderSub = row.displayOrderSub;
        if (!displayOrderMap.has(displayOrderSub)) {
            displayOrderMap.set(displayOrderSub, [row]);
        } else {
            displayOrderMap.get(displayOrderSub).push(row);
        }
    }

    const invalidOrderCells = Array.from(displayOrderMap.values()).filter(
        (rows) =>
            rows.length > 1 ||
            !rows[0]?.displayOrderSub ||
            rows[0]?.displayOrderSub < 1
    );

    const rowsWithInvallidOrder =
        invalidOrderCells.flat() as DataFormQuestion[];

    rowsWithInvallidOrder.forEach((x) => {
        const id = getRowId(x, EditorTypeEnum.Questionnaire);

        const element = document.querySelector('[data-id="' + id + '"]');
        const cellElement = element?.querySelector('.display-order-sub');
        addErrorClassToCellContent(cellElement);
    });

    const rowsWithValidOrder = subQuestions.filter((x) => {
        const result = !rowsWithInvallidOrder.some((y) => {
            const invalidRowId = getRowId(y, EditorTypeEnum.Questionnaire);
            const originalRowId = getRowId(x, EditorTypeEnum.Questionnaire);
            return invalidRowId === originalRowId;
        });

        return result;
    });

    rowsWithValidOrder.forEach((x) => {
        const id = getRowId(x, EditorTypeEnum.Questionnaire);

        const element = document.querySelector('[data-id="' + id + '"]');
        const cellElement = element?.querySelector('.display-order-sub');
        removeErrorClassFromCellContent(cellElement);
    });

    return rowsWithInvallidOrder.length === 0;
};

export const validateDataTableViews = (
    dataTables: DataTable[],
    setInvalidTableViewIds?: (invalidViewIds: number[]) => void
): boolean => {
    const viewsValidaitionResult = dataTables.flatMap((table) =>
        table.views.map((view) => ({
            tableId: table.id,
            viewId: view.id,
            areRowsValid: validateDataTableViewsColumns(view.viewColumns),
        }))
    );

    if (setInvalidTableViewIds) {
        const invalidTableViewIds = viewsValidaitionResult
            .filter((result) => !result.areRowsValid)
            .map((result) => result.viewId);
        setInvalidTableViewIds(invalidTableViewIds);
    }

    const areTableViewsValid = !viewsValidaitionResult.some(
        (s) => !s.areRowsValid
    );

    return areTableViewsValid;
};

export const validateDataTableViewsColumns = (
    view: DataTableViewColumn[]
): boolean => {
    const includedColumns = view.filter((column) => column.isIncluded);
    const haveOverridenGroupOrderError = includedColumns.some(
        (column) => !column.overridenDisplayOrder
    );

    if (haveOverridenGroupOrderError) {
        return false;
    }

    const haveColumnError = validateDataTableViewColumns(view);
    if (!haveColumnError) {
        return false;
    }

    const mappedColumns = includedColumns.map((column) => ({
        ...column,
        displayOrder: column.overridenDisplayOrder,
    }));
    if (validateRowDisplayOrder(mappedColumns)) {
        return false;
    }

    return true;
};

export const validateProjectDataTables = (
    dataTables: DataTable[],
    setInvalidTableIds?: (invalidViewIds: number[]) => void
): boolean => {
    const tablesValidationResult = dataTables.map((table) => ({
        tableId: table.id,
        areRowsValid: validateDataTableColumns(table.columns),
        isNameValid: !!table.originalTemplateName,
    }));

    if (setInvalidTableIds) {
        const invalidTableIds = tablesValidationResult
            .filter((result) => !result.areRowsValid || !result.isNameValid)
            .map((result) => result.tableId);
        setInvalidTableIds(invalidTableIds);
    }

    const areDataTablesValid = !tablesValidationResult.some(
        (s) => !s.areRowsValid || !s.isNameValid
    );

    return areDataTablesValid;
};

const validateDataTableColumns = (columns: DataTableColumn[]) => {
    const haveColumnsError = columns.some((column) =>
        doesColumnMissRequiredFields(column)
    );

    if (haveColumnsError) {
        return false;
    }

    if (validateRowDisplayOrder(columns)) {
        return false;
    }

    const mappedColumns = columns.map((m) => ({ ...m, displayName: m.name }));
    if (validateRowUniqueName(mappedColumns)) {
        return false;
    }

    if (validateRequiredFormType(mappedColumns)) {
        return false;
    }

    if (validateRequiredFormList(mappedColumns)) {
        return false;
    }

    return true;
};

const doesColumnMissRequiredFields = (
    tableColumn: DataTableColumn
): boolean => {
    const areBasicFieldsMisssing =
        !tableColumn.displayOrder ||
        !tableColumn.answerType ||
        !tableColumn.name;
    const isConditionalColumnComplete =
        !tableColumn.isDisplayLogicEnabled ||
        (tableColumn.logicParentCustomId &&
            tableColumn.conditionValues.length &&
            tableColumn.conditionValues !== null &&
            tableColumn.conditionValues !== undefined);
    return areBasicFieldsMisssing || !isConditionalColumnComplete;
};

const validateDataTableViewColumns = (columns: DataTableViewColumn[]) => {
    const haveColumnsError = columns.some((column) =>
        doesColumnViewMissRequiredFields(column)
    );

    if (haveColumnsError) {
        return false;
    }

    return true;
};

const doesColumnViewMissRequiredFields = (
    tableColumn: DataTableViewColumn
): boolean => {
    if (!tableColumn.isIncluded) {
        return false;
    }

    return (
        tableColumn.isFilterEnabled &&
        (tableColumn.filterData === null ||
            tableColumn.filterData === undefined ||
            tableColumn.filterData.length < 1)
    );
};
