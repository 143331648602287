import { createAsyncThunk } from '@reduxjs/toolkit';
import { DataRequestProject } from '../../DataRequestProject';
import {
    mapFormsAdminNotifications,
    mapFormsUserNotifications,
    mapQuestionsStatus,
} from '../data-forms-status-mapper';
import {
    mapCellsSeenStatus,
    mapTablesNotifications,
} from '../data-tables-status-mapper';
import { DataRequestNotificationsState } from '../data-request-notifications.state';
import {
    mapProjectTabsStatuses,
    mapModuleTabsAdminStatuses,
    mapModuleTabsUserStatuses,
} from '../project-tabs-status-mapper';

export interface InitializeDataRequestNotificationsArguments {
    projects: DataRequestProject[];
    isAdminView: boolean;
}

export const initializeDataRequestNotifications = createAsyncThunk(
    'dataRequestNotifications/initialize',
    async (
        args: InitializeDataRequestNotificationsArguments,
        dispatch: any
    ) => {
        const notificationsState =
            dispatch.getState().dataRequestsNotifications;
        const result = await initialize(
            args.projects,
            args.isAdminView,
            notificationsState
        );

        return result;
    }
);

const initialize = (
    projects: DataRequestProject[],
    isAdminView: boolean,
    state: DataRequestNotificationsState
) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            const initializedState = { ...state };
            const quesiotnsStatus = mapQuestionsStatus(projects);
            const formsNotifications = isAdminView
                ? mapFormsAdminNotifications(quesiotnsStatus)
                : mapFormsUserNotifications(quesiotnsStatus);

            const cellsSeenStatus = mapCellsSeenStatus(projects);

            const tablesNotifications = mapTablesNotifications(
                cellsSeenStatus,
                projects,
                isAdminView
            );

            const updateFormTabsStatus = mapProjectTabsStatuses(
                formsNotifications,
                tablesNotifications,
                isAdminView
            );

            const updateModuleTabsStatus = isAdminView
                ? mapModuleTabsAdminStatuses(quesiotnsStatus)
                : mapModuleTabsUserStatuses(quesiotnsStatus);

            initializedState.isAdminView = isAdminView;
            initializedState.questionsSeenStatus = quesiotnsStatus;
            initializedState.formsNotificationsStatus = formsNotifications;
            initializedState.projectTabsNotificationsStatus =
                updateFormTabsStatus;
            initializedState.moduleTabsNotificationsStatus =
                updateModuleTabsStatus;
            initializedState.tableCellsSeenStatus = cellsSeenStatus;
            initializedState.tablesNotificationsStatus = tablesNotifications;
            resolve(initializedState);
        });
    });
};
