import { TimelineParty } from './TimelineParty';

export enum TimelineActionType {
    SellerDataAndDocumentRequest,
    SellerEngagementDocument,
    SellerCustomTeaserApproval,
    SellerCustomProcessDuration,
    SellerCustomSelectStage2NdaTerms,
    SellerCustomSelectStage3NdaTerms,
    SellerCustomMeetingAvailability,
    SellerBuyerSelectionStage1Nda,
    SellerBuyerSelectionStage2Nda,
    SellerBuyerSelectionDetailedFinancialModel,
    SellerBuyerSelectionMeetings,
    SellerBuyerSelectionStage3Nda,
    BuyerDocumentExecution,
    BuyerExpressionOfInterest,
    BuyerExpressionOfInterestWithRange,
    BuyerReviewDocument,
    BuyerUploadDocument,
    BuyerScheduleMeeting,
    BuyerLoiInformation,
}

export enum BuyerActionTypeGroups {
    DocumentExecution,
    ExpresstionOfInterest,
    ReviewDocument,
    UploadDocument,
    Custom,
}

export enum SellerActionTypeGroups {
    DataAndDocumentRequest,
    EngagementDocument,
    BuyerSelection,
    Custom,
}

export interface ActionTypeDefinition {
    id: TimelineActionType;
    party: TimelineParty;
    group: BuyerActionTypeGroups | SellerActionTypeGroups;
    name: string;
}

export const MarketplaceEventActionTypeList: ActionTypeDefinition[] = [
    {
        id: TimelineActionType.SellerDataAndDocumentRequest,
        party: TimelineParty.Seller,
        group: SellerActionTypeGroups.DataAndDocumentRequest,
        name: 'Data & Docs Request',
    },
    {
        id: TimelineActionType.SellerEngagementDocument,
        party: TimelineParty.Seller,
        group: SellerActionTypeGroups.EngagementDocument,

        name: 'Engagement Document',
    },
    {
        id: TimelineActionType.SellerCustomTeaserApproval,
        party: TimelineParty.Seller,
        group: SellerActionTypeGroups.Custom,

        name: 'Teaser Approval',
    },
    {
        id: TimelineActionType.SellerCustomProcessDuration,
        party: TimelineParty.Seller,
        group: SellerActionTypeGroups.Custom,
        name: 'Process Duration',
    },
    {
        id: TimelineActionType.SellerCustomSelectStage2NdaTerms,
        party: TimelineParty.Seller,
        group: SellerActionTypeGroups.Custom,
        name: 'Select Stage 2 NDA Terms',
    },
    {
        id: TimelineActionType.SellerCustomSelectStage3NdaTerms,
        party: TimelineParty.Seller,
        group: SellerActionTypeGroups.Custom,
        name: 'Select Stage 3 NDA Terms',
    },
    {
        id: TimelineActionType.SellerCustomMeetingAvailability,
        party: TimelineParty.Seller,
        group: SellerActionTypeGroups.Custom,
        name: 'Metting Availability',
    },
    {
        id: TimelineActionType.SellerBuyerSelectionStage1Nda,
        party: TimelineParty.Seller,
        group: SellerActionTypeGroups.BuyerSelection,
        name: 'Buyer Selection Stage 1 NDA',
    },
    {
        id: TimelineActionType.SellerBuyerSelectionStage2Nda,
        party: TimelineParty.Seller,
        group: SellerActionTypeGroups.BuyerSelection,
        name: 'Buyer Selection Stage 2 NDA',
    },
    {
        id: TimelineActionType.SellerBuyerSelectionDetailedFinancialModel,
        party: TimelineParty.Seller,
        group: SellerActionTypeGroups.BuyerSelection,
        name: 'Buyer Selection Detailed Financial Model',
    },
    {
        id: TimelineActionType.SellerBuyerSelectionMeetings,
        party: TimelineParty.Seller,
        group: SellerActionTypeGroups.BuyerSelection,
        name: 'Buyer Selection Meetings',
    },
    {
        id: TimelineActionType.SellerBuyerSelectionStage3Nda,
        party: TimelineParty.Seller,
        group: SellerActionTypeGroups.BuyerSelection,
        name: 'Buyer Selection Stage 3 NDA',
    },
    {
        id: TimelineActionType.BuyerDocumentExecution,
        party: TimelineParty.Buyer,
        group: BuyerActionTypeGroups.DocumentExecution,
        name: 'Document Execution',
    },
    {
        id: TimelineActionType.BuyerExpressionOfInterest,
        party: TimelineParty.Buyer,
        group: BuyerActionTypeGroups.ExpresstionOfInterest,
        name: 'Expression of Interest',
    },
    {
        id: TimelineActionType.BuyerExpressionOfInterestWithRange,
        party: TimelineParty.Buyer,
        group: BuyerActionTypeGroups.ExpresstionOfInterest,
        name: 'Expression of Interest with Range',
    },
    {
        id: TimelineActionType.BuyerReviewDocument,
        party: TimelineParty.Buyer,
        group: BuyerActionTypeGroups.ReviewDocument,
        name: 'Review Document',
    },
    {
        id: TimelineActionType.BuyerUploadDocument,
        party: TimelineParty.Buyer,
        group: BuyerActionTypeGroups.UploadDocument,
        name: 'Upload Document',
    },
    {
        id: TimelineActionType.BuyerScheduleMeeting,
        party: TimelineParty.Buyer,
        group: BuyerActionTypeGroups.Custom,
        name: 'Schedule Meeting',
    },
    {
        id: TimelineActionType.BuyerLoiInformation,
        party: TimelineParty.Buyer,
        group: BuyerActionTypeGroups.Custom,
        name: 'LOI Information',
    },
];

export const BuyerLinkActionTypeOptions = [
    {
        id: BuyerActionTypeGroups.DocumentExecution,
        party: TimelineParty.Buyer,
        name: 'Document Execution',
    },
    {
        id: BuyerActionTypeGroups.ExpresstionOfInterest,
        party: TimelineParty.Buyer,
        name: 'Expresion of Interest',
    },
    {
        id: BuyerActionTypeGroups.ReviewDocument,
        party: TimelineParty.Buyer,
        name: 'Review Document',
    },
    {
        id: BuyerActionTypeGroups.UploadDocument,
        party: TimelineParty.Buyer,
        name: 'Upload Information/Document',
    },
    {
        id: BuyerActionTypeGroups.Custom,
        party: TimelineParty.Buyer,
        name: 'Custom',
    },
];

export const SellerLinkActionTypeOptions = [
    {
        id: SellerActionTypeGroups.DataAndDocumentRequest,
        party: TimelineParty.Seller,
        name: 'Data & Docs Request',
    },
    {
        id: SellerActionTypeGroups.EngagementDocument,
        party: TimelineParty.Seller,
        name: 'Engagement Document',
    },
    {
        id: SellerActionTypeGroups.BuyerSelection,
        party: TimelineParty.Seller,
        name: 'Buyer Selection',
    },
    {
        id: SellerActionTypeGroups.Custom,
        party: TimelineParty.Seller,
        name: 'Custom',
    },
];

export const BuyerExpresionOfInterestOptionsList = [
    {
        label: 'No need to specify range',
        value: TimelineActionType.BuyerExpressionOfInterest,
    },
    {
        label: 'Indicate value range',
        value: TimelineActionType.BuyerExpressionOfInterestWithRange,
    },
];
