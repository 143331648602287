import GanttChartDateHelper from '../helpers/GanttChartDateHelper';

const HORIZON_BUFFER = 1000;
const HORIZON_BUFFER_ALERT = 750;

export default class GanttChartDataController {
    private lower_limit: number;
    private upper_limit: number;
    private lower_data_limit: number;
    private upper_data_limit: number;
    private nowposition: number;
    private daywidth: number;

    constructor() {
        this.lower_limit = 0;
        this.upper_limit = 0;
        this.lower_data_limit = 0;
        this.upper_data_limit = 0;
        this.nowposition = 0;
        this.daywidth = 0;
    }

    initialise(
        start: number,
        end: number,
        nowposition: number,
        daywidth: number
    ): void {
        this.nowposition = nowposition;
        this.daywidth = daywidth;
        this.setLimits(start, end);
        this.loadDataHorizon();
    }

    // OnScroll
    setStartEnd(
        start: number,
        end: number,
        nowposition: number,
        daywidth: number
    ): void {
        this.nowposition = nowposition;
        this.daywidth = daywidth;
        if (this.needData(start, end)) {
            this.setLimits(start, end);
            this.loadDataHorizon();
        }
    }

    private needData(start: number, end: number): boolean {
        return start < this.lower_data_limit || end > this.upper_data_limit;
    }

    private setLimits(start: number, end: number): void {
        this.lower_limit = start - HORIZON_BUFFER;
        this.lower_data_limit = start - HORIZON_BUFFER_ALERT;
        this.upper_limit = end + HORIZON_BUFFER;
        this.upper_data_limit = end + HORIZON_BUFFER_ALERT;
    }

    // OnScroll
    private loadDataHorizon(): void {
        const lowerLimit = GanttChartDateHelper.pixelToDate(
            this.lower_limit,
            this.nowposition,
            this.daywidth
        );
        const upLimit = GanttChartDateHelper.pixelToDate(
            this.upper_limit,
            this.nowposition,
            this.daywidth
        );
        this.onHorizonChange(lowerLimit, upLimit);
    }

    // Placeholder for the method to handle horizon change, to be implemented by the caller
    onHorizonChange(lowerLimit: Date, upLimit: Date): void {
        throw new Error("Method 'onHorizonChange' must be implemented.");
    }
}
