import { TimelineEvent } from '../Model/TimelineEvent';
import { TimelineEventDependency } from '../Model/TimelineEventDependency';

export class TimelineEventSortingService {
    static placeUpdatedEvent(
        events: TimelineEvent[],
        updatedEvent: TimelineEvent
    ): TimelineEvent[] {
        if (!events || events.length == 0) return [updatedEvent];

        const prevEventState = events.find(
            (event) => event.id == updatedEvent.id
        );
        const prevEventIndex = prevEventState
            ? events.indexOf(prevEventState)
            : -1;

        const deps = new Set(
            updatedEvent.dependencies.map((dep) => dep.dependencyEventId)
        );
        events = events.filter((event) => event.id !== updatedEvent.id);

        const firstStageOccurrence =
            events
                .map((event, index) => ({ event, index }))
                .find(({ event }) => event.stage === updatedEvent.stage)
                ?.index ?? -1;

        const eventsFromStage = events.filter(
            (event) => event.stage === updatedEvent.stage
        );
        const eventsIdsFromStage = new Set(
            eventsFromStage.map((event) => event.id)
        );
        events = events.filter((event) => !eventsIdsFromStage.has(event.id));

        const lastPositionOfDependency =
            events
                .map((event, index) => ({ event, index }))
                .reverse()
                .find(({ event }) => deps.has(event.id))?.index ?? -1;
        const positionToInsertAfterDependency =
            lastPositionOfDependency == -1 ? -1 : lastPositionOfDependency + 1;

        let positionToPlace = Math.max(
            firstStageOccurrence,
            positionToInsertAfterDependency
        );
        positionToPlace =
            positionToPlace < 0 ? prevEventIndex : positionToPlace;
        positionToPlace = positionToPlace < 0 ? events.length : positionToPlace;

        events.splice(positionToPlace, 0, ...eventsFromStage, updatedEvent);
        return events;
    }

    static sortTimelineEvents(events: TimelineEvent[]): TimelineEvent[] {
        if (!events || events.length == 0) return [];
        if (events.length == 1) {
            this.fillStartEndDay(events);
            return events;
        }

        const stagesOrder = this.getDistinctStages(events);
        const graph = this.mapDependenciesGraph(events);
        const sortedByDependencies = this.topologicalSort(
            events,
            graph
        ).reverse();

        const groupedByStagesEvents = sortedByDependencies.reduce(
            (groups, event) => {
                if (!groups[event.stage]) {
                    groups[event.stage] = [];
                }
                groups[event.stage].push({ ...event, dayStart: 0, dayEnd: 0 });
                return groups;
            },
            {} as Record<number, TimelineEvent[]>
        );

        const groupedSorted = Object.keys(groupedByStagesEvents)
            .sort(
                (a, b) =>
                    stagesOrder.indexOf(parseInt(a)) -
                    stagesOrder.indexOf(parseInt(b))
            )
            .flatMap((stage) => groupedByStagesEvents[parseInt(stage)]);

        this.fillStartEndDay(groupedSorted);

        const result = groupedSorted.sort((a, b) => {
            if (a.dayStart !== b.dayStart) return a.dayStart - b.dayStart;
            if (a.dayEnd !== b.dayEnd) return a.dayEnd - b.dayEnd;
            return a.name.localeCompare(b.name);
        });
        return result;
    }

    static getDistinctStages(events: TimelineEvent[]): number[] {
        const stages = events.map((event) => event.stage);
        return stages.filter((stage, index) => stages.indexOf(stage) === index);
    }

    private static mapDependenciesGraph(
        events: TimelineEvent[]
    ): Record<string, string[]> {
        const graph: Record<string, string[]> = {};
        events.forEach((event) => {
            if (!graph[event.id]) graph[event.id] = [];

            event.dependencies.forEach(
                (dependency: TimelineEventDependency) => {
                    if (!graph[dependency.dependencyEventId])
                        graph[dependency.dependencyEventId] = [];
                    graph[dependency.dependencyEventId].push(event.id);
                }
            );
        });

        return graph;
    }

    private static fillStartEndDay(events: TimelineEvent[]): void {
        let lastStageId = 0;
        let lastStageDayEnd = 0;
        let curStageDayEnd = 0;

        events.forEach((event, i) => {
            if (lastStageId !== event.stage) {
                lastStageDayEnd = lastStageId === 0 ? 0 : curStageDayEnd;
                lastStageId = event.stage;
            }

            let maxDepDayEnd = 0;

            if (event.dependencies.length > 0) {
                event.dependencies.forEach((dep) => {
                    const depEvent = events.find(
                        (e) => e.id === dep.dependencyEventId
                    );
                    if (
                        !depEvent ||
                        depEvent.dayStart < 0 ||
                        depEvent.dayEnd <= 0
                    ) {
                        throw new Error(
                            'Timeline Template Events order is broken'
                        );
                    }
                    const depDayEnd = depEvent.dayEnd + dep.offsetDays;
                    if (depDayEnd > maxDepDayEnd) maxDepDayEnd = depDayEnd;
                });
            }

            const dayStart = Math.max(maxDepDayEnd, lastStageDayEnd);
            const dayEnd = dayStart + event.duration;
            curStageDayEnd = Math.max(curStageDayEnd, dayEnd);
            events[i] = { ...event, dayStart, dayEnd };
        });
    }

    private static topologicalSort(
        events: TimelineEvent[],
        graph: Record<string, string[]>
    ): TimelineEvent[] {
        const sorted: string[] = [];
        const visited = new Set<string>();
        const visiting = new Set<string>();

        const dfs = (current: string): boolean => {
            if (visiting.has(current)) return false; // Cycle detected
            if (visited.has(current)) return true; // Already processed

            visiting.add(current);

            for (const neighbor of graph[current] || []) {
                if (!dfs(neighbor)) return false;
            }

            visiting.delete(current);
            visited.add(current);
            sorted.push(current);

            return true;
        };

        for (const event of events) {
            if (!visited.has(event.id)) {
                if (!dfs(event.id)) {
                    throw new Error('Timeline Template Events order is broken');
                }
            }
        }

        return sorted.map(
            (id) => events.find((event) => event.id === id) as TimelineEvent
        );
    }
}
