import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';
import './ActionDefinitionForm.scss';
import { TimelineParty } from '../Model/TimelineParty';
import { RadioGroup } from '@mui/material';
import {
    BuyerLinkActionTypeOptions as BuyerLinkActionGroupOptions,
    SellerActionTypeGroups,
    BuyerActionTypeGroups,
    SellerLinkActionTypeOptions as SellerLinkActionGroupOptions,
    TimelineActionType,
    MarketplaceEventActionTypeList,
    BuyerExpresionOfInterestOptionsList,
} from '../Model/TimelineActionType';
import ExpandableRadioButton from './ExpandableRadioButton';
import { Field, Form, Input } from '@availity/form';
import * as yup from 'yup';
import { defaultTimelineAction, TimelineAction } from '../Model/TimelineAction';

interface ActionDefinitionFormProps {
    party: TimelineParty;
    onSubmit(action: TimelineAction): void;
    disabled: boolean;
    linkedAction?: TimelineAction;
}

const ActionDefinitionForm = forwardRef(
    (props: ActionDefinitionFormProps, ref) => {
        const [value, setValue] = useState<
            BuyerActionTypeGroups | SellerActionTypeGroups
        >(null);

        useEffect(() => {
            const selectedGroup =
                MarketplaceEventActionTypeList.find(
                    (f) => f.id === props.linkedAction?.actionType
                )?.group ?? null;

            setValue(selectedGroup);
        }, []);

        const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setValue(Number(event.target.value));
        };

        const formRef = useRef<any>();

        useImperativeHandle(ref, () => ({
            submit() {
                props.onSubmit(defaultTimelineAction)
                // formRef?.current?.handleSubmit();
            },
        }));

        const getBuyerActionGroupForm = (
            actionGroup: BuyerActionTypeGroups
        ) => {
            switch (actionGroup) {
                case BuyerActionTypeGroups.DocumentExecution:
                    return (
                        <Form
                            innerRef={formRef}
                            initialValues={{ document: '' }}
                            onSubmit={() => {}}>
                            <Field
                                name="document"
                                label="Select Document"></Field>
                        </Form>
                    );
                case BuyerActionTypeGroups.ExpresstionOfInterest:
                    return (
                        <Form
                            innerRef={formRef}
                            initialValues={{
                                actionType:
                                    props.linkedAction?.actionType ??
                                    TimelineActionType.BuyerExpressionOfInterest,
                            }}
                            validationSchema={yup.object().shape({
                                actionType: yup.string().min(1),
                            })}
                            onSubmit={(values) => {
                                props.onSubmit({
                                    ...defaultTimelineAction,
                                    actionType: values.actionType,
                                });
                            }}>
                            <Input
                                type="select"
                                name="actionType"
                                disabled={props.disabled}>
                                {BuyerExpresionOfInterestOptionsList.map(
                                    (option) => (
                                        <option
                                            key={option.value}
                                            value={option.value}>
                                            {option.label}
                                        </option>
                                    )
                                )}
                            </Input>
                        </Form>
                    );
                case BuyerActionTypeGroups.ReviewDocument:
                    return (
                        <Form
                            initialValues={{ document: '' }}
                            onSubmit={() => {}}>
                            <Field
                                name="document"
                                label="Upload Document"></Field>
                        </Form>
                    );
                case BuyerActionTypeGroups.UploadDocument:
                    return <></>;
                case BuyerActionTypeGroups.Custom:
                    return (
                        <RadioGroup style={{ marginLeft: '1rem' }}>
                            {[
                                TimelineActionType.BuyerScheduleMeeting,
                                TimelineActionType.BuyerLoiInformation,
                            ].map((actionType) => {
                                return (
                                    <ExpandableRadioButton
                                        disabled={props.disabled}
                                        value={actionType}
                                        label={
                                            MarketplaceEventActionTypeList.find(
                                                (f) => f.id === actionType
                                            )?.name
                                        }
                                        isExpanded={false}
                                        expandableContent={''}
                                    />
                                );
                            })}
                        </RadioGroup>
                    );
            }
        };

        const getSellerActionGroupForm = (
            actionGroup: SellerActionTypeGroups
        ) => {
            switch (actionGroup) {
                case SellerActionTypeGroups.DataAndDocumentRequest:
                    return 'data request project onboard form';
                case SellerActionTypeGroups.EngagementDocument:
                    return (
                        <Form
                            initialValues={{
                                type: '',
                                documentSource: '',
                                documentSection: '',
                                documentName: '',
                            }}
                            onSubmit={() => {}}>
                            <Field name="type" label="Action Type" />
                            <Field
                                name="documentSource"
                                label="Select Document Source"
                            />
                            <Field
                                name="documentSection"
                                label="Document Section"
                            />
                            <Field name="documentName" label="Document Name" />
                        </Form>
                    );
                case SellerActionTypeGroups.BuyerSelection:
                    return (
                        <RadioGroup style={{ marginLeft: '1rem' }}>
                            {[
                                TimelineActionType.SellerBuyerSelectionStage1Nda,
                                TimelineActionType.SellerBuyerSelectionStage2Nda,
                                TimelineActionType.SellerBuyerSelectionDetailedFinancialModel,
                                TimelineActionType.SellerBuyerSelectionMeetings,
                                TimelineActionType.SellerBuyerSelectionStage3Nda,
                            ].map((actionType) => {
                                return (
                                    <ExpandableRadioButton
                                        disabled={props.disabled}
                                        value={actionType}
                                        label={
                                            MarketplaceEventActionTypeList.find(
                                                (f) => f.id === actionType
                                            )?.name
                                        }
                                        isExpanded={false}
                                        expandableContent={''}
                                    />
                                );
                            })}
                        </RadioGroup>
                    );
                case SellerActionTypeGroups.Custom:
                    return (
                        <RadioGroup style={{ marginLeft: '1rem' }}>
                            {[
                                TimelineActionType.SellerCustomTeaserApproval,
                                TimelineActionType.SellerCustomProcessDuration,
                                TimelineActionType.SellerCustomMeetingAvailability,
                            ].map((actionType) => {
                                return (
                                    <ExpandableRadioButton
                                        disabled={props.disabled}
                                        value={actionType}
                                        label={
                                            MarketplaceEventActionTypeList.find(
                                                (f) => f.id === actionType
                                            )?.name
                                        }
                                        isExpanded={false}
                                        expandableContent={''}
                                    />
                                );
                            })}
                        </RadioGroup>
                    );
                default:
                    break;
            }
        };

        const buyerActionsForm = () => (
            <RadioGroup value={value} onChange={handleChange}>
                {BuyerLinkActionGroupOptions.map((actionGroup) => {
                    return (
                        <ExpandableRadioButton
                            disabled={props.disabled}
                            value={actionGroup.id}
                            label={actionGroup.name}
                            isExpanded={value === actionGroup.id}
                            expandableContent={getBuyerActionGroupForm(
                                actionGroup.id
                            )}
                        />
                    );
                })}
            </RadioGroup>
        );

        const sellerActionsForm = () => (
            <RadioGroup value={value} onChange={handleChange}>
                {SellerLinkActionGroupOptions.map((actionGroup) => {
                    return (
                        <ExpandableRadioButton
                            disabled={props.disabled}
                            value={actionGroup.id}
                            label={actionGroup.name}
                            isExpanded={value === actionGroup.id}
                            expandableContent={getSellerActionGroupForm(
                                actionGroup.id
                            )}
                        />
                    );
                })}
            </RadioGroup>
        );

        const renderForm = () => {
            switch (props.party) {
                case TimelineParty.Buyer:
                    return buyerActionsForm();
                case TimelineParty.Seller:
                    return sellerActionsForm();
                default:
                    return 'N/A';
            }
        };

        return <div className="action-definition-form">{renderForm()}</div>;
    }
);

export default ActionDefinitionForm;
