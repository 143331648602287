export enum RewardItemTypeEnum {
    Apvar,
    Capvar,
    Kpi,
    Pvc,
    Donation,
    Rebate,
    Refund,
    Reward,
    Withdrawal,
    GiftCard,
    KnowledgeLibraryContent,
    MembershipFeeInd,
    MembershipFeeCorp,
    PmgFeeInd,
    PmgFeeCorp,
}

export const RewardItemTypeList = [
    { id: 0, name: 'APVAR' },
    { id: 1, name: 'CAPVAR' },
    { id: 2, name: 'KPI Benchmarking' },
    { id: 3, name: 'Practice Valuation Comparison' },
    { id: 4, name: 'Donation' },
    { id: 5, name: 'Rebate' },
    { id: 6, name: 'Refund' },
    { id: 7, name: 'Reward' },
    { id: 8, name: 'Withdrawal' },
    { id: 9, name: 'Gift Card' },
    { id: 10, name: 'Knowledge Library Content' },
    { id: 11, name: 'Membership Fee, Ind' },
    { id: 12, name: 'Membership Fee, Corp' },
    { id: 13, name: 'Pmg Fee, Ind' },
    { id: 14, name: 'Pmg Fee, Corp' },
];
