import './InviteNewBuyerModal.scss';
import React, { useEffect, useState } from 'react';
import { useStateSelector } from '../../../../store/selectors';
import Rodal from '../../../../components/Rodal/Rodal';
import * as yup from 'yup';
import { Form, Field, Input } from '@availity/form';
import createNotification from '../../../../utils/createNotification';
import Loader from 'react-loaders';
import { CorporateGroup } from '../../../CorporateGroupManagement/Model/CorporateGroup';

export interface InviteNewBuyerModalProps {
    onClose: () => void;
    isVisible: boolean;
}

interface InviteNewBuyerState {
    name: string;
    email: string;
    affiliationId: number;
}

export const InviteNewBuyerModal = (props: InviteNewBuyerModalProps) => {
    const axios = useStateSelector((state) => state.core.axios);
    const [isLoading, setIsLoading] = useState(false);
    const [groups, setGroups] = useState<CorporateGroup[]>([]);

    useEffect(() => {
        fetchGroups();
    }, []);

    const inviteNewBuyer = (state: InviteNewBuyerState) => {
        axios
            .post(`api/Marketplace/MarketplaceBuyers`, state)
            .then((response: any) => {
                if (response) {
                    if (response.status === 200) {
                        props.onClose();
                    } else {
                        createNotification(
                            response.response.data.title,
                            'error'
                        );
                    }
                }
            })
            .catch(() => {
                createNotification(
                    'An unexpected error occurred while copying Timeline Template.',
                    'error'
                );
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const fetchGroups = () => {
        axios
            .get('/api/CorporateGroups/groups')
            .then((response: any) => {
                if (response && response.data) {
                    setGroups(response.data);
                } else {
                    createNotification(
                        'An unexpected error occurred while loading Corporate Groups.',
                        'error'
                    );
                }
            })
            .catch(() => {
                createNotification(
                    'An unexpected error occurred while loading Corporate Groups.',
                    'error'
                );
            });
    };

    const renderForm = () => (
        <Form
            initialValues={{
                name: ``,
                email: '',
                affiliationId: 0,
            }}
            validationSchema={yup.object().shape({
                name: yup.string().required(),
                affiliationId: yup.number().min(1).required(),
                email: yup.string().email().required(),
            })}
            onSubmit={(state: InviteNewBuyerState) => inviteNewBuyer(state)}>
            <div className="d-flex flex-column">
                <div className="form-group row">
                    <div className="col-md-3 d-flex mt-2">
                        <label className="field-label" htmlFor="name">
                            Name:
                        </label>
                    </div>
                    <div className="col-md-9">
                        <Field
                            type="text"
                            name="name"
                            id="name"
                            className="form-control"
                        />
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col-md-3 d-flex mt-2">
                        <label className="field-label" htmlFor="email">
                            Email:
                        </label>
                    </div>
                    <div className="col-md-9">
                        <Field
                            type="email"
                            name="email"
                            id="email"
                            className="form-control"
                        />
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col-md-3 d-flex mt-2">
                        <label className="field-label" htmlFor="affiliationId">
                            Corp affiliation:
                        </label>
                    </div>
                    <div className="col-md-9">
                        <Input type="select" name="affiliationId">
                            <option value={0} key={0}>
                                {''}
                            </option>
                            {groups.map((opt) => (
                                <option value={opt.id} key={opt.id}>
                                    {opt.name}
                                </option>
                            ))}
                        </Input>
                    </div>
                </div>

                <div className="mt-auto d-flex justify-content-end">
                    <button
                        type="button"
                        className="btn btn-cancel"
                        onClick={() => props.onClose()}>
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={isLoading}>
                        Send Invite
                    </button>
                </div>
            </div>
        </Form>
    );

    const renderLoader = () => {
        return (
            <div className="loader-container" style={{ height: '10vw' }}>
                <div className="loader-container-inner">
                    <div className="text-center">
                        <Loader active={isLoading} type="ball-scale-multiple" />
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div>
            <Rodal
                visible={props.isVisible}
                animation="slideTop"
                onClose={() => props.onClose()}
                showMask={false}
                width={600}
                closeOnEsc
                className="modal-invite-new-buyer"
                center>
                <div className="modal-header">Invite a New Buyer</div>
                <div className="modal-body">
                    {isLoading ? renderLoader() : renderForm()}
                </div>
            </Rodal>
        </div>
    );
};
