import React, { Component } from 'react';
import { GanttChartHeaders } from './components/GanttChartHeaders/GanttChartHeaders';
import DataViewPort from './components/GanttChartDataTaskViewPort/GanttChartDataViewPort';
import { DAY_WIDTH, FIRST_COLUMN_GAP } from './GanttChartConstants';
import GanttChartDataController from './controllers/GanttChartDataController';
import Config from './helpers/config/GanttChartConfigs';
import GanttChartDateHelper from './helpers/GanttChartDateHelper';
import './GanttChart.scss';
import { GanttChartItem } from './models/GanttChartItem.model';

interface GanttChartProps {
    itemheight: number;
    data: GanttChartItem[];
    startDate: Date;
    endDate: Date;
    onHorizonChange?: (lowerLimit: Date, upLimit: Date) => void;
    config: any;
}

interface GanttChartState {
    nowposition: number;
    scrollLeft: number;
    size: { width: number; height: number };
}

class GanttChart extends Component<GanttChartProps, GanttChartState> {
    private dc: GanttChartDataController;
    private initialise: boolean;

    constructor(props: GanttChartProps) {
        super(props);
        this.dc = new GanttChartDataController();
        this.dc.onHorizonChange = this.onHorizonChange;
        this.initialise = false;

        Config.load(this.props.config);
        console.log(this.props);

        this.state = {
            nowposition: 0,
            scrollLeft: 0,
            size: { width: 1, height: 1 },
        };
    }

    ////////////////////
    //     ON SIZE    //
    ////////////////////

    onSize = (size: { width: number; height: number }) => {
        if (!this.initialise) {
            this.dc.initialise(
                this.state.scrollLeft + this.state.nowposition,
                this.state.scrollLeft + this.state.nowposition + size.width,
                this.state.nowposition,
                DAY_WIDTH
            );
            this.initialise = true;
        }
        this.setStartEnd();
        this.setState({
            size: size,
        });
    };

    setStartEnd = () => {
        this.dc.setStartEnd(
            this.state.scrollLeft,
            this.state.scrollLeft + this.state.size.width,
            this.state.nowposition,
            DAY_WIDTH
        );
    };

    onHorizonChange = (lowerLimit: Date, upLimit: Date) => {
        if (this.props.onHorizonChange)
            this.props.onHorizonChange(lowerLimit, upLimit);
    };

    /////////////////////
    //   ITEMS EVENTS  //
    /////////////////////

    calcNumVisibleDays = (size: { width: number; height: number }) => {
        return Math.ceil(size.width / DAY_WIDTH);
    };

    render() {
        return (
            <div className="gantt-chart">
                <div className="timeLine-main">
                    <GanttChartHeaders
                        nowposition={this.state.nowposition}
                        upperBoundary={
                            GanttChartDateHelper.getDifferenceInDays(
                                this.props.startDate,
                                this.props.endDate
                            ) *
                                DAY_WIDTH +
                            FIRST_COLUMN_GAP * DAY_WIDTH
                        }
                    />
                    <DataViewPort
                        itemheight={this.props.itemheight}
                        nowposition={this.state.nowposition}
                        data={this.props.data}
                        upperBoundary={
                            GanttChartDateHelper.getDifferenceInDays(
                                this.props.startDate,
                                this.props.endDate
                            ) *
                                DAY_WIDTH +
                            FIRST_COLUMN_GAP * DAY_WIDTH
                        }
                        onSize={this.onSize}
                    />
                </div>
            </div>
        );
    }
}

export default GanttChart;
