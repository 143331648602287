import React from 'react';
import { Badge } from 'reactstrap';
import { useStateSelector } from '../../../../store/selectors';
import { DataTable } from '../../../../models/DataRequestHub/DataTable';

interface RenderCellBadgeNotificationProps {
    isAdminView: boolean;
    dataTable: DataTable;
    selectedTableViewId: number;
    columnCustomId: string;
    rowCustomId: string;
}

export const RenderCellBadgeNotification = (
    props: RenderCellBadgeNotificationProps
) => {
    const cellsNotificationsStatus = useStateSelector(
        (s) => s.dataRequestsNotifications.tableCellsSeenStatus
    );
    const tableNotificationStatus = useStateSelector((s) =>
        s.dataRequestsNotifications.tablesNotificationsStatus.find(
            (f) => f.tableId === props.dataTable.id
        )
    );

    if (props.isAdminView) return <></>;
    const cellNotificationStatus = cellsNotificationsStatus.find(
        (cell) =>
            props.dataTable.id === cell.tableId &&
            cell.customColumnId === props.columnCustomId &&
            cell.customRowId === props.rowCustomId
    );

    const targetRowStatus = tableNotificationStatus?.rowsSeenStatus?.find(
        (row) => row.customRowId === cellNotificationStatus?.customRowId
    );

    const targetView = tableNotificationStatus?.viewsNotificationsStatus.find(
        (viewStatus) => viewStatus.viewId === props.selectedTableViewId
    );

    const targetColumnStatus = props.selectedTableViewId
        ? targetView?.columnsSeenStatus.find(
              (columnStatus) =>
                  columnStatus.customColumnId ===
                  cellNotificationStatus?.customColumnId
          )
        : null;

    const isNotificationsVisible =
        !cellNotificationStatus?.isSeenByUser &&
        !props.isAdminView &&
        !cellNotificationStatus?.isNewTable &&
        targetRowStatus?.isSeenByUser &&
        targetColumnStatus?.isSeenByUser &&
        targetView?.columnsSeenStatus?.length &&
        !props.dataTable.isSnapshot;

    return isNotificationsVisible ? (
        <Badge className="cell-notification-badge unread-comments-badge">
            1
        </Badge>
    ) : (
        <></>
    );
};
