import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    GridColDef,
    GridEditDateCell,
    GridPreProcessEditCellProps,
    useGridApiRef,
} from '@mui/x-data-grid';
import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';
import TextInputColumn from '../../../../../components/EditableTable/CustomColumns/TextInputColumn/TextInputColumn';
import EditableTable from '../../../../../components/EditableTable/EditableTable';
import Helpers from '../../../../../utils/helper';
import {
    ProjectVariableOption,
    ProjectVariable,
} from '../../../../../models/DataRequestHub/ProjectVariable';
import moment from 'moment';
import {
    validateDateAndAdjustClasses,
    validateGridEditorRowDisplayOrder,
    validateRequiredCell,
    validateUniqueItemName,
} from '../../../../../components/EditableTable/EditableTableValidationHelper';
import DataFormQuestion from '../../../../../models/DataRequestHub/DataFormQuestion';
import { TimeZone, TimeZoneHelper } from '../../../../../utils/timeZoneHelper';
import { EditorTypeEnum } from '../../../../../models/DataRequestHub/ProjectEditorEnum';
import PortalTooltip from '../../../../../components/PortalTooltip/PortalTooltip';

interface FinancialRequestEditorInterface {
    setIsProjectVariableEditPopupVisible(value: boolean): void;
    setVariableNameToEdit(value: string): void;
    setVarialbeIdToEdit(value: number): void;
    templateRows: DataFormQuestion[];
    setTemplateRows(rows: DataFormQuestion[]): void;
    setProjectVariableOptionsToEdit(options: ProjectVariableOption[]): void;
    isLoading: boolean;
    likelySourceVariable: ProjectVariable;
    displayFormatVariable: ProjectVariable;
    frequencyVariable: ProjectVariable;
}

const FinancialRequestEditor = forwardRef(
    (props: FinancialRequestEditorInterface, ref) => {
        const apiRef = useGridApiRef();
        const {
            setVariableNameToEdit,
            setIsProjectVariableEditPopupVisible,
            templateRows,
            setTemplateRows,
            setVarialbeIdToEdit,
            setProjectVariableOptionsToEdit,
            isLoading,
            likelySourceVariable,
            displayFormatVariable,
            frequencyVariable,
        } = props;

        const gridTableRef = useRef(null);
        const [timeZone] = useState<TimeZone>(
            TimeZoneHelper.getTimeZoneByDate(new Date())
        );
        useImperativeHandle(ref, () => ({
            addRow() {
                gridTableRef.current.addRow();
            },
            validateAllRows() {
                return validateAllRows();
            },
        }));

        const alignFinancialRequestQuestionPeriod = (
            firstDate: Date,
            secondDate: Date
        ): { dateStarted: Date; dateEnded: Date } => {
            if (firstDate > secondDate) {
                return {
                    dateStarted: new Date(secondDate),
                    dateEnded: new Date(firstDate),
                };
            }
            return {
                dateStarted: new Date(firstDate),
                dateEnded: new Date(secondDate),
            };
        };

        const getCellTooltip = (
            anchorId: string,
            content: React.JSX.Element
        ) => {
            return (
                <PortalTooltip
                    anchorId={anchorId}
                    delayShow={1000}
                    place="bottom"
                    content={content}
                />
            );
        };

        const columns = (): GridColDef[] => [
            {
                field: 'displayOrder',
                headerName: 'Display Order',
                minWidth: 140,
                flex: 0.25,
                type: 'number',
                cellClassName: 'cell-input display-order',
                editable: true,
                align: 'left',
                renderCell: (params) => {
                    const displayOrderCellId = `display-order-cell-${params.id}`;
                    const tooltipContent = <div>{params.value}</div>;

                    return (
                        <>
                            {params.value
                                ? getCellTooltip(
                                      displayOrderCellId,
                                      tooltipContent
                                  )
                                : []}
                            <div
                                id={displayOrderCellId}
                                className="MuiDataGrid-cellContent">
                                {params.value}
                            </div>
                        </>
                    );
                },
                headerAlign: 'left',
                preProcessEditCellProps: (
                    params: GridPreProcessEditCellProps
                ) => {
                    const value = params.props.value;
                    const hasError = value <= 0;
                    return { ...params.props, error: hasError };
                },
            },
            {
                field: 'displayName',
                headerName: 'Item Name',
                type: 'string',
                cellClassName: 'cell-text-input display-name',
                minWidth: 150,
                flex: 1,
                editable: true,
                renderCell: (params) => {
                    const displayNameCellId = `display-name-cell-${params.id}`;
                    const tooltipContent = <div>{params.row.displayName}</div>;

                    return (
                        <>
                            {params.row.displayName
                                ? getCellTooltip(
                                      displayNameCellId,
                                      tooltipContent
                                  )
                                : []}
                            <div
                                id={displayNameCellId}
                                className="MuiDataGrid-cellContent">
                                {params.row.displayName}
                            </div>
                        </>
                    );
                },
                preProcessEditCellProps: (
                    params: GridPreProcessEditCellProps
                ) => {
                    const value = params.props.value;

                    const hasError = value?.trim().length <= 0;
                    return { ...params.props, error: hasError };
                },
                renderEditCell: (params) => <TextInputColumn {...params} />,
            },
            {
                field: 'description',
                headerName: 'Description',
                minWidth: 150,
                flex: 1,
                type: 'string',
                cellClassName: 'cell-text-input description',
                editable: true,
                renderCell: (params) => {
                    const descriptionNameCellId = `description-cell-${params.id}`;
                    const tooltipContent = <div>{params.row.description}</div>;

                    return (
                        <>
                            {params.row.description
                                ? getCellTooltip(
                                      descriptionNameCellId,
                                      tooltipContent
                                  )
                                : []}
                            <div
                                id={descriptionNameCellId}
                                className="MuiDataGrid-cellContent">
                                {params.row.description}
                            </div>
                        </>
                    );
                },
                renderEditCell: (params) => <TextInputColumn {...params} />,
            },
            {
                field: 'frequency',
                headerName: 'Frequency',
                minWidth: 170,
                cellClassName: 'frequency',
                flex: 0.5,
                editable: true,
                type: 'singleSelect',
                valueOptions: frequencyVariable?.options.map((x) => x.option),
                renderHeader: () => {
                    return (
                        <>
                            Frequency
                            <FontAwesomeIcon
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setIsProjectVariableEditPopupVisible(true);
                                    setVarialbeIdToEdit(frequencyVariable.id);
                                    setVariableNameToEdit(
                                        frequencyVariable.name
                                    );
                                    setProjectVariableOptionsToEdit(
                                        frequencyVariable.options
                                    );
                                }}
                                style={{ marginLeft: '10px' }}
                                size="1x"
                                icon={faEdit as any}
                                title="Save"
                            />
                        </>
                    );
                },
                preProcessEditCellProps: (
                    params: GridPreProcessEditCellProps
                ) => {
                    const hasError = params.props.value?.length <= 0;
                    return { ...params.props, error: hasError };
                },
                renderCell: (params) => {
                    const frequencyCellId = `frequency-cell-${params.id}`;
                    const tooltipContent = <div>{params.value}</div>;

                    return (
                        <>
                            {params.value
                                ? getCellTooltip(
                                      frequencyCellId,
                                      tooltipContent
                                  )
                                : []}
                            <div
                                id={frequencyCellId}
                                className="MuiDataGrid-cellContent">
                                {params.value}
                            </div>
                        </>
                    );
                },
            },
            {
                field: 'likelySource',
                headerName: 'Likely Source',
                minWidth: 170,
                renderCell: (params) => {
                    const likelySourceCellId = `likely-source-cell-${params.id}`;
                    const tooltipContent = <div>{params.value}</div>;

                    return (
                        <>
                            {params.value
                                ? getCellTooltip(
                                      likelySourceCellId,
                                      tooltipContent
                                  )
                                : []}
                            <div
                                id={likelySourceCellId}
                                className="MuiDataGrid-cellContent">
                                {params.value}
                            </div>
                        </>
                    );
                },
                cellClassName: 'likely-source',
                flex: 0.5,
                type: 'singleSelect',
                renderHeader: () => {
                    return (
                        <>
                            Likely Source
                            <FontAwesomeIcon
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setIsProjectVariableEditPopupVisible(true);
                                    setVarialbeIdToEdit(
                                        likelySourceVariable.id
                                    );
                                    setVariableNameToEdit(
                                        likelySourceVariable.name
                                    );
                                    setProjectVariableOptionsToEdit(
                                        likelySourceVariable.options
                                    );
                                }}
                                style={{ marginLeft: '10px' }}
                                size="1x"
                                icon={faEdit as any}
                                title="Save"
                            />
                        </>
                    );
                },
                valueOptions: likelySourceVariable?.options.map(
                    (x) => x.option
                ),
                editable: true,
                preProcessEditCellProps: (
                    params: GridPreProcessEditCellProps
                ) => {
                    const hasError = params.props.value?.length <= 0;
                    return { ...params.props, error: hasError };
                },
            },
            {
                field: 'displayFileFormat',
                headerName: 'Display Format',
                cellClassName: 'display-file-format',
                minWidth: 170,
                flex: 0.5,
                type: 'singleSelect',
                renderHeader: () => {
                    return (
                        <>
                            Display Format
                            <FontAwesomeIcon
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setIsProjectVariableEditPopupVisible(true);
                                    setVarialbeIdToEdit(
                                        displayFormatVariable.id
                                    );
                                    setVariableNameToEdit(
                                        displayFormatVariable.name
                                    );
                                    setProjectVariableOptionsToEdit(
                                        displayFormatVariable.options
                                    );
                                }}
                                style={{ marginLeft: '10px' }}
                                size="1x"
                                icon={faEdit as any}
                                title="Save"
                            />
                        </>
                    );
                },
                renderCell: (params) => {
                    const displayFileFormatCellId = `display-file-format-cell-${params.id}`;
                    const tooltipContent = <div>{params.value}</div>;

                    return (
                        <>
                            {params.value
                                ? getCellTooltip(
                                      displayFileFormatCellId,
                                      tooltipContent
                                  )
                                : []}
                            <div
                                id={displayFileFormatCellId}
                                className="MuiDataGrid-cellContent">
                                {params.value}
                            </div>
                        </>
                    );
                },
                valueOptions: displayFormatVariable?.options.map((x) => x.name),
                editable: true,
                preProcessEditCellProps: (
                    params: GridPreProcessEditCellProps
                ) => {
                    const hasError = params.props.value?.length <= 0;

                    return { ...params.props, error: hasError };
                },
            },
            {
                field: 'allowedFileFormat',
                editable: true,
            },
            {
                field: 'datePeriodStarted',
                headerName: 'Start',
                minWidth: 150,
                flex: 0.5,
                cellClassName: 'period-date-start date-picker',
                editable: true,
                type: 'date',
                valueFormatter: (value: any) =>
                    value ? moment(value).format('L') : null,
                preProcessEditCellProps: (
                    params: GridPreProcessEditCellProps
                ) => {
                    const hasError = !!!params.props.value;
                    return { ...params.props, error: hasError };
                },
                renderEditCell: (params) => {
                    return <GridEditDateCell {...params} />;
                },
                valueSetter: (value, row) => {
                    if (value && !isNaN(Date.parse(value))) {
                        const currentValue = row.datePeriodStarted;
                        const parsedValue = new Date(value);
                        if (
                            currentValue &&
                            parsedValue.toISOString() === currentValue
                        ) {
                            return row;
                        }
                        const valueWithTImeZoneOffset =
                            TimeZoneHelper.parseUtcDate(
                                new Date(value),
                                timeZone
                            );
                        const datePeriodEndedValue = new Date(
                            row.datePeriodEnded
                        );
                        if (
                            !isNaN(new Date(row.datePeriodEnded).getTime()) &&
                            !!valueWithTImeZoneOffset &&
                            !!datePeriodEndedValue
                        ) {
                            const { dateStarted, dateEnded } =
                                alignFinancialRequestQuestionPeriod(
                                    valueWithTImeZoneOffset,
                                    datePeriodEndedValue
                                );

                            return {
                                ...row,
                                datePeriodStarted: dateStarted.toISOString(),
                                datePeriodEnded: dateEnded.toISOString(),
                            };
                        }

                        return {
                            ...row,
                            datePeriodStarted:
                                valueWithTImeZoneOffset.toISOString(),
                        };
                    }
                    return {
                        ...row,
                        datePeriodStarted: null,
                    };
                },
                renderCell: (params) => {
                    const datePeriodStartedCellId = `date-period-started-cell-${params.id}`;
                    const tooltipContent = <div>{params.formattedValue}</div>;

                    return (
                        <>
                            {params.formattedValue
                                ? getCellTooltip(
                                      datePeriodStartedCellId,
                                      tooltipContent
                                  )
                                : []}
                            <div
                                id={datePeriodStartedCellId}
                                className="MuiDataGrid-cellContent">
                                {params.formattedValue}
                            </div>
                        </>
                    );
                },
            },
            {
                field: 'datePeriodEnded',
                headerName: 'End',
                minWidth: 150,
                flex: 0.5,
                cellClassName: 'period-date-end date-picker',
                editable: true,
                type: 'date',
                renderEditCell: (params) => {
                    return <GridEditDateCell {...params} />;
                },
                valueFormatter: (value: any) =>
                    value ? moment(value).format('L') : null,
                preProcessEditCellProps: (
                    params: GridPreProcessEditCellProps
                ) => {
                    const hasError = !!!params.props.value;
                    return { ...params.props, error: hasError };
                },
                valueSetter: (value, row) => {
                    if (value && !isNaN(Date.parse(value))) {
                        const currentValue = row.datePeriodEnded;
                        const parsedValue = new Date(value);
                        if (
                            currentValue &&
                            parsedValue.toISOString() === currentValue
                        ) {
                            return row;
                        }
                        const valueWithTimeZoneOffset =
                            TimeZoneHelper.parseUtcDate(
                                new Date(value),
                                timeZone
                            );
                        const datePeriodStartedValue = new Date(
                            row.datePeriodStarted
                        );

                        if (
                            !isNaN(new Date(row.datePeriodStarted).getTime()) &&
                            !!datePeriodStartedValue &&
                            !!valueWithTimeZoneOffset
                        ) {
                            const { dateStarted, dateEnded } =
                                alignFinancialRequestQuestionPeriod(
                                    valueWithTimeZoneOffset,
                                    datePeriodStartedValue
                                );

                            return {
                                ...row,
                                datePeriodStarted: dateStarted.toISOString(),
                                datePeriodEnded: dateEnded.toISOString(),
                            };
                        }

                        return { ...row, datePeriodEnded: value.toISOString() };
                    }
                    return {
                        ...row,
                        datePeriodEnded: null,
                    };
                },
                renderCell: (params) => {
                    const datePeriodEndedCellId = `date-period-ended-cell-${params.id}`;
                    const tooltipContent = <div>{params.formattedValue}</div>;

                    return (
                        <>
                            {params.formattedValue
                                ? getCellTooltip(
                                      datePeriodEndedCellId,
                                      tooltipContent
                                  )
                                : []}
                            <div
                                id={datePeriodEndedCellId}
                                className="MuiDataGrid-cellContent">
                                {params.formattedValue}
                            </div>
                        </>
                    );
                },
            },
            {
                field: 'storageFolder',
                headerName: 'Storage Folder',
                minWidth: 150,
                flex: 0.5,
                cellClassName: 'cell-text-input storage-folder',
                editable: true,
                renderCell: (params) => {
                    const storageFolderCellId = `disstorage-folder-cell-${params.id}`;
                    const tooltipContent = (
                        <div>{params.row.storageFolder}</div>
                    );

                    return (
                        <>
                            {params.row.storageFolder
                                ? getCellTooltip(
                                      storageFolderCellId,
                                      tooltipContent
                                  )
                                : []}
                            <div
                                id={storageFolderCellId}
                                className="MuiDataGrid-cellContent">
                                {params.row.storageFolder}
                            </div>
                        </>
                    );
                },
                preProcessEditCellProps: (
                    params: GridPreProcessEditCellProps
                ) => {
                    if (params.hasChanged) {
                        const value = params.props.value as string;
                        const hasError = Helpers.hasFoldersPathError(
                            value,
                            '/'
                        );

                        return { ...params.props, error: hasError };
                    }

                    return { ...params.props };
                },
                renderEditCell: (params) => <TextInputColumn {...params} />,
            },
        ];

        const validateAllRows = () => {
            let isValid = true;
            for (let index = 0; index < templateRows.length; index++) {
                const rowId =
                    templateRows[index]?.id ?? templateRows[index].gridId;
                const element = document.querySelector(
                    '[data-id="' + rowId + '"]'
                );
                
                if (element) {
                    const displayNameElement =
                    element.querySelector('.display-name');
                const displayFileFormatElement = element.querySelector(
                    '.display-file-format'
                );
                const allowedFileFormatElement = element.querySelector(
                    '.allowed-file-format'
                );
                const likelySourceElement =
                    element.querySelector('.likely-source');
                const frequencyElement = element.querySelector('.frequency');
                const periodDateStartElement =
                    element.querySelector('.period-date-start');
                const periodDateEndElement =
                    element.querySelector('.period-date-end');
                    
                    const question = templateRows[index];
                    const hasDisplayNameError = validateRequiredCell(
                        displayNameElement,
                        question.displayName
                    );

                    const hasDisplayFileFormatError = validateRequiredCell(
                        displayFileFormatElement,
                        question.displayFileFormat
                    );

                    const hasAllowedFormatError = validateRequiredCell(
                        allowedFileFormatElement,
                        question.allowedFileFormat
                    );

                    const hasLikelySourceError = validateRequiredCell(
                        likelySourceElement,
                        question.likelySource
                    );

                    const hasFrequencyError = validateRequiredCell(
                        frequencyElement,
                        question.frequency
                    );
                    const hasDatePeriodStartedError =
                        validateRequiredCell(
                            periodDateStartElement,
                            question.datePeriodStarted
                        ) ||
                        validateDateAndAdjustClasses(
                            periodDateStartElement,
                            question.datePeriodStarted
                        );

                    const hasDatePeriodEndedError =
                        validateRequiredCell(
                            periodDateEndElement,
                            question.datePeriodEnded
                        ) ||
                        validateDateAndAdjustClasses(
                            periodDateEndElement,
                            question.datePeriodEnded
                        );

                    if (
                        hasDisplayNameError ||
                        hasDisplayFileFormatError ||
                        hasAllowedFormatError ||
                        hasLikelySourceError ||
                        hasFrequencyError ||
                        hasDatePeriodStartedError ||
                        hasDatePeriodEndedError
                    ) {
                        isValid = false;
                    }
                }
            }
            if (
                !validateGridEditorRowDisplayOrder(
                    templateRows,
                    apiRef.current.state,
                    EditorTypeEnum.FinancialRequest
                )
            ) {
                isValid = false;
            }

            if (
                !validateUniqueItemName(
                    templateRows,
                    apiRef.current.state,
                    EditorTypeEnum.FinancialRequest
                )
            ) {
                isValid = false;
            }

            return isValid;
        };

        const mapAllowedFilesFormat = (fileFormat: string) =>
            displayFormatVariable?.options?.find((f) => f.name === fileFormat)
                ?.option ?? 'any';

        const updateRows = (
            newRows: (oldRows: DataFormQuestion[]) => DataFormQuestion[]
        ): void => {
            const result = newRows(templateRows).map((question) => ({
                ...question,
                allowedFileFormat: mapAllowedFilesFormat(
                    question.displayFileFormat
                ),
            }));

            setTemplateRows(result);
        };

        useEffect(() => {
            validateAllRows();
        }, [templateRows]);

        return (
            <EditableTable
                editorType={EditorTypeEnum.FinancialRequest}
                columns={columns()}
                rows={templateRows}
                setRows={updateRows}
                isLoading={isLoading}
                fieldToFocus="displayOrder"
                ref={gridTableRef}
                gridApiRef={apiRef}
            />
        );
    }
);

export default FinancialRequestEditor;
