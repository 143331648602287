import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { GridColDef, DataGrid } from '@mui/x-data-grid';
import { TimelineStage } from '../../../Model/TimelineStage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import './StagesTable.scss';

interface StagesTableProps {
    stagesUsedLocally: number[];
    rows: TimelineStage[];
    stagesAreLoading: boolean;
    handleStageToUpdate: (id: number) => void;
    handleStageToRemove: (id: number) => void;
}

const StagesTable = (props: StagesTableProps) => {
    const [updatedRows, setUpdatedRows] = useState<TimelineStage[]>(props.rows);

    useEffect(() => {
        if (!props.stagesAreLoading) {
            const newRows = props.rows.map((row) => ({
                ...row,
                isInUse: props.stagesUsedLocally.includes(row.id)
                    ? true
                    : row.isInUse,
            }));

            setUpdatedRows(newRows);
        }
    }, [props.stagesUsedLocally, props.rows, props.stagesAreLoading]);

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Name',
            flex: 2,
            minWidth: 100,
            editable: false,
            resizable: false,
        },
        {
            field: 'isInUse',
            headerName: 'Status',
            flex: 1,
            minWidth: 100,
            editable: false,
            resizable: false,
            renderCell: (params: any) => (
                <div
                    className={params.value ? 'text-success ' : 'text-danger'}
                    style={{ fontWeight: 700 }}>
                    {params.value ? 'Used' : 'Unused'}
                </div>
            ),
        },
        {
            field: 'action',
            headerName: 'Actions',
            flex: 0,
            minWidth: 70,
            resizable: false,
            sortable: false,
            renderCell: (params: any) => (
                <div className="action-cell">
                    <div className="d-flex justify-content-start align-items-center">
                        <FontAwesomeIcon
                            onClick={() => {
                                props.handleStageToUpdate(params.row.id);
                            }}
                            className={`${'cursor-pointer'}`}
                            size="lg"
                            icon={faPenToSquare}
                        />
                        <FontAwesomeIcon
                            onClick={() => {
                                props.handleStageToRemove(params.row.id);
                            }}
                            className={`${'cursor-pointer ml-4'}`}
                            size="lg"
                            icon={faTrash}
                        />
                    </div>
                </div>
            ),
        },
    ];

    const additionalStyles = {
        border: 'none',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        '.MuiDataGrid-scrollbar': {
            display: 'none',
        },
        '.MuiDataGrid-row': {
            borderBottom: 'none',
        },
        '.MuiDataGrid-columnHeader:focus': {
            outline: 'none',
        },
        '.MuiDataGrid-cell:focus': {
            outline: 'none',
        },
    };

    const getRowClassName = (params: any) => {
        const stageId = params.row.id;
        return params.row.isInUse ? 'disabled-row' : '';
    };

    return (
        <Box
            sx={{ width: '100%', overflow: 'auto', scrollbarWidth: 'none' }}
            className="stages-table">
            <DataGrid
                disableColumnMenu
                disableColumnFilter
                disableColumnSelector
                disableColumnResize
                sx={additionalStyles}
                columns={columns}
                rows={updatedRows}
                loading={props.stagesAreLoading}
                getRowClassName={getRowClassName}
            />
        </Box>
    );
};

export default StagesTable;
