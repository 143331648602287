import React, { useRef, useState } from 'react';
import './LinkActionPopup.scss';
import Rodal from '../../../components/Rodal/Rodal';
import { Col, ModalBody, ModalHeader, Row } from 'reactstrap';
import { TimelineParty, TimelinePartyList } from '../Model/TimelineParty';
import ActionDefinitionForm from '../ActionDefinitionForm/ActionDefinitionForm';
import { TimelineAction } from '../Model/TimelineAction';

interface LinkActionPopupProps {
    close(): void;
    mode: LinkActionPopupMode;
    party: TimelineParty;
    linkedAction?: TimelineAction;
    linkAction(newAction: TimelineAction): void;
}

export type LinkActionPopupMode = 'create' | 'preview' | 'edit';

const LinkActionPopup = (props: LinkActionPopupProps) => {
    const [mode, setMode] = useState<LinkActionPopupMode>(props.mode);

    const getPartyName = () =>
        TimelinePartyList.find((f) => f.id === props.party).name;

    const formRef = useRef<any>();

    const renderHeaderText = () => {
        switch (mode) {
            case 'create':
                return 'Link an Action';
            case 'preview':
                return 'Action Preview';
            case 'edit':
                return 'Edit Linked Action';
        }
    };

    return (
        <div className="link-action-popup">
            <Rodal
                visible={true}
                onClose={props.close}
                width={600}
                animation={'fade'}
                showMask={true}
                centered>
                <ModalHeader>{renderHeaderText()}</ModalHeader>
                <ModalBody>
                    <Col className="sections-container">
                        <Row className="party-section">
                            <div>
                                <strong>{'Party: '}</strong>
                                {getPartyName()}
                            </div>
                        </Row>
                        <Row className="action-type-section">
                            <div className="action-type-section-header">
                                {'Action Type'}
                            </div>
                            <ActionDefinitionForm
                                party={props.party}
                                ref={formRef}
                                onSubmit={props.linkAction}
                                disabled={mode === 'preview'}
                                linkedAction={props.linkedAction}
                            />
                        </Row>
                    </Col>
                    <div className="buttons-wrapper">
                        <button
                            disabled={false}
                            className="btn btn-outline-secondary"
                            onClick={props.close}>
                            {'Cancel'}
                        </button>
                        {mode === 'create' || mode === 'edit' ? (
                            <button
                                disabled={false}
                                onClick={() => {
                                    formRef?.current?.submit();
                                }}
                                className={'btn btn-primary'}>
                                {'Link Action'}
                            </button>
                        ) : (
                            <></>
                        )}
                        {mode === 'preview' ? (
                            <>
                                <button
                                    disabled={false}
                                    onClick={() => {
                                        setMode('edit');
                                    }}
                                    className={'btn btn-primary'}>
                                    {'Edit Action'}
                                </button>
                                <button
                                    disabled={false}
                                    onClick={() => props.linkAction(null)}
                                    className={'btn btn-primary'}>
                                    {'Delete'}
                                </button>
                            </>
                        ) : (
                            <></>
                        )}
                    </div>
                </ModalBody>
            </Rodal>
        </div>
    );
};

export default LinkActionPopup;
