import { createAsyncThunk } from '@reduxjs/toolkit';
import { DataRequestNotificationsState } from '../data-request-notifications.state';
import Answer from '../../Answer';
import {
    updateQuestionAnswer,
    mapNotificationsForAppearedQuestions,
} from '../data-forms-status-mapper';
import { ResetQuestionInfo } from '../data-request-notifications.slice';
import { updateTabsState } from '../project-tabs-status-mapper';

export interface AddQuestionAnswerArguments {
    answer: Answer;
    questionInfo: ResetQuestionInfo;
}

export const addQuestionAnswer = createAsyncThunk(
    'dataRequestNotifications/addQuestionAnswer',
    async (args: AddQuestionAnswerArguments, dispatch: any) => {
        const notificationsState =
            dispatch.getState().dataRequestsNotifications;
        const result = await addAnswer(
            args.answer,
            args.questionInfo,
            notificationsState
        );

        return result;
    }
);

const addAnswer = (
    answer: Answer,
    questionInfo: ResetQuestionInfo,
    state: DataRequestNotificationsState
) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            const updatedState = { ...state };

            let updatedQuestions = updateQuestionAnswer(
                updatedState.questionsSeenStatus,
                answer,
                questionInfo
            );
            const changedQuestion = updatedQuestions.find(
                (question) =>
                    question.id === questionInfo.questionId &&
                    question.isSubQuestion === questionInfo.isSubquestion
            );
            updatedQuestions = mapNotificationsForAppearedQuestions(
                updatedQuestions,
                changedQuestion
            );

            updatedState.questionsSeenStatus = updatedQuestions;
            updateTabsState(
                updatedState,
                updatedQuestions,
                updatedState.tableCellsSeenStatus,
                updatedState.tablesNotificationsStatus
            );
            resolve(updatedState);
        });
    });
};
