export enum SystemVariableNames {
    LikelySource,
    DisplayFormat,
    Frequency,
    TextSize,
    AnswerType,
    NumericFormat,
    FormType,
    Priority,
    DataTableViewLinksVariable,
}

export const SystemVariableNamesList = [
    { id: 0, name: 'Likely Source' },
    { id: 1, name: 'Display Format' },
    { id: 2, name: 'Frequency' },
    { id: 3, name: 'Text Size' },
    { id: 4, name: 'Answer Type' },
    { id: 5, name: 'Numeric Format' },
    { id: 6, name: 'Form Type' },
    { id: 7, name: 'Priority' },
    { id: 8, name: 'DataTableViewLinksVariable' },
];

export enum NumericFormatVariable {
    Accounting,
    Percent,
    Decimal,
    Integer,
}

export const NumericFormatVariableNamesList = [
    { id: 0, name: 'Accounting' },
    { id: 1, name: 'Percent' },
    { id: 2, name: 'Decimal' },
    { id: 3, name: 'Integer' },
];

export enum FormTypeVariable {
    YesNo,
    DropDown,
}

export const FormTypeVariableNamesList = [
    { id: 0, name: 'YesNo' },
    { id: 1, name: 'DropDown' },
];

export enum AnswerTypeVariable {
    Text,
    PickFromList,
    Number,
    Date,
}

export const AnswerTypeVariableNamesList = [
    { id: 0, name: 'Text' },
    { id: 1, name: 'Pick from list' },
    { id: 2, name: 'Number' },
    { id: 3, name: 'Date' },
];

export const YesNoDropdownOptionsList = [
    { id: 0, name: 'No' },
    { id: 1, name: 'Yes' },
];

export interface ProjectVariable {
    id: number;
    name: string;
    options: Array<ProjectVariableOption>;
}

export interface ProjectVariableOption {
    id: number;
    name: string;
    option: string;
}
