function onClick(item) {
    window.alert(JSON.stringify(item, null, 2));
}

// New Links
export const ApprovalNav = [
    {
        icon: 'pe-7s-display2',
        label: 'Approval Queues',
        to: '/dashboard',
        isVisible: true,
        isDisabled: false,
        perCode: [],
        onSelected: onClick,
    },
];

export const DashboardNav = [
    {
        icon: 'pe-7s-display2',
        label: 'Dashboard',
        to: '/dashboard',
        isVisible: true,
        isDisabled: false,
        perCode: [],
        onSelected: onClick,
    },
];

export const CharityApprovalNav = [
    {
        icon: 'pe-7s-display2',
        label: 'Nonprofit Management',
        to: '/charityapproval',
        isVisible: true,
        isDisabled: false,
        perCode: [],
        onSelected: onClick,
    },
];

export const ApprovalSubNav = [
    {
        icon: 'pe-7s-display2',
        label: 'Practice Approval',
        to: '/practicerequests',
        isVisible: true,
        isDisabled: false,
        perCode: [],
    },
    {
        icon: 'pe-7s-display2',
        label: 'Referral Partner Approval',
        to: '/referralrequests',
        isVisible: true,
        isDisabled: false,
        perCode: [],
    },
    {
        icon: 'pe-7s-plugin',
        label: 'W-9 Update Approval',
        to: '/w-9-Update-Approval',
        isVisible: true,
        isDisabled: false,
        perCode: [],
    },
    {
        icon: 'pe-7s-plugin',
        label: 'Payment Request Status and Update',
        to: '/payment-request-status',
        isVisible: true,
        isDisabled: false,
        perCode: [],
    },
    {
        icon: 'pe-7s-plugin',
        label: 'New Nonprofit Approval',
        to: 'new-charity-approval',
        isVisible: true,
        isDisabled: false,
        perCode: [],
    },
    {
        icon: 'pe-7s-display2',
        label: 'Valuation Report Approval',
        to: '/detailedreports',
        isVisible: true,
        isDisabled: false,
        perCode: [],
    },
];

export const MyResourcesNav = [
    {
        icon: 'pe-7s-plugin',
        label: 'My Resources',
        to: 'my-resources',
        isVisible: true,
        isDisabled: false,
        perCode: [],
        onSelected: onClick,
    },
];

export const ReferralNav = [
    {
        icon: 'pe-7s-users',
        label: 'Referral',
        to: '#',
        isVisible: true,
        isDisabled: false,
        perCode: [],
        onSelected: onClick,
    },
];

export const AssociateMembersNav = [
    {
        icon: 'pe-7s-add-user',
        label: 'My Affiliated Members',
        isVisible: true,
        isDisabled: false,
        to: '/associates',
        perCode: [],
    },
];

export const MemberList = [
    {
        icon: 'pe-7s-add-user',
        label: 'Member List',
        isVisible: true,
        isDisabled: false,
        to: '/associates',
        perCode: [],
    },
];

export const StandardizationSevices = [
    {
        icon: 'pe-7s-refresh-2',
        label: 'Standardizations',
        isVisible: true,
        isDisabled: false,
        to: '/standardization-sevices',
        perCode: [],
    },
];

export const AnnouncementList = [
    {
        icon: 'pe-7s-speaker',
        label: 'Announcements Manager',
        isVisible: true,
        isDisabled: false,
        to: '/announcements',
        perCode: [],
    },
];

export const KnowledgeBaseContentManager = [
    {
        icon: 'pe-7s-note2',
        label: 'Knowledge Library Content Manager',
        isVisible: true,
        isDisabled: false,
        to: '/admin/knowledge-library',
        perCode: [],
    },
];

export const AssociateMembersSubNav = [
    {
        icon: 'pe-7s-id',
        label: 'My Account',
        isVisible: true,
        isDisabled: false,
        to: '/profile',
        perCode: [],
    },
];

export const NotificationsNav = [
    {
        icon: 'pe-7s-bell',
        label: 'Notification Settings',
        isVisible: true,
        isDisabled: false,
        to: '/notifications',
        perCode: [],
    },
];

export const AuditLogsNav = [
    {
        icon: 'pe-7s-news-paper',
        label: 'Audit Logs',
        isVisible: true,
        isDisabled: false,
        to: '/audit-logs',
        perCode: [],
    },
];

export const AdminNotificationSettings = [
    {
        icon: 'pe-7s-bell',
        label: 'Notifications',
        to: '/admin/notifications',
        isVisible: true,
        isDisabled: false,
        classNameItem: 'custom',
        perCode: [],
    },
];

export const SupportPortalNav = [
    {
        icon: 'pe-7s-chat',
        label: 'Support Requests',
        isVisible: true,
        isDisabled: false,
        to: '/admin/support-requests',
        perCode: [],
    },
];

export const AutomatedServicesInputsNav = [
    {
        icon: 'pe-7s-edit',
        label: 'Automated Services Inputs',
        isVisible: true,
        isDisabled: false,
        to: '/admin/controls',
        perCode: [],
    },
];

export const ProductPricingDiscountsNav = [
    {
        icon: 'pe-7s-cash',
        label: 'Pricing, Discounts, Rebates, and Referral Fees',
        to: '/admin/pricing',
        isVisible: true,
        isDisabled: true,
        classNameItem: 'custom',
        perCode: [],
    },
];

export const ProductPricingDiscountsSubNav = [
    {
        icon: 'pe-7s-cash',
        label: 'Discount code discount because of 1st purchase',
        to: 'discount-code',
        isVisible: true,
        isDisabled: true,
        classNameItem: 'custom',
        perCode: [],
    },
    {
        icon: 'pe-7s-cash',
        label: 'Annual Discount by product / practice',
        to: 'annual-discount',
        isVisible: true,
        isDisabled: true,
        classNameItem: 'custom',
        perCode: [],
    },
    {
        icon: 'pe-7s-cash',
        label: 'Volume Discount / Credits',
        to: 'volume-discount',
        isVisible: true,
        isDisabled: true,
        classNameItem: 'custom',
        perCode: [],
    },
];

export const MemberServicesManagementNav = [
    {
        icon: 'pe-7s-graph2',
        label: 'Valuation Report Input Forms',
        to: '/admin/paidreport-from-updates',
        isVisible: true,
        isDisabled: false,
        perCode: [],
    },
];

export const MemberServicesManagementSubNav = [
    {
        icon: 'pe-7s-graph2',
        label: 'Paid report form updates',
        to: 'paidreport-from-updates',
        isVisible: true,
        isDisabled: false,
        perCode: [],
    },
    {
        icon: 'pe-7s-graph1',
        label: 'CAPVAR',
        to: 'detailedreports',
        isVisible: true,
        isDisabled: false,
        perCode: [],
    },
];

export const SubscriptionsNav = [
    {
        icon: 'pe-7s-bell',
        label: 'Subscriptions',
        to: '/subscriptions',
        isVisible: true,
        isDisabled: false,
        perCode: [],
    },
];

export const UploadNav = [
    {
        icon: 'pe-7s-bell',
        label: 'Upload Chart',
        to: '/admin/upload-chart',
        isVisible: true,
        isDisabled: false,
        perCode: [],
    },
];

export const PracticesNav = [
    {
        icon: 'pe-7s-rocket',
        label: 'Dashboard',
        to: '/dashboard',
        isVisible: true,
        isDisabled: false,
        perCode: [],
    },
];

export const ReportsNav = [
    {
        icon: 'pe-7s-rocket',
        label: 'Free & Easy Valuation',
        to: '/report/free',
        isVisible: true,
        isDisabled: false,
        perCode: ['GENERATE_F_E', 'VIEW_F_E'],
    },
    {
        icon: 'pe-7s-rocket',
        label: 'Detailed Valuation',
        to: '/report/evaluation',
        isVisible: true,
        isDisabled: false,
        perCode: [
            'VIEW_PAID_REPORT',
            'GENERATE_PAID_REPORT',
            'SAVE_PAID_REPORT_DRAFT',
        ],
    },
    {
        icon: 'pe-7s-rocket',
        label: 'Sample Reports',
        to: '/report/samples',
        isVisible: true,
        isDisabled: false,
        perCode: ['VIEW_SAMPLE_REPORTS'],
    },
];

export const SettingsNav = [
    {
        icon: 'pe-7s-rocket',
        label: 'Admin',
        to: '/admin/controls',
        isVisible: false,
        isDisabled: false,
        classNameItem: 'custom',
        perCode: ['VIEW_CONTROLS', 'EDIT_CONTROLS'],
    },
    {
        icon: 'pe-7s-rocket',
        label: 'Upload Chart',
        to: '/admin/upload-chart',
        isVisible: true,
        isDisabled: true,
        classNameItem: 'custom',
        perCode: ['UPLOAD_CHART'],
    },
    {
        icon: 'pe-7s-rocket',
        label: 'Permissions',
        to: '/admin/permissions',
        isVisible: true,
        isDisabled: false,
        classNameItem: 'custom',
        perCode: [],
    },
];

export const PrimaryOwnerSettingsNav = [
    {
        icon: 'pe-7s-rocket',
        label: 'Permissions',
        to: '/admin/permissions',
        isVisible: true,
        isDisabled: false,
        classNameItem: 'custom',
        perCode: [],
    },
];

export const ReferalNav = [
    {
        icon: 'pe-7s-share',
        label: 'My Referral Hub',
        isVisible: true,
        isDisabled: false,
        to: '/my-referrals',
        perCode: [],
    },
];

export const DataRequestNav = [
    {
        icon: 'pe-7s-share',
        label: 'Data Request',
        isVisible: true,
        isDisabled: false,
        to: '/data-request-dashboard/others',
        perCode: [],
    },
];

export const DataAndDocumentRequestsNav = [
    {
        icon: 'pe-7s-share',
        label: 'Data & Docs Requests',
        isVisible: true,
        isDisabled: false,
        to: '/data-and-document-requests-dashboard',
        perCode: [],
    },
];

export const DataAndDocumentTemplatesNav = [
    {
        icon: 'pe-7s-share',
        label: 'Data & Docs Templates',
        isVisible: true,
        isDisabled: false,
        to: '/data-and-document-templates-dashboard',
        perCode: [],
    },
];

export const AdminReferalNav = [
    {
        icon: 'pe-7s-id',
        label: 'My Referral Hub',
        isVisible: true,
        isDisabled: false,
        to: '/#',
        perCode: [],
    },
];

export const AdminMyReferalNav = [
    {
        icon: 'pe-7s-id',
        label: 'My Referral Hub',
        isVisible: true,
        isDisabled: false,
        to: '/my-referrals',
        perCode: [],
    },
];

export const MyPracticesNav = [
    {
        icon: 'pe-7s-home',
        label: 'My Practices',
        isVisible: true,
        isDisabled: false,
        to: '/practices',
        perCode: [],
    },
];

export const MyCharitiesNav = [
    {
        icon: 'pe-7s-network',
        label: 'My Nonprofits',
        isVisible: true,
        isDisabled: false,
        to: '/charities',
        perCode: [],
    },
];

export const ResourcesNav = [
    {
        icon: 'pe-7s-rocket',
        label: 'Sample Reports',
        to: '/samples',
        perCode: [],
    },
    {
        icon: 'pe-7s-rocket',
        label: 'Valuations Demos',
        to: '/demos',
        perCode: [],
    },
    {
        icon: 'pe-7s-rocket',
        label: 'Knowledge Library',
        to: '/knowledge-library',
        perCode: [],
    },
    {
        icon: 'pe-7s-rocket',
        label: 'PMG',
        to: '/practice-management-groups',
        perCode: [],
    },
    {
        icon: 'pe-7s-users',
        label: 'PMG1',
        to: '/practice-management-pmg',
        perCode: [],
    },
];

export const AssociateResourcesNav = [
    {
        icon: 'pe-7s-rocket',
        label: 'Sample Reports',
        to: '/samples',
        perCode: [],
    },
    {
        icon: 'pe-7s-rocket',
        label: 'Valuations Demos',
        to: '/demos',
        perCode: [],
    },
];

export const MemberAdminNav = [
    {
        icon: 'pe-7s-rocket',
        label: 'Member Profile',
        isVisible: true,
        isDisabled: false,
        to: '/profile',
        perCode: [],
    },
    {
        icon: 'pe-7s-rocket',
        label: 'Affiliated Members',
        isVisible: true,
        isDisabled: false,
        to: '/associates',
        perCode: [],
    },
];

export const SecondaryMemberAdminNav = [
    {
        icon: 'pe-7s-rocket',
        label: 'Member Profile',
        isVisible: true,
        isDisabled: false,
        to: '/profile',
        perCode: [],
    },
];

export const AdminSettingsNav = [
    {
        icon: 'pe-7s-rocket',
        label: 'Automated Services Inputs',
        to: '/admin/controls',
        isVisible: false,
        isDisabled: false,
        classNameItem: 'custom',
        perCode: [],
    },
    {
        icon: 'pe-7s-rocket',
        label: 'Pricing, Discounts, Rebates, and Referral Fees',
        to: '/admin/pricing',
        isVisible: false,
        isDisabled: false,
        classNameItem: 'custom',
        perCode: [],
    },
    {
        icon: 'pe-7s-rocket',
        label: 'Subscriptions',
        to: '/demos',
        isVisible: false,
        isDisabled: false,
        classNameItem: 'custom',
        perCode: [],
    },
    {
        icon: 'pe-7s-rocket',
        label: 'Upload Chart',
        to: '/admin/upload-chart',
        isVisible: true,
        isDisabled: true,
        classNameItem: 'custom',
        perCode: [],
    },
];

// Practice Owner menu Item
export const HomeNav = [
    {
        icon: 'pe-7s-display2',
        label: 'Home',
        isVisible: true,
        isDisabled: false,
        to: '/user-home',
        perCode: [],
    },
];

export const ReferralHomeNav = [
    {
        icon: 'pe-7s-display2',
        label: 'Ecosystem Homepage',
        isVisible: true,
        isDisabled: false,
        to: '/user-home',
        perCode: [],
    },
];

export const MyGroupNav = [
    {
        icon: 'pe-7s-users',
        label: 'My Groups & Resources',
        to: '/admin-my-groups',
        isVisible: true,
        isDisabled: false,
        perCode: [],
    },
];

export const MyGroupNavAssociate = [
    {
        icon: 'pe-7s-users',
        label: 'My Groups & Resources',
        isVisible: true,
        isDisabled: false,
        perCode: [],
    },
];

export const PMGAdmin = [
    {
        icon: 'pe-7s-users',
        label: 'Practice Management Groups',
        to: '/pmg-admin',
        isVisible: true,
        isDisabled: false,
        perCode: [],
    },
];

export const CGMAdmin = [
    {
        icon: 'pe-7s-portfolio',
        label: 'Corporate Group Management',
        to: '/cgm-admin',
        isVisible: true,
        isDisabled: false,
        perCode: [],
    },
];

export const PBSAdmin = [
    {
        icon: 'pe-7s-safe',
        label: 'Practice Brokerage Services',
        to: '/practice-brokerage-services',
        isVisible: true,
        isDisabled: false,
        perCode: [],
    },
];

export const MyGroupAdmin = [
    {
        icon: 'pe-7s-safe',
        label: 'My Groups & Resources',
        to: '/admin-my-groups',
        isVisible: true,
        isDisabled: false,
        perCode: [],
    },
];

export const MarketplaceAdmin = [
    {
        icon: 'pe-7s-plugin',
        label: 'Marketplace Admin',
        to: '/marketplace/admin',
        isVisible: true,
        isDisabled: false,
        perCode: []
    },
]
