import React from 'react';

interface GanttChartHeaderItem {
    left: number;
    width: number;
    label: string | number;
    styles?: any;
}
export const GanttChartHeaderItem = (props: GanttChartHeaderItem) => {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                height: 20,
                left: props.left,
                width: props.width,
                ...props.styles,
            }}>
            <div>{props.label}</div>
        </div>
    );
};
