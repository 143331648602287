import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';
import EditableTable from '../../../../../components/EditableTable/EditableTable';
import {
    GridColDef,
    GridEditDateCell,
    GridEditSingleSelectCell,
    GridPreProcessEditCellProps,
    GridRowId,
    GridValidRowModel,
    GridValueOptionsParams,
    useGridApiRef,
} from '@mui/x-data-grid';
import {
    AnswerTypeVariable,
    AnswerTypeVariableNamesList,
    FormTypeVariable,
    FormTypeVariableNamesList,
    NumericFormatVariableNamesList,
    ProjectVariable,
    YesNoDropdownOptionsList,
} from '../../../../../models/DataRequestHub/ProjectVariable';
import TextInputColumn from '../../../../../components/EditableTable/CustomColumns/TextInputColumn/TextInputColumn';
import DataFormQuestion from '../../../../../models/DataRequestHub/DataFormQuestion';
import {
    validateGridEditorRowDisplayOrder,
    validateDisplayTextSize,
    validateFormList,
    validateFormType,
    validateRequiredCell,
    validateSubQuestionDisplayOrder,
    removeErrorClassFromCellContent,
    validateDateAndAdjustClasses,
    validateConditionValueCell,
} from '../../../../../components/EditableTable/EditableTableValidationHelper';
import SubQuestionSwitcherColumn from '../../../../../components/EditableTable/CustomColumns/SubQuestionSwitcherColumn';
import moment from 'moment';
import DisplayLogicSwitcherColumn from '../../../../../components/EditableTable/CustomColumns/DisplayLogicSwitcherColumn';
import { CustomIdConstants } from '../../../../../models/DataRequestHub/CustomIdConstants';
import { TimeZone, TimeZoneHelper } from '../../../../../utils/timeZoneHelper';
import { EditorTypeEnum } from '../../../../../models/DataRequestHub/ProjectEditorEnum';
import { NumericCell } from '../../../../DataRequest/DataTableForm/NumericCell/NumericCell';
import { Box } from '@mui/material';
import {
    MultiSelectEditCell,
    MultiSelectOption,
} from '../../../../../components/EditableTable/CustomColumns/MultiselectInputColumn/MultiselectInputColumn';
import { ConditionValue } from '../../../../../models/DataRequestHub/ItemDisplayCondition';
import PortalTooltip from '../../../../../components/PortalTooltip/PortalTooltip';
import CustomRenderCell from '../../../../../components/EditableTable/CustomRenderCell/CustomRenderCell';

interface QuestionnaireTemplateEditorInterface {
    templateRows: DataFormQuestion[];
    setTemplateRows(rows: DataFormQuestion[]): void;
    isLoading: boolean;
    answerType: ProjectVariable;
    priority: ProjectVariable;
    textSize: ProjectVariable;
    formType: ProjectVariable;
    numericFormat: ProjectVariable;
    notSystemVariables: ProjectVariable[];
    isProjectEditMode: boolean;
    dataTableViewLinksVariable: ProjectVariable;
    availableDataTableViewLinksVariable: ProjectVariable;
}

const QuestionnaireTemplateEditor = forwardRef(
    (props: QuestionnaireTemplateEditorInterface, ref) => {
        const apiRef = useGridApiRef();
        const {
            templateRows,
            setTemplateRows,
            answerType,
            priority,
            isProjectEditMode,
            textSize,
            formType,
            numericFormat,
            isLoading,
            notSystemVariables,
            dataTableViewLinksVariable,
            availableDataTableViewLinksVariable,
        } = props;

        const generateDuePeriodOptions = () => {
            const options = [];
            options.push('');
            for (let i = 1; i <= 365; i++) {
                options.push(`${i} day${i > 1 ? 's' : ''}`);
            }
            return options;
        };
        const [timeZone] = useState<TimeZone>(
            TimeZoneHelper.getTimeZoneByDate(new Date())
        );
        const getParentQuestionsIds = () =>
            props.templateRows
                .filter((question) => !question.isSubQuestion)
                .map((question) => question.customQuestionId);

        const getlogicParentCustomId = (currentQuestion: DataFormQuestion) => {
            const currentQuestionCustomId = currentQuestion.customQuestionId;
            const customIdsList = props.templateRows.map(
                (question) => question.customQuestionId
            );

            const currentCustomIdIndex = customIdsList.indexOf(
                currentQuestionCustomId
            );
            customIdsList.splice(currentCustomIdIndex, 1);

            return customIdsList;
        };

        const gridTableRef = useRef(null);
        useImperativeHandle(ref, () => ({
            addRow() {
                gridTableRef.current.addRow();
            },
            validateAllRows() {
                return validateAllRows();
            },
            getEditorState() {
                return apiRef.current.state;
            },
        }));

        const getDueDateOrPeriodColumn = (): GridColDef => {
            return isProjectEditMode
                ? {
                      field: 'dueDate',
                      headerName: 'Due Date',
                      minWidth: 150,
                      flex: 0.5,
                      cellClassName: (params) => {
                          let cellClass = 'date-picker due-date';

                          if (params.row.isSubQuestion) {
                              cellClass = `${cellClass} disabled`;
                          }

                          return cellClass;
                      },
                      editable: true,
                      type: 'date',
                      renderCell: (params) => {
                          const dueDateCellId = `due-date-cell-${params.id}`;

                          return (
                              <CustomRenderCell
                                  {...params}
                                  anchorTooltipId={dueDateCellId}
                                  renderValue={params.formattedValue}
                              />
                          );
                      },
                      renderEditCell: (params) => {
                          return <GridEditDateCell {...params} />;
                      },
                      valueFormatter: (value: any) =>
                          value ? moment(value).format('L') : null,
                      valueSetter: (value, row) => {
                          if (value && !isNaN(Date.parse(value))) {
                              const currentValue = row.dueDate;
                              const parsedValue = new Date(value);
                              if (
                                  currentValue &&
                                  parsedValue.toISOString() === currentValue
                              ) {
                                  return row;
                              }
                              const valueWithTImeZoneOffset =
                                  TimeZoneHelper.parseUtcDate(
                                      new Date(value),
                                      timeZone
                                  );
                              return {
                                  ...row,
                                  dueDate:
                                      valueWithTImeZoneOffset?.toISOString() ??
                                      value,
                              };
                          }
                          return {
                              ...row,
                              dueDate: null,
                          };
                      },
                  }
                : {
                      field: 'duePeriod',
                      headerName: 'Due Period',
                      minWidth: 150,
                      flex: 0.5,
                      cellClassName: (params) => {
                          let cellClass = 'cell-text-input due-period';

                          if (params.row.isSubQuestion) {
                              cellClass = `${cellClass} disabled`;
                          }

                          return cellClass;
                      },
                      type: 'singleSelect',
                      valueOptions: generateDuePeriodOptions(),
                      editable: true,
                      renderCell: (params) => {
                          const duePeriodCellId = `due-period-cell-${params.id}`;

                          return (
                              <CustomRenderCell
                                  {...params}
                                  anchorTooltipId={duePeriodCellId}
                                  renderValue={params.value}
                              />
                          );
                      },
                  };
        };

        const getQuestionGridModelByCustomId = (
            customQuestionId: string
        ): DataFormQuestion => {
            const latestStateMap = apiRef.current.getRowModels();
            const latestStateArray = Array.from(
                latestStateMap.values()
            ) as DataFormQuestion[];
            return latestStateArray?.find(
                (questionModel) =>
                    questionModel.customQuestionId === customQuestionId
            );
        };

        const getConditionValueOptions = (
            currentQuestion: DataFormQuestion
        ): string[] => {
            const refQuestion = getQuestionGridModelByCustomId(
                currentQuestion.logicParentCustomId
            );

            const formType = FormTypeVariableNamesList.find(
                (f) => f.name === refQuestion?.formType
            );

            if (formType?.id === FormTypeVariable.YesNo) {
                return YesNoDropdownOptionsList.map((m) => m.name).reverse();
            }
            if (
                formType?.id === FormTypeVariable.DropDown &&
                refQuestion?.formList
            ) {
                const variableOptions =
                    notSystemVariables.find(
                        (variable) => variable.name === refQuestion?.formList
                    )?.options ?? [];
                return variableOptions?.map((option) => option.option);
            }

            return [];
        };

        const handleConditionValueDropdownChange = async (
            newValue: string,
            oldRowId: GridRowId
        ) => {
            await apiRef.current.setEditCellValue({
                id: oldRowId,
                field: 'conditionValues',
                value: newValue,
            });
        };

        const handleReferenceQuestionAnswerTypeChange = async (
            newValue: string,
            field: string,
            currentRowId: GridRowId,
            current: DataFormQuestion
        ) => {
            await apiRef.current.setEditCellValue({
                id: currentRowId,
                field: field,
                value: newValue,
            });

            removeConditionByReferenceId(current.customQuestionId);
        };

        const handleReferencedQuestionIdChange = (customQuestionId: string) => {
            const latestStateMap = apiRef.current.getRowModels();
            const latestStateArray = Array.from(latestStateMap.values());

            const updatedStateArray = latestStateArray.map((row) => {
                const question = row as DataFormQuestion;
                if (question.customQuestionId === customQuestionId) {
                    return {
                        ...row,
                        conditionValues: [],
                    };
                }

                return row;
            });

            updateRows(() => updatedStateArray as DataFormQuestion[]);
        };

        const removeConditionByReferenceId = (customQuestionId: string) => {
            const latestStateMap = apiRef.current.getRowModels();
            const latestStateArray = Array.from(latestStateMap.values());

            const updatedStateArray = latestStateArray.map((row) => {
                const question = row as DataFormQuestion;
                if (question.logicParentCustomId === customQuestionId) {
                    return {
                        ...row,
                        conditionValues: [],
                    };
                }

                return row;
            });

            updateRows(() => updatedStateArray as DataFormQuestion[]);
        };

        const isParentAnswerDescribed = (question: DataFormQuestion) => {
            const parentQuestion = getQuestionGridModelByCustomId(
                question?.logicParentCustomId
            );

            if (!parentQuestion) {
                return false;
            }

            const definedTypes = AnswerTypeVariableNamesList.filter(
                (f) =>
                    f.id === AnswerTypeVariable.Date ||
                    f.id === AnswerTypeVariable.Text
            );

            const definedFormOptions = [
                ...FormTypeVariableNamesList.filter(
                    (f) => f.id === FormTypeVariable.YesNo
                ),
                ...NumericFormatVariableNamesList,
            ].map((definedFormTypeOption) => definedFormTypeOption.name);

            const isParentQuestionAnswerIdentified =
                parentQuestion?.formList ||
                definedFormOptions.some(
                    (formOption) => formOption === parentQuestion?.formType
                ) ||
                definedTypes.some(
                    (type) => type.name === parentQuestion?.answerType
                );

            return isParentQuestionAnswerIdentified;
        };

        const handleParentQuestionIdChange = async (
            newValue: string,
            currnetRowId: GridRowId,
            currentRow: DataFormQuestion
        ) => {
            await apiRef.current.setEditCellValue({
                id: currnetRowId,
                field: 'parentQuestionId',
                value: newValue,
            });
            apiRef.current.stopCellEditMode({
                id: currnetRowId,
                field: 'parentQuestionId',
            });
            setTimeout(() => {
                const latestStateMap = apiRef.current.getRowModels();
                const latestStateArray = Array.from(latestStateMap.values());

                const newSubquestionCustomId =
                    CustomIdConstants.getNexSubquestionWithParentCustomId(
                        latestStateArray as DataFormQuestion[],
                        newValue
                    );

                const updatedStateArray = latestStateArray.map((row) => {
                    const rowId = row.customQuestionId;

                    if (rowId === currnetRowId) {
                        return {
                            ...row,
                            customQuestionId: newSubquestionCustomId,
                        };
                    }

                    if (
                        row.logicParentCustomId &&
                        row.logicParentCustomId === currentRow.customQuestionId
                    ) {
                        return {
                            ...row,
                            logicParentCustomId: newSubquestionCustomId,
                        };
                    }

                    return row;
                });

                updateRows(() => updatedStateArray as DataFormQuestion[]);
            }, 100);
        };

        const getConditionalityValueOptions = (
            currentColumn: DataFormQuestion
        ): MultiSelectOption[] => {
            const variableOptions =
                notSystemVariables.find(
                    (variable) => variable.name === currentColumn?.formList
                )?.options ?? [];
            return variableOptions?.map((option, index) => ({
                data: option.option,
                id: index,
            }));
        };

        const getCellTooltip = (
            anchorId: string,
            content: React.JSX.Element
        ) => {
            return (
                <PortalTooltip
                    anchorId={anchorId}
                    delayShow={1000}
                    place="bottom"
                    content={content}
                />
            );
        };

        const columns = (): GridColDef[] => [
            {
                field: 'displayOrder',
                headerName: 'Display Order',
                minWidth: 140,
                flex: 0.25,
                type: 'number',
                cellClassName: (params) => {
                    let cellClass = 'cell-input display-order';

                    if (params.row.isSubQuestion) {
                        cellClass = `${cellClass} disabled`;
                    }

                    return cellClass;
                },
                editable: true,
                align: 'left',
                headerAlign: 'left',
                preProcessEditCellProps: (
                    params: GridPreProcessEditCellProps
                ) => {
                    const value = params.props.value;
                    const hasError = value <= 0;
                    return { ...params.props, error: hasError };
                },
                renderCell: (params) => {
                    const displayOrderCellId = `display-order-cell-${params.id}`;

                    return (
                        <CustomRenderCell
                            {...params}
                            anchorTooltipId={displayOrderCellId}
                            renderValue={params.value}
                        />
                    );
                },
            },
            {
                field: 'customQuestionId',
                headerName: 'Q-ID',
                minWidth: 150,
                flex: 0.5,
                cellClassName: 'cell-text-input custom-question-id',
                editable: false,
                renderCell: (params) => {
                    const customQuestionIdCellId = `custom-question-id-cell-${params.id}`;

                    return (
                        <CustomRenderCell
                            {...params}
                            anchorTooltipId={customQuestionIdCellId}
                            renderValue={params.value}
                        />
                    );
                },
                preProcessEditCellProps: (
                    params: GridPreProcessEditCellProps
                ) => {
                    return { ...params.props };
                },
                renderEditCell: (params) => <TextInputColumn {...params} />,
            },
            {
                field: 'displayModule',
                headerName: 'Display Module',
                minWidth: 150,
                flex: 0.5,
                cellClassName: (params) => {
                    let cellClass = 'cell-text-input display-module';

                    if (params.row.isSubQuestion) {
                        cellClass = `${cellClass} disabled`;
                    }

                    return cellClass;
                },
                editable: true,
                renderCell: (params) => {
                    const displayModuleCellId = `display-module-cell-${params.id}`;

                    return (
                        <CustomRenderCell
                            {...params}
                            anchorTooltipId={displayModuleCellId}
                            renderValue={params.value}
                        />
                    );
                },
                preProcessEditCellProps: (
                    params: GridPreProcessEditCellProps
                ) => {
                    if (params.hasChanged) {
                        const hasError = params.props.value?.trim().length <= 0;
                        return { ...params.props, error: hasError };
                    }

                    return { ...params.props };
                },
                renderEditCell: (params) => <TextInputColumn {...params} />,
            },
            {
                field: 'parentDisplayName',
                headerName: 'Parent Display Name',
                minWidth: 150,
                flex: 0.5,
                cellClassName: (params) => {
                    let cellClass = 'cell-text-input parent-display-name';

                    if (params.row.isSubQuestion) {
                        cellClass = `${cellClass} disabled`;
                    }

                    return cellClass;
                },
                editable: true,
                renderCell: (params) => {
                    const parentDisplayNameCellId = `parent-display-name-cell-${params.id}`;

                    return (
                        <CustomRenderCell
                            {...params}
                            anchorTooltipId={parentDisplayNameCellId}
                            renderValue={params.value}
                        />
                    );
                },
                renderEditCell: (params) => <TextInputColumn {...params} />,
            },
            {
                field: 'displayQuestion',
                headerName: 'Display Question',
                minWidth: 150,
                flex: 0.5,
                cellClassName: 'cell-text-input display-question',
                editable: true,
                renderCell: (params) => {
                    const displayQuestionCellId = `display-question-cell-${params.id}`;

                    return (
                        <CustomRenderCell
                            {...params}
                            anchorTooltipId={displayQuestionCellId}
                            renderValue={params.value}
                        />
                    );
                },
                preProcessEditCellProps: (
                    params: GridPreProcessEditCellProps
                ) => {
                    if (params.hasChanged) {
                        const hasError = params.props.value?.trim().length <= 0;
                        return { ...params.props, error: hasError };
                    }

                    return { ...params.props };
                },
                renderEditCell: (params) => <TextInputColumn {...params} />,
            },
            subQuestionSwitcherColumn,
            {
                field: 'parentQuestionId',
                headerName: 'Parent Q-ID',
                minWidth: 150,
                flex: 0.5,
                cellClassName: (params) => {
                    let cellClass = 'cell-text-input parent-question-id';

                    if (!params.row.isSubQuestion) {
                        cellClass = `${cellClass} disabled`;
                    }

                    return cellClass;
                },
                type: 'singleSelect',
                valueOptions: getParentQuestionsIds(),
                editable: true,
                preProcessEditCellProps: (
                    params: GridPreProcessEditCellProps
                ) => {
                    return { ...params.props };
                },
                renderCell: (params) => {
                    const parentQuestionIdCellId = `parent-question-id-cell-${params.id}`;

                    return (
                        <CustomRenderCell
                            {...params}
                            anchorTooltipId={parentQuestionIdCellId}
                            renderValue={params.value}
                        />
                    );
                },
                renderEditCell: (params) => (
                    <GridEditSingleSelectCell
                        onValueChange={(_, newValue) =>
                            handleParentQuestionIdChange(
                                newValue,
                                params.id,
                                params.row
                            )
                        }
                        {...params}
                    />
                ),
            },
            {
                field: 'displayOrderSub',
                headerName: 'Display Order Sub',
                minWidth: 140,
                flex: 0.25,
                type: 'number',
                cellClassName: (params) => {
                    let cellClass = 'cell-input display-order-sub';

                    if (!params.row.isSubQuestion) {
                        cellClass = `${cellClass} disabled`;
                    }

                    return cellClass;
                },
                editable: true,
                align: 'left',
                headerAlign: 'left',
                preProcessEditCellProps: (
                    params: GridPreProcessEditCellProps
                ) => {
                    const value = params.props.value;
                    const hasError = value <= 0;
                    return { ...params.props, error: hasError };
                },
                renderCell: (params) => {
                    const displayOrderSubCellId = `display-order-sub-cell-${params.id}`;

                    return (
                        <CustomRenderCell
                            {...params}
                            anchorTooltipId={displayOrderSubCellId}
                            renderValue={params.value}
                        />
                    );
                },
            },
            displayLogicSwitcherColumn,
            {
                field: 'logicParentCustomId',
                headerName: 'Reference Q-ID',
                minWidth: 150,
                flex: 0.5,
                cellClassName: (params) => {
                    let cellClass = 'cell-text-input logic-parent-question-id';

                    if (!params.row.isDisplayLogicEnabled) {
                        cellClass = `${cellClass} disabled`;
                    }

                    return cellClass;
                },
                type: 'singleSelect',
                valueOptions: (params) => getlogicParentCustomId(params.row),
                editable: true,
                preProcessEditCellProps: (
                    params: GridPreProcessEditCellProps
                ) => {
                    return { ...params.props };
                },
                renderCell: (params) => {
                    const logicParentCustomIdCellId = `logic-parent-custom-id-cell-${params.id}`;

                    return (
                        <CustomRenderCell
                            {...params}
                            anchorTooltipId={logicParentCustomIdCellId}
                            renderValue={params.value}
                        />
                    );
                },
                renderEditCell: (params) => (
                    <GridEditSingleSelectCell
                        onValueChange={() =>
                            handleReferencedQuestionIdChange(
                                params.row.customQuestionId
                            )
                        }
                        {...params}
                    />
                ),
            },
            {
                field: 'conditionValues',
                headerName: 'Condition Value',
                type: 'singleSelect',
                valueOptions: (params) => getConditionValueOptions(params.row),
                minWidth: 140,
                flex: 0.25,
                cellClassName: (params) => {
                    let cellClass = 'cell-text-input condition-value';

                    const isLogicEnabled = params.row.isDisplayLogicEnabled;
                    const hasParent = params.row.logicParentCustomId;
                    const isParentAnswerIdentified = isParentAnswerDescribed(
                        params.row
                    );
                    if (
                        !isLogicEnabled ||
                        !hasParent ||
                        !isParentAnswerIdentified
                    ) {
                        cellClass = `${cellClass} disabled`;
                    }

                    return cellClass;
                },
                editable: true,
                align: 'left',
                headerAlign: 'left',
                preProcessEditCellProps: (
                    params: GridPreProcessEditCellProps
                ) => {
                    if (
                        Object.prototype.toString.call(params.props.value) ===
                        '[object Date]'
                    ) {
                        const hasDateError = !!!params.props.value;
                        return { ...params.props, error: hasDateError };
                    }

                    if (Array.isArray(params.props.value)) {
                        const conditionValues = params.props
                            .value as ConditionValue[];
                        const hasArrayError = conditionValues.some(
                            (item) => item.data?.trim().length <= 0
                        );
                        return { ...params.props, error: hasArrayError };
                    }

                    const valueLenght = params.props.value?.trim().lenght;
                    const hasError = valueLenght <= 0;

                    return { ...params.props, error: hasError };
                },
                valueSetter: (value, row) => {
                    if (
                        Object.prototype.toString.call(value) ===
                        '[object Date]'
                    ) {
                        const valueWithTImeZoneOffset =
                            TimeZoneHelper.parseUtcDate(
                                new Date(value),
                                timeZone
                            );

                        const isDateValid = TimeZoneHelper.isDateValid(
                            valueWithTImeZoneOffset
                        );

                        const newConditionValue = [
                            {
                                id: 0,
                                data: isDateValid
                                    ? valueWithTImeZoneOffset.toISOString()
                                    : '',
                            },
                        ];

                        return {
                            ...row,
                            conditionValues: newConditionValue,
                        };
                    }

                    if (Array.isArray(value)) {
                        return {
                            ...row,
                            conditionValues: value,
                        };
                    }

                    return {
                        ...row,
                        conditionValues: [{ id: 0, data: value }],
                    };
                },
                valueFormatter: (value: any, params) => {
                    const question = params as DataFormQuestion;
                    const parentQuestion = getQuestionGridModelByCustomId(
                        question.logicParentCustomId
                    );
                    const parentFormType = FormTypeVariableNamesList.find(
                        (x) => x.name === parentQuestion?.formType
                    )?.id;
                    const parentAnswerType = AnswerTypeVariableNamesList.find(
                        (x) => x.name === parentQuestion?.answerType
                    )?.id;

                    if (
                        parentFormType !== FormTypeVariable.DropDown ||
                        parentAnswerType !== AnswerTypeVariable.PickFromList
                    ) {
                        return Array.isArray(value) ? value[0]?.data : value;
                    }

                    if (!Array.isArray(value)) {
                        return [{ id: 0, data: value }];
                    }

                    return value;
                },
                renderCell: (params) => {
                    const question = params.row as DataFormQuestion;
                    const parentQuestion = getQuestionGridModelByCustomId(
                        question.logicParentCustomId
                    );
                    const answerType = AnswerTypeVariableNamesList.find(
                        (type) => type.name === parentQuestion?.answerType
                    )?.id;

                    const conditionCellId = `form-condition-cell-${params.id}`;

                    switch (answerType) {
                        case AnswerTypeVariable.Number:
                            const numberConditionTooltipContent = (
                                <NumericCell
                                    isReadonly={true}
                                    formType={parentQuestion.formType}
                                    {...params}
                                    value={params.formattedValue}
                                />
                            );
                            return (
                                <Box className="MuiDataGrid-cellContent custom-render-cell">
                                    {params.formattedValue
                                        ? getCellTooltip(
                                              conditionCellId,
                                              numberConditionTooltipContent
                                          )
                                        : []}
                                    <NumericCell
                                        elementId={conditionCellId}
                                        isReadonly={true}
                                        formType={parentQuestion.formType}
                                        {...params}
                                        value={params.formattedValue}
                                    />
                                </Box>
                            );
                        case AnswerTypeVariable.Date:
                            const dateConditionTooltipContent = (
                                <div>
                                    {moment(params?.formattedValue).format('L')}
                                </div>
                            );
                            return (
                                <Box className="MuiDataGrid-cellContent custom-render-cell">
                                    {params?.formattedValue
                                        ? getCellTooltip(
                                              conditionCellId,
                                              dateConditionTooltipContent
                                          )
                                        : []}
                                    <div id={conditionCellId}>
                                        {params?.formattedValue
                                            ? moment(
                                                  params?.formattedValue
                                              ).format('L')
                                            : null}
                                    </div>
                                </Box>
                            );
                        case AnswerTypeVariable.PickFromList:
                            const stringArray = Array.isArray(
                                params.formattedValue
                            )
                                ? params.formattedValue?.map(
                                      (condition: ConditionValue) =>
                                          condition.data
                                  )
                                : [params.formattedValue];

                            const displayValue = stringArray.join(', ');

                            return (
                                <CustomRenderCell
                                    {...params}
                                    anchorTooltipId={conditionCellId}
                                    renderValue={displayValue}
                                />
                            );
                        default:
                            return (
                                <CustomRenderCell
                                    {...params}
                                    anchorTooltipId={conditionCellId}
                                    renderValue={params.formattedValue}
                                />
                            );
                    }
                },
                renderEditCell: (params) => {
                    const question = params.row as DataFormQuestion;
                    const parentQuestion = getQuestionGridModelByCustomId(
                        question.logicParentCustomId
                    );

                    const answerType = AnswerTypeVariableNamesList.find(
                        (type) => type.name === parentQuestion?.answerType
                    )?.id;

                    switch (answerType) {
                        case AnswerTypeVariable.Text:
                            return (
                                <TextInputColumn
                                    {...params}
                                    value={params.formattedValue}
                                />
                            );
                        case AnswerTypeVariable.Number:
                            return (
                                <div className="numeric-cell-edit">
                                    <NumericCell
                                        {...params}
                                        isReadonly={false}
                                        formType={parentQuestion.formType}
                                        value={params.formattedValue}
                                    />
                                </div>
                            );
                        case AnswerTypeVariable.PickFromList:
                            const parentFormType =
                                FormTypeVariableNamesList.find(
                                    (x) => x.name === parentQuestion.formType
                                )?.id;
                            if (parentFormType === FormTypeVariable.DropDown) {
                                return MultiSelectEditCell(
                                    { ...params, value: params.formattedValue },
                                    getConditionalityValueOptions(
                                        parentQuestion
                                    )
                                );
                            }
                            return (
                                <GridEditSingleSelectCell
                                    {...params}
                                    onValueChange={(_, newValue) => {
                                        handleConditionValueDropdownChange(
                                            newValue,
                                            params.id
                                        );
                                    }}
                                />
                            );
                        case AnswerTypeVariable.Date:
                            return (
                                <GridEditDateCell
                                    {...params}
                                    value={params.formattedValue}
                                />
                            );
                        default:
                            return <></>;
                    }
                },
            },
            {
                field: 'tableViewId',
                headerName: 'View Link',
                minWidth: 150,
                flex: 0.5,
                cellClassName: 'cell-text-input table-view-id',
                editable: true,
                type: 'singleSelect',
                valueOptions: availableDataTableViewLinksVariable?.options.map(
                    (x) => x.option
                ),
                valueFormatter: (value: string) => {
                    const tableViewOption =
                        dataTableViewLinksVariable?.options.find(
                            (x) => x.name == value
                        );

                    return tableViewOption?.option;
                },
                valueSetter: (value, row) => {
                    const tableViewOption =
                        dataTableViewLinksVariable?.options.find(
                            (x) => x.option == value
                        );

                    if (
                        !tableViewOption &&
                        !isNaN(value) &&
                        Number(value) > 0
                    ) {
                        return {
                            ...row,
                            tableViewId: value,
                        };
                    }
                    return {
                        ...row,
                        tableViewId: tableViewOption?.name,
                    };
                },
                renderCell: (params) => (
                    <div className="MuiDataGrid-cellContent">
                        {params.formattedValue}
                    </div>
                ),
                renderEditCell: (params) => {
                    return (
                        <GridEditSingleSelectCell
                            {...params}
                            value={params.formattedValue ?? params.value}
                        />
                    );
                },
            },
            {
                field: 'answerType',
                headerName: 'Answer Type',
                minWidth: 170,
                cellClassName: 'answer-type',
                flex: 0.5,
                type: 'singleSelect',
                valueOptions: answerType?.options.map((x) => x.option),
                editable: true,
                preProcessEditCellProps: (
                    params: GridPreProcessEditCellProps
                ) => {
                    const hasError = params.props.value?.length <= 0;
                    return { ...params.props, error: hasError };
                },
                renderEditCell: (params) => (
                    <GridEditSingleSelectCell
                        onValueChange={(_, newValue) =>
                            handleReferenceQuestionAnswerTypeChange(
                                newValue,
                                params.field,
                                params.id,
                                params.row
                            )
                        }
                        {...params}
                    />
                ),
                renderCell: (params) => {
                    const answerTypeIdCellId = `answer-type-cell-${params.id}`;

                    return (
                        <CustomRenderCell
                            {...params}
                            anchorTooltipId={answerTypeIdCellId}
                            renderValue={params.value}
                        />
                    );
                },
            },
            {
                field: 'displayTextSize',
                headerName: 'Text Size',
                minWidth: 170,
                cellClassName: 'display-text-size',
                flex: 0.5,
                type: 'singleSelect',
                valueOptions: textSize?.options.map((x) => x.name),
                editable: true,
                preProcessEditCellProps: (
                    params: GridPreProcessEditCellProps
                ) => {
                    const hasError = params.props.value?.length <= 0;
                    return { ...params.props, error: hasError };
                },
                renderCell: (params) => {
                    const displayTextSizeCellId = `display-text-size-cell-${params.id}`;

                    return (
                        <CustomRenderCell
                            {...params}
                            anchorTooltipId={displayTextSizeCellId}
                            renderValue={params.value}
                        />
                    );
                },
            },
            {
                field: 'maxTextSize',
                editable: true,
            },
            {
                field: 'formType',
                headerName: 'Form Type',
                minWidth: 170,
                cellClassName: 'form-type',
                flex: 0.5,
                type: 'singleSelect',
                valueOptions: (
                    params: GridValueOptionsParams<DataFormQuestion>
                ) => {
                    switch (params.row.answerType) {
                        case AnswerTypeVariableNamesList.find(
                            (f) => f.id === AnswerTypeVariable.PickFromList
                        ).name:
                            return formType?.options.map((x) => x.option);
                        case AnswerTypeVariableNamesList.find(
                            (f) => f.id === AnswerTypeVariable.Number
                        ).name:
                            return numericFormat?.options.map((x) => x.option);
                    }
                },
                editable: true,
                preProcessEditCellProps: (
                    params: GridPreProcessEditCellProps
                ) => {
                    const hasError = params.props.value?.length <= 0;
                    return { ...params.props, error: hasError };
                },
                renderEditCell: (params) => (
                    <GridEditSingleSelectCell
                        onValueChange={(_, newValue) =>
                            handleReferenceQuestionAnswerTypeChange(
                                newValue,
                                params.field,
                                params.id,
                                params.row
                            )
                        }
                        {...params}
                    />
                ),
                renderCell: (params) => {
                    const formTypeCellId = `form-type-cell-${params.id}`;

                    return (
                        <CustomRenderCell
                            {...params}
                            anchorTooltipId={formTypeCellId}
                            renderValue={params.value}
                        />
                    );
                },
            },
            {
                field: 'formList',
                headerName: 'Form List',
                minWidth: 170,
                cellClassName: 'form-list',
                flex: 0.5,
                type: 'singleSelect',
                valueOptions: notSystemVariables
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((x) => x.name),
                editable: true,
                preProcessEditCellProps: (
                    params: GridPreProcessEditCellProps
                ) => {
                    const hasError = params.props.value?.length <= 0;
                    return { ...params.props, error: hasError };
                },
                renderEditCell: (params) => (
                    <GridEditSingleSelectCell
                        onValueChange={(_, newValue) =>
                            handleReferenceQuestionAnswerTypeChange(
                                newValue,
                                params.field,
                                params.id,
                                params.row
                            )
                        }
                        {...params}
                    />
                ),
                renderCell: (params) => {
                    const formListCellId = `form-list-cell-${params.id}`;

                    return (
                        <CustomRenderCell
                            {...params}
                            anchorTooltipId={formListCellId}
                            renderValue={params.value}
                        />
                    );
                },
            },
            {
                field: 'priority',
                headerName: 'Priority',
                minWidth: 170,
                cellClassName: (params) => {
                    let cellClass = 'priority';

                    if (params.row.isSubQuestion) {
                        cellClass = `${cellClass} disabled`;
                    }

                    return cellClass;
                },
                flex: 0.5,
                type: 'singleSelect',
                valueOptions: ['', ...priority?.options.map((x) => x.option)],
                editable: true,
                renderCell: (params) => {
                    const priorityCellId = `priority-cell-${params.id}`;

                    return (
                        <CustomRenderCell
                            {...params}
                            anchorTooltipId={priorityCellId}
                            renderValue={params.value}
                        />
                    );
                },
            },
            getDueDateOrPeriodColumn(),
        ];

        const runQuestionValidation = (
            question: DataFormQuestion,
            element: Element
        ): boolean => {
            let isValid = true;

            const displayModuleElement =
                element.querySelector('.display-module');
            const displayQuestionElement =
                element.querySelector('.display-question');
            const answerTypeElement = element.querySelector('.answer-type');

            const parentQuestionIdCellElement = element.querySelector(
                '.parent-question-id'
            );

            removeErrorClassFromCellContent(parentQuestionIdCellElement);

            const displaySubQuestionOrderCellElement =
                element.querySelector('.display-order-sub');

            removeErrorClassFromCellContent(displaySubQuestionOrderCellElement);

            if (
                validateRequiredCell(
                    displayModuleElement,
                    question.displayModule
                )
            ) {
                isValid = false;
            }
            if (
                validateRequiredCell(
                    displayQuestionElement,
                    question.displayQuestion
                )
            ) {
                isValid = false;
            }
            if (validateRequiredCell(answerTypeElement, question.answerType)) {
                isValid = false;
            }

            return isValid;
        };

        const runSubQuesitonValidation = (
            subQquestion: DataFormQuestion,
            element: Element
        ): boolean => {
            let isValid = true;

            const parentDisplayNameCellElement = element.querySelector(
                '.parent-display-name'
            );
            removeErrorClassFromCellContent(parentDisplayNameCellElement);

            const displayModuleCellElement =
                element.querySelector('.display-module');
            removeErrorClassFromCellContent(displayModuleCellElement);

            const parentQuestionIdElement = element.querySelector(
                '.parent-question-id'
            );
            const displayQuestionElement =
                element.querySelector('.display-question');
            const answerTypeElement = element.querySelector('.answer-type');

            if (
                validateRequiredCell(
                    parentQuestionIdElement,
                    subQquestion.parentQuestionId
                )
            ) {
                isValid = false;
            }

            if (
                validateRequiredCell(
                    displayQuestionElement,
                    subQquestion.displayQuestion
                )
            ) {
                isValid = false;
            }

            if (
                validateRequiredCell(answerTypeElement, subQquestion.answerType)
            ) {
                isValid = false;
            }

            return isValid;
        };

        const validateAllRows = () => {
            let isValid = true;
            for (let index = 0; index < templateRows.length; index++) {
                const rowId = templateRows[index].customQuestionId;
                const element = document.querySelector(
                    '[data-id="' + rowId + '"]'
                );
                const question = templateRows[index];
                if (element) {
                    const isQuestionValid = question.isSubQuestion
                        ? runSubQuesitonValidation(question, element)
                        : runQuestionValidation(question, element);
                    if (!isQuestionValid) {
                        isValid = false;
                    }
                    const logicParentQuestionIdElement = element.querySelector(
                        '.logic-parent-question-id'
                    );
                    if (question.isDisplayLogicEnabled) {
                        if (
                            validateRequiredCell(
                                logicParentQuestionIdElement,
                                question.logicParentCustomId
                            )
                        ) {
                            isValid = false;
                        }
                        if (
                            question.logicParentCustomId &&
                            isParentAnswerDescribed(question)
                        ) {
                            const validationResult = validateConditionValueCell(
                                element,
                                '.condition-value',
                                question.conditionValues
                            );
                            isValid = validationResult ? false : isValid;
                        } else {
                            const displayNameCellElement =
                                element.querySelector('.condition-value');
                            removeErrorClassFromCellContent(
                                displayNameCellElement
                            );
                        }
                    } else {
                        const logicParentIdCellElement = element.querySelector(
                            '.logic-parent-question-id'
                        );
                        removeErrorClassFromCellContent(
                            logicParentIdCellElement
                        );

                        const conditionValueCellElement =
                            element.querySelector('.condition-value');
                        removeErrorClassFromCellContent(
                            conditionValueCellElement
                        );
                    }
                }
            }

            apiRef.current?.forceUpdate();
            if (
                !validateGridEditorRowDisplayOrder(
                    templateRows,
                    apiRef.current.state,
                    EditorTypeEnum.Questionnaire,
                    (x) => x.isSubQuestion
                )
            ) {
                isValid = false;
            }

            if (
                !validateSubQuestionDisplayOrder(
                    templateRows,
                    apiRef.current.state
                )
            ) {
                isValid = false;
            }

            if (!validateDisplayTextSize(templateRows, apiRef.current.state)) {
                isValid = false;
            }

            if (
                !validateFormType(
                    templateRows,
                    apiRef.current.state,
                    EditorTypeEnum.Questionnaire
                )
            ) {
                isValid = false;
            }

            if (
                !validateFormList(
                    templateRows,
                    apiRef.current.state,
                    EditorTypeEnum.Questionnaire
                )
            ) {
                isValid = false;
            }

            return isValid;
        };

        const updateRows = (
            newRows: (oldRows: DataFormQuestion[]) => DataFormQuestion[]
        ): void => {
            const result = newRows(templateRows).map((question) => ({
                ...question,
                maxTextSize: mapMaxTextSize(question.displayTextSize),
            }));
            setTemplateRows(result);
        };

        const getRowId = (row: GridValidRowModel) => {
            return row.customQuestionId;
        };

        const subQuestionSwitcherColumn = SubQuestionSwitcherColumn({
            setRows: updateRows,
            gridApiRef: apiRef,
            getRowId: getRowId,
        });

        const displayLogicSwitcherColumn = DisplayLogicSwitcherColumn({
            setRows: updateRows,
            gridApiRef: apiRef,
            getRowId: getRowId,
        });

        const mapMaxTextSize = (displayTextSize: string) =>
            textSize?.options?.find((f) => f.name === displayTextSize)
                ?.option ?? '0';

        useEffect(() => {
            validateAllRows();
        }, [templateRows]);

        return (
            <EditableTable
                editorType={EditorTypeEnum.Questionnaire}
                columns={columns()}
                rows={templateRows}
                setRows={updateRows}
                isLoading={isLoading}
                fieldToFocus="displayOrder"
                ref={gridTableRef}
                gridApiRef={apiRef}
                validateAllRows={validateAllRows}
            />
        );
    }
);

export default QuestionnaireTemplateEditor;
