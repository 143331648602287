import { DataFormType } from '../DataFormTypeEnum';
import FormNotificationStatus from '../FormNotificationStatus';
import { QuestionProgressStatus } from '../QuestionProgressStatusEnum';
import QuestionSeenStatus, {
    calculateQuestionUserNotifications,
} from '../QuestionSeenStatus';
import { TableCellSeenStatus } from '../TableCellSeenStatus';
import { TableNotificationStatus } from '../TableNotificationStatus';
import TabNotificationStatus from '../TabNotificationStatus';
import {
    mapFormsAdminNotifications,
    mapFormsUserNotifications,
} from './data-forms-status-mapper';
import { DataRequestNotificationsState } from './data-request-notifications.state';
import { updateTablesNotifications } from './data-tables-status-mapper';

export const defaultTabsStatus: TabNotificationStatus[] = [
    {
        formType: DataFormType.Documents,
        displayName: 'Documents',
        isNewProject: true,
        isNewForm: true,
        countOfNotifications: 0,
    },
    {
        formType: DataFormType.FinancialRequest,
        displayName: 'Financial Requests',
        isNewProject: true,
        isNewForm: true,
        countOfNotifications: 0,
    },
    {
        formType: DataFormType.Questionnaire,
        displayName: 'Questionnaire',
        isNewProject: true,
        isNewForm: true,
        countOfNotifications: 0,
    },
    {
        formType: DataFormType.DataTable,
        displayName: 'Data Tables',
        isNewProject: true,
        isNewForm: true,
        countOfNotifications: 0,
    },
];

export const updateTabsState = (
    state: DataRequestNotificationsState,
    updatedQuestionsStatus: QuestionSeenStatus[],
    cellsStatus: TableCellSeenStatus[],
    tablesNotificationState: TableNotificationStatus[]
) => {
    const formsNotifications = state.isAdminView
        ? mapFormsAdminNotifications(updatedQuestionsStatus)
        : mapFormsUserNotifications(updatedQuestionsStatus);

    const updatedTablesNotifications = updateTablesNotifications(
        cellsStatus,
        tablesNotificationState,
        state.isAdminView
    );

    const updateFormTabsStatus = mapProjectTabsStatuses(
        formsNotifications,
        updatedTablesNotifications,
        state.isAdminView
    );
    const updateModuleTabsStatus = state.isAdminView
        ? mapModuleTabsAdminStatuses(updatedQuestionsStatus)
        : mapModuleTabsUserStatuses(updatedQuestionsStatus);

    state.formsNotificationsStatus = formsNotifications;
    state.projectTabsNotificationsStatus = updateFormTabsStatus;
    state.moduleTabsNotificationsStatus = updateModuleTabsStatus;
    state.projectTabsNotificationsStatus = updateFormTabsStatus;
};

export const mapProjectTabsStatuses = (
    formNotificationsStatus: FormNotificationStatus[],
    tablesNotifications: TableNotificationStatus[],
    isAdminView: boolean
) => {
    const projectTabs = defaultTabsStatus;
    const result = projectTabs.map((tab): TabNotificationStatus => {
        if (tab.formType === DataFormType.DataTable) {
            return updateTablesTabStatus(tab, tablesNotifications, isAdminView);
        } else {
            return updateFormTabStatus(
                tab,
                formNotificationsStatus,
                isAdminView
            );
        }
    });

    return result;
};

const updateFormTabStatus = (
    tab: TabNotificationStatus,
    formNotificationsStatus: FormNotificationStatus[],
    isAdminView: boolean
) => {
    const formTypeStatuse = formNotificationsStatus.filter(
        (status) => status.formType === tab.formType
    );

    const notificationsCount = formTypeStatuse
        .map((f) => f.notificationsCount)
        .reduce((sum, value) => sum + value, 0);

    const isFormNew = formTypeStatuse.every((e) => e.isNew);
    const isNewProject = formTypeStatuse.every((e) => e.isNewProject);

    return {
        ...tab,
        formType: tab.formType,
        isNewForm: isFormNew,
        countOfNotifications: isAdminView && isFormNew ? 0 : notificationsCount,
        isNewProject: isNewProject,
    };
};

const updateTablesTabStatus = (
    tab: TabNotificationStatus,
    tablesNotifications: TableNotificationStatus[],
    isAdminView: boolean
): TabNotificationStatus => {
    const notificationsCount = tablesNotifications
        .map((f) => f.notificationsCount)
        .reduce((sum, value) => sum + value, 0);

    const isNewProject = tablesNotifications.every((e) => e.isNewProject);

    return {
        ...tab,
        formType: tab.formType,
        isNewForm: false,
        countOfNotifications: isAdminView ? 0 : notificationsCount,
        isNewProject: isNewProject,
    };
};

export const mapModuleTabsUserStatuses = (
    questionsSeenStatus: QuestionSeenStatus[]
): TabNotificationStatus[] => {
    const moduleTabs = mapModuleTabItems(questionsSeenStatus);

    const result = moduleTabs.map((moduleTab): TabNotificationStatus => {
        const formItems = questionsSeenStatus.filter(
            (question) => question.formId === moduleTab.formId
        );

        const questionsStatus = formItems.filter(
            (question) =>
                !question.isSubQuestion &&
                question.formId === moduleTab.formId &&
                question.displayModule === moduleTab.displayName
        );

        const subQquestionsStatus = formItems.filter(
            (question) =>
                question.isSubQuestion && question.formId === moduleTab.formId
        );

        const moduleNotificationsCount = questionsStatus
            .map((questionStatus) => {
                let count = calculateQuestionUserNotifications(
                    questionStatus,
                    formItems
                );

                if (
                    questionStatus.isSeenByUser &&
                    !questionStatus.isSubQuestion
                ) {
                    const subQuestionsNotifications = subQquestionsStatus
                        .filter(
                            (subQuestionStatus) =>
                                subQuestionStatus.originQuestionId ===
                                questionStatus.id
                        )
                        .reduce(
                            (sum, value) =>
                                sum +
                                calculateQuestionUserNotifications(
                                    value,
                                    formItems
                                ),
                            0
                        );

                    count += subQuestionsNotifications;
                }

                return count;
            })
            .reduce((sum, value) => sum + value, 0);

        return { ...moduleTab, countOfNotifications: moduleNotificationsCount };
    });

    return result;
};

export const mapModuleTabsAdminStatuses = (
    questionsSeenStatus: QuestionSeenStatus[]
): TabNotificationStatus[] => {
    const moduleTabs = mapModuleTabItems(questionsSeenStatus);

    const result = moduleTabs.map((moduleTab): TabNotificationStatus => {
        const moduleQuestions = questionsSeenStatus.filter(
            (question) =>
                !question.isSubQuestion &&
                question.formId === moduleTab.formId &&
                question.displayModule === moduleTab.displayName
        );

        const moduleNotificationsmoduleNotificationsCount = moduleQuestions
            .map((questionStatus) => {
                let count = 0;

                if (questionStatus.isUserQuestionHighlighted) {
                    count += 1;
                }

                if (
                    !questionStatus.isSeenByAdmin &&
                    questionStatus.isMarkedAsComplete &&
                    questionStatus.status === QuestionProgressStatus.InReview
                ) {
                    count += 1;
                }
                return count;
            })
            .reduce((sum, value) => sum + value, 0);

        return {
            ...moduleTab,
            countOfNotifications: moduleNotificationsmoduleNotificationsCount,
        };
    });

    return result;
};

const mapModuleTabItems = (
    questionsSeenStatus: QuestionSeenStatus[]
): TabNotificationStatus[] => {
    const displayModules = questionsSeenStatus.map(
        (questionStatus): TabNotificationStatus | null =>
            questionStatus.displayModule
                ? {
                      formType: questionStatus.dataFormType,
                      displayName: questionStatus.displayModule,
                      isNewProject: true,
                      isNewForm: true,
                      countOfNotifications: 0,
                      formId: questionStatus.formId,
                  }
                : null
    );
    const notEmptyDisplayModules = displayModules.filter((x) => x);

    const uniqueDisplayModules = notEmptyDisplayModules.filter(
        (moduleState, index) => {
            const duplicateIndex = notEmptyDisplayModules.findIndex(
                (duplicateModuleState) =>
                    duplicateModuleState.displayName ===
                        moduleState.displayName &&
                    duplicateModuleState.formId === moduleState.formId &&
                    duplicateModuleState.formType === moduleState.formType
            );
            return index === duplicateIndex;
        }
    );

    return uniqueDisplayModules;
};
