import './TimelineTemplates.scss';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Helpers from '../../../../utils/helper';
import { useStateSelector } from '../../../../store/selectors';
import BootstrapTable from '../../../../components/react-bootstrap-table-next/react-bootstrap-table2';
import filterFactory from '../../../../components/react-bootstrap-table-next/react-bootstrap-table2-filter';
import paginationFactory from '../../../../components/react-bootstrap-table-next/react-bootstrap-table2-paginator';
import { TableFilter } from '../../../../components/Table/models/table-filter';
import { TimelineTemplate } from '../../Model/TimelineTemplate';
import createNotification from '../../../../utils/createNotification';
import ConfirmationModal from '../../../../components/Modal/ConfirmationModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCopy,
    faEdit,
    faEye,
    faTrashCan,
} from '@fortawesome/free-solid-svg-icons';
import { objectToQueryString } from '../../../../utils/queryHelper';
import moment from 'moment';
import { MarketplaceTemplatePreviewComponent } from '../../MarketplaceTemplatePreview/MarketplaceTemplatePreview.component';
import TimelineCopyModal from '../../Modals/TimelineCopyModal/TimelineCopyModal';
import { Button } from 'reactstrap';

const TimelineTemplates = () => {
    const [page, setPage] = useState(1);
    const [totalSize, setTotalSize] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(5);
    const [templates, setTemplates] = useState<TimelineTemplate[]>([]);
    const [selectedTemplate, setSelectedTemplate] =
        useState<TimelineTemplate>();

    const navigate = useNavigate();
    const axios = useStateSelector((state) => state.core.axios);

    const [isLoading, setIsLoading] = useState(true);

    const [isRenamingPopupVisible, setIsRenamingPopupVisible] = useState(false);
    const [
        isRemoveConfirmationPopupVisible,
        setIsRemoveConfirmationPopupVisible,
    ] = useState(false);
    const [isPreviewVisible, setIsPreviewVisible] = useState(false);

    const defaultSorted: [{ dataField: any; order: any }] = [
        {
            dataField: 'name',
            order: 'asc',
        },
    ];
    const [currentSort, setCurrentSort] = useState<TableFilter>(
        new TableFilter({ filters: [], logic: '' }, page, sizePerPage, {
            dir: 'asc',
            field: 'name',
        })
    );

    const handleTableChange = (_type: any, newState: any) => {
        const { page, sizePerPage, sortField, sortOrder } = newState;

        const updatedPostObj = new TableFilter(
            { filters: [], logic: '' },
            page,
            sizePerPage,
            { dir: sortOrder, field: sortField }
        );
        setCurrentSort(updatedPostObj);

        fetchData(updatedPostObj);
    };

    const fetchData = (postObj: TableFilter) => {
        axios
            .get(
                `/api/Marketplace/TimelineTemplates?${objectToQueryString(
                    postObj
                )}`
            )
            .then((response: any) => {
                if (
                    response &&
                    response.request &&
                    response.request.status !== 200
                ) {
                    createNotification(
                        'Could not fetch Groups at the moment. Please try again later.',
                        'error'
                    );
                } else {
                    setTemplates(response.data.list);
                    setPage(response.data.pageNumber);
                    setTotalSize(response.data.totalItems);
                    setSizePerPage(response.data.pageSize);
                }
                setIsLoading(false);
            })
            .catch(() => {
                setTemplates([]);
                setIsLoading(false);
            });
    };

    const removeTemplate = (templateId: number) => {
        axios
            .delete(`api/Marketplace/TimelineTemplates/${templateId}`)
            .catch(() => {
                createNotification(
                    'An unexpected error occurred while removing Timeline Template.',
                    'error'
                );
            })
            .finally(() => fetchData(currentSort));
    };

    useEffect(() => {
        fetchData(currentSort);
    }, []);

    const templatesColumns = [
        {
            dataField: 'name',
            text: 'Template Name',
            headerClasses: 'name-column-header',
            sort: true,
            formatter: (cellContent: number) =>
                !cellContent ? '-' : cellContent,
        },
        {
            dataField: 'dateModified',
            text: 'Last Edited',
            headerClasses: 'last-edited-column-header',
            sort: true,
            formatter: (cellContent: string) => (
                <div>
                    {cellContent
                        ? moment(cellContent).format('M/D/YYYY h:mmA')
                        : '-'}
                </div>
            ),
        },
        {
            dataField: '',
            text: 'Actions',
            headerClasses: 'actions-column-header',
            headerFormatter: () => (
                <div className="d-flex justify-content-between w-100">
                    <div className="w-100">Actions</div>
                </div>
            ),
            style: {
                minWidth: 350,
            },
            formatter: (_cellContent: number, row: TimelineTemplate) => {
                return (
                    <>
                        <div className="action-icons">
                            <FontAwesomeIcon
                                onClick={() => {
                                    setSelectedTemplate(row);
                                    setIsRenamingPopupVisible(true);
                                }}
                                icon={faCopy}
                            />
                            <FontAwesomeIcon
                                onClick={() => {
                                    navigate(
                                        `/marketplace/admin/templates/${row.id}`
                                    );
                                }}
                                icon={faEdit}
                            />
                            <FontAwesomeIcon
                                onClick={() => {
                                    setSelectedTemplate(row);
                                    setIsPreviewVisible(true);
                                }}
                                icon={faEye}
                            />
                            <FontAwesomeIcon
                                className="delete-button"
                                icon={faTrashCan}
                                onClick={() => {
                                    setSelectedTemplate(row);
                                    setIsRemoveConfirmationPopupVisible(true);
                                }}
                            />
                        </div>
                    </>
                );
            },
        },
    ];

    return (
        <>
            <div className="timeline-templates">
                <div>
                    <div className="mb-3">
                        <strong>
                            {isPreviewVisible && selectedTemplate?.name
                                ? selectedTemplate.name
                                : 'Timeline Templates'}
                        </strong>
                        {selectedTemplate && isPreviewVisible ? (
                            <Button
                                type="button"
                                className="btn btn-primary ml-3 back-btn"
                                onClick={() => {
                                    setSelectedTemplate(undefined);
                                    setIsPreviewVisible(false);
                                }}>
                                Back
                            </Button>
                        ) : (
                            <Button
                                type="button"
                                className="btn btn-primary ml-3"
                                onClick={() => {
                                    navigate(
                                        `/marketplace/admin/templates/new`
                                    );
                                }}>
                                Create New
                            </Button>
                        )}
                    </div>
                    {selectedTemplate && isPreviewVisible ? (
                        <MarketplaceTemplatePreviewComponent
                            timelineTemplateId={selectedTemplate.id}
                        />
                    ) : (
                        <div
                            role="tabpanel"
                            id="panel-0"
                            aria-labelledby="tab-0"
                            aria-hidden="false">
                            <div className="card-body">
                                {isLoading ? (
                                    Helpers.renderTableLoader()
                                ) : (
                                    <BootstrapTable
                                        striped
                                        bordered
                                        bootstrap4
                                        remote
                                        keyField="id"
                                        data={templates}
                                        columns={templatesColumns}
                                        defaultSorted={defaultSorted}
                                        filter={filterFactory()}
                                        pagination={paginationFactory({
                                            page,
                                            sizePerPage,
                                            totalSize,
                                            tooltipTargetId:
                                                'timeline-templates-tooltip',
                                        })}
                                        onTableChange={handleTableChange}
                                        noDataIndication="No Templates available"
                                        rowStyle={{ height: '44px' }}
                                    />
                                )}
                            </div>
                        </div>
                    )}
                </div>
                {selectedTemplate && isRenamingPopupVisible && (
                    <TimelineCopyModal
                        isVisible={isRenamingPopupVisible}
                        template={selectedTemplate}
                        onClose={(id: number | undefined) => {
                            if (!!id)
                                navigate(`/marketplace/admin/templates/${id}`);
                            else {
                                setIsRenamingPopupVisible(false);
                                setSelectedTemplate(undefined);
                            }
                        }}
                        fetchData={() => fetchData(currentSort)}
                    />
                )}
                {selectedTemplate && isRemoveConfirmationPopupVisible && (
                    <ConfirmationModal
                        isVisible={isRemoveConfirmationPopupVisible}
                        setIsVisible={setIsRemoveConfirmationPopupVisible}
                        header="Confirmation"
                        confirmationText={`Are you sure you want to remove '${selectedTemplate.name}' Timeline Template?`}
                        nextButtonText="Confirm"
                        cancelButtonText="Back"
                        onConfirm={removeTemplate}
                        params={selectedTemplate.id}
                    />
                )}
            </div>
        </>
    );
};

export default TimelineTemplates;
