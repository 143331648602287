import './MarketplaceBuyers.scss';
import React, { useEffect, useState } from 'react';
import Helpers from '../../../../utils/helper';
import { useStateSelector } from '../../../../store/selectors';
import BootstrapTable from '../../../../components/react-bootstrap-table-next/react-bootstrap-table2';
import filterFactory from '../../../../components/react-bootstrap-table-next/react-bootstrap-table2-filter';
import paginationFactory from '../../../../components/react-bootstrap-table-next/react-bootstrap-table2-paginator';
import { TableFilter } from '../../../../components/Table/models/table-filter';
import { Button } from 'reactstrap';
import { InviteNewBuyerModal } from '../../Modals/InviteNewBuyerModal/InviteNewBuyerModal';
import { objectToQueryString } from '../../../../utils/queryHelper';
import createNotification from '../../../../utils/createNotification';
import UserDetailsModal from '../../../Admin/Permissions/Users/Modal/UserDetailsModal';

interface Buyer {
    buyerName: string;
    corporateAffiliationName: string;
    activeEngagementsCount: number;
    id: number;
    userId: number;
}
export const MarketplaceBuyers = () => {
    const [page, setPage] = useState(1);
    const [totalSize, setTotalSize] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(5);
    const [data, setData] = useState<Buyer[]>([]);
    const axios = useStateSelector((state) => state.core.axios);
    const [isLoading, setIsLoading] = useState(false);
    const [isInviteNewBuyerModalVisible, setIsInviteNewBuyerModalVisible] =
        useState(false);
    const [userId, setUserId] = useState(0);
    const [isUserDetailsModalVisible, setIsUserDetailsModalVisible] =
        useState(false);
    const defaultSorted: [{ dataField: any; order: any }] = [
        {
            dataField: 'userFullName',
            order: 'asc',
        },
    ];
    const [currentSort, setCurrentSort] = useState<TableFilter>(
        new TableFilter({ filters: [], logic: '' }, page, sizePerPage, {
            dir: 'asc',
            field: 'userFullName',
        })
    );

    useEffect(() => {
        fetchData(currentSort);
    }, []);

    const fetchData = (postObj: TableFilter) => {
        axios
            .get(
                `/api/Marketplace/MarketplaceBuyers?${objectToQueryString(
                    postObj
                )}`
            )
            .then((response: any) => {
                if (
                    response &&
                    response.request &&
                    response.request.status !== 200
                ) {
                    createNotification(
                        'Could not fetch Buyers at the moment. Please try again later.',
                        'error'
                    );
                } else {
                    setData(response.data.list);
                    setPage(response.data.pageNumber);
                    setTotalSize(response.data.totalItems);
                    setSizePerPage(response.data.pageSize);
                }
                setIsLoading(false);
            })
            .catch(() => {
                setData([]);
                setIsLoading(false);
            });
    };

    const handleTableChange = (_type: any, newState: any) => {
        const { page, sizePerPage, sortField, sortOrder } = newState;

        const updatedPostObj = new TableFilter(
            { filters: [], logic: '' },
            page,
            sizePerPage,
            { dir: sortOrder, field: sortField }
        );
        setCurrentSort(updatedPostObj);
        fetchData(updatedPostObj);
    };

    const showUserDetailPopup = (userId: number) => {
        setIsUserDetailsModalVisible(true);
        setUserId(userId);
    };

    const columns = [
        {
            dataField: 'userFullName',
            text: 'Buyer Name',
            headerClasses: 'buyer-name',
            sort: true,
            formatter: (cellContent: string, row: Buyer) => (
                <div
                    className={'link'}
                    onClick={() => {
                        showUserDetailPopup(row.userId);
                    }}>
                    {cellContent}
                </div>
            ),
        },
        {
            dataField: 'corporateAffiliationName',
            text: 'Corporate Affiliation',
            headerClasses: 'corporate-affiliation-name',
            sort: true,
        },
        {
            dataField: 'activeEngagementsCount',
            headerClasses: 'active-engagements-count',
            text: '# of active engagements',
            sort: true,
        },
        {
            dataField: 'id',
            text: 'Actions',
            headerClasses: 'actions',
            formatter: (_cellContent: number) => {
                return (
                    <div className="d-flex">
                        <Button className="btn btn-primary" onClick={() => {}}>
                            History
                        </Button>
                    </div>
                );
            },
        },
    ];

    return (
        <div className="mt-3 marketplace-buyers-tab">
            <div className="body_white_box">
                <div>
                    <div className="body_header_title">
                        <div className="d-flex align-items-center">
                            <div className="header-title mr-4">Buyers</div>
                            <div>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => {
                                        setIsInviteNewBuyerModalVisible(true);
                                    }}>
                                    Invite New Buyer
                                </button>
                            </div>
                        </div>
                    </div>

                    <div
                        role="tabpanel"
                        id="panel-0"
                        aria-labelledby="tab-0"
                        aria-hidden="false">
                        <div className="card-body">
                            {isLoading ? (
                                Helpers.renderTableLoader()
                            ) : (
                                <BootstrapTable
                                    bootstrap4
                                    remote
                                    keyField="id"
                                    data={data}
                                    columns={columns}
                                    defaultSorted={defaultSorted}
                                    filter={filterFactory()}
                                    pagination={paginationFactory({
                                        page,
                                        sizePerPage,
                                        totalSize,
                                    })}
                                    onTableChange={handleTableChange}
                                    noDataIndication="No Templates available"
                                />
                            )}
                        </div>
                    </div>
                    {isInviteNewBuyerModalVisible && (
                        <InviteNewBuyerModal
                            isVisible={isInviteNewBuyerModalVisible}
                            onClose={() => {
                                setIsInviteNewBuyerModalVisible(false);
                            }}></InviteNewBuyerModal>
                    )}
                    {isUserDetailsModalVisible && (
                        <div className="user-details-popup">
                            <UserDetailsModal
                                onClose={() => {
                                    setIsUserDetailsModalVisible(false);
                                    fetchData(currentSort);
                                }}
                                isVisisble={isUserDetailsModalVisible}
                                userID={userId}></UserDetailsModal>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
