import React, { useState } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Box, MenuItem, Select, TextField, Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faEye,
    faPenToSquare,
    faTrashCan,
    faSave,
    faCheck,
    faTimes,
} from '@fortawesome/free-solid-svg-icons';
import './TimelineTable.scss';
import { TimelineEvent } from '../Model/TimelineEvent';
import { STAGE_ITEMS, PARTY_ITEMS } from '../Constants/dropdownItems';
import { MAX_DEPENDENCIES_AMOUNT } from '../Constants/constants';
import { TimelineStage } from '../Model/TimelineStage';
import createNotification from '../../../utils/createNotification';
import { TimelineEventDependency } from '../Model/TimelineEventDependency';
import LinkActionPopup, {
    LinkActionPopupMode,
} from '../LinkActionPopup/LinkActionPopup';
import { TimelineParty } from '../Model/TimelineParty';
import { TimelineAction } from '../Model/TimelineAction';

interface TimelineTableProps {
    rows: TimelineEvent[];
    stages: TimelineStage[];
    handleRemoveRow: (id: string) => void;
    handleUpdateRow: (
        id: string,
        updatedValues: {
            name?: string;
            duration?: number;
            stage?: number;
            party?: number;
            dependencies?: TimelineEventDependency[];
            linkedAction?: TimelineAction;
        }
    ) => void;
    isLoading: boolean;
}

const TimelineTable = (props: TimelineTableProps) => {
    const [eyeActionIsDisabled, setEyeActionIsDisabled] = useState(true);
    const [noteActionIsDisabled, setNoteActionIsDisabled] = useState(true);

    const [isDependencySelectorVisible, setIsDependencySelectorVisible] =
        useState(false);
    const [selectedDependencyId, setSelectedDependencyId] = useState('');
    const [selectedDependencyOffsetDays, setSelectedDependencyOffsetDays] =
        useState(0);

    const [editRowId, setEditRowId] = useState<string | null>(null);
    const [editedEvent, setEditedEvent] = useState<TimelineEvent>();
    const [actionEventRowId, setActionEventRowId] = useState<string>(null);
    const [isLinkActionPopupVisible, setIsLinkActionPopupVisible] =
        useState(false);
    const [linkActionPopupMode, setLinkActionPopupMode] =
        useState<LinkActionPopupMode>('create');

    const generateStageCode = (index: number): string => {
        const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        let code = '';

        while (index >= 0) {
            code = alphabet[index % 26] + code;
            index = Math.floor(index / 26) - 1;
        }

        return code;
    };

    const handleEditClick = (row: TimelineEvent) => {
        setEditRowId(row.id);
        setEditedEvent(row);
        setSelectedDependencyId('');
        setSelectedDependencyOffsetDays(0);
        setIsDependencySelectorVisible(false);
    };

    const handleSaveClick = (rowId: string) => {
        if (editedEvent) {
            const selectedStageId = editedEvent.stage;
            const stageExists = props.stages.some(
                (stage) => stage.id === selectedStageId
            );

            if (stageExists) {
                const originalEvent = props.rows.find((f) => f.id === rowId);
                const isPartyChanged =
                    originalEvent.party !== editedEvent.party;

                props.handleUpdateRow(rowId, {
                    name: editedEvent.name,
                    duration: editedEvent.duration,
                    stage: selectedStageId,
                    party: editedEvent.party,
                    dependencies: editedEvent.dependencies,
                    linkedAction: isPartyChanged
                        ? null
                        : editedEvent.linkedAction,
                });
                setEditRowId(null);
                setEditedEvent(null);
            } else {
                createNotification(
                    'Selected stage does not exist in the list of stages',
                    'error'
                );
            }
        }
    };

    const handleInputChange = (field: string, value: string | number) => {
        setEditedEvent((prev) => ({
            ...prev,
            [field]: field === 'duration' ? Number(value) : value,
        }));
    };

    const handleAddDependency = () => {
        const doesSelectedDependencyExist = props.rows.some(
            (row: TimelineEvent) => row.id === selectedDependencyId
        );
        if (!doesSelectedDependencyExist) {
            return undefined;
        }

        const newDependency: TimelineEventDependency = {
            dependencyEventId: selectedDependencyId,
            offsetDays: selectedDependencyOffsetDays,
        };

        const resultDependencies = [
            ...(editedEvent?.dependencies || []),
            newDependency,
        ];

        setEditedEvent((prev) => ({
            ...prev,
            dependencies: [...(prev?.dependencies || []), newDependency],
        }));

        setIsDependencySelectorVisible(false);
        setSelectedDependencyId('');
        setSelectedDependencyOffsetDays(0);

        return resultDependencies;
    };

    const handleRemoveDependencyById = (dependencyId: string) => {
        setEditedEvent((prev) => ({
            ...prev,
            dependencies: prev.dependencies.filter(
                (dependency) => dependency.dependencyEventId !== dependencyId
            ),
        }));
    };

    const handleRemoveRowWithDependencies = (id: string) => {
        if (editedEvent?.dependencies) {
            const updatedDependencies = editedEvent.dependencies.filter(
                (dependency) => dependency.dependencyEventId !== id
            );
            setEditedEvent((prev) => ({
                ...prev,
                dependencies: updatedDependencies,
            }));
        }

        props.handleRemoveRow(id);
    };

    const confirmDependency = () => {
        if (selectedDependencyId && selectedDependencyOffsetDays > -1) {
            handleAddDependency();
        }
    };

    const rootStyles = STAGE_ITEMS.reduce((styles, stage, index) => {
        const code = generateStageCode(index);
        styles[`& .${code}`] = {
            backgroundColor: stage.color,
            display: 'flex',
            justifyContent: 'center',
        };
        return styles;
    }, {} as Record<string, React.CSSProperties>);

    const stageMap = new Map();

    const additionalStyles = {
        border: 'none',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        '.MuiDataGrid-scrollbar': {
            display: 'none',
        },
        '.MuiDataGrid-row': {
            borderBottom: 'none',
            '&:nth-of-type(odd)': {
                backgroundColor: '#f9f9f9',
            },
            '&:nth-of-type(even)': {
                backgroundColor: '#ffffff',
            },
        },
        '.MuiDataGrid-columnHeader:focus': {
            outline: 'none',
        },
        '.MuiDataGrid-cell:focus': {
            outline: 'none',
        },
    };

    const finalStyles = { ...rootStyles, ...additionalStyles };

    const columns: GridColDef[] = [
        {
            field: 'index',
            headerName: 'Index',
            headerClassName: 'text-bold',
            width: 80,
            renderCell: (params) =>
                params.api.getAllRowIds().indexOf(params.id) + 1,
        },
        // {
        //     // todo: this field should be removed when the table behavior will be finished
        //     // Currently it was added to manage data clearly
        //     field: 'id',
        //     headerName: 'Id',
        //     headerClassName: 'text-bold',
        //     width: 80,
        //     renderCell: (params) => params.row.id,
        // },
        {
            field: 'stage',
            headerName: 'Stage',
            width: 150,
            maxWidth: 200,
            headerAlign: 'center',
            renderCell: (params) => {
                if (params.row.id === editRowId) {
                    return (
                        <Select
                            value={editedEvent?.stage || ''}
                            onChange={(e) =>
                                handleInputChange('stage', e.target.value)
                            }
                            size="small"
                            fullWidth
                            sx={{
                                '& .MuiOutlinedInput-notchedOutline': {
                                    border: 'none',
                                },
                                width: 'auto',
                                margin: '0 auto',
                                display: 'flex',
                            }}>
                            {props.stages.map((stage) => (
                                <MenuItem key={stage.id} value={stage.id}>
                                    <span
                                        style={{
                                            display: 'inline-flex',
                                            alignItems: 'center',
                                        }}>
                                        <span
                                            style={{
                                                display: 'inline-block',
                                                maxWidth: '100px',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                            }}
                                            title={stage.name}>
                                            {stage.name}
                                        </span>
                                    </span>
                                </MenuItem>
                            ))}
                        </Select>
                    );
                }

                const stageId = params.value;
                const stage = props.stages.find(
                    (s: TimelineStage) => s.id === stageId
                );
                const name = stage?.name || 'N/A';

                if (!stageMap.has(stageId)) {
                    const index = stageMap.size;
                    const code = generateStageCode(index);
                    const color =
                        index < STAGE_ITEMS.length
                            ? STAGE_ITEMS[index].color
                            : null;

                    stageMap.set(stageId, { code, color });
                }

                const mappedStage = stageMap.get(stageId);
                const code = mappedStage?.code || 'N/A';
                const colorStyle = mappedStage?.color
                    ? { backgroundColor: mappedStage.color }
                    : {};

                return (
                    <Tooltip title={name}>
                        <span
                            className="w-100 d-flex justify-content-center align-items-center"
                            style={{ ...colorStyle }}>
                            {code}
                        </span>
                    </Tooltip>
                );
            },
            cellClassName: (params) => {
                const stageId = params.value;

                if (!stageMap.has(stageId)) {
                    const index = stageMap.size;
                    const code = generateStageCode(index);
                    const color =
                        index < STAGE_ITEMS.length
                            ? STAGE_ITEMS[index].color
                            : null;

                    stageMap.set(stageId, { code, color });
                }

                const baseClasses = 'center-align cursor-pointer';
                const stage = stageMap.get(stageId);
                return stage?.color
                    ? `${stage.code} ${baseClasses}`
                    : baseClasses;
            },
        },
        {
            field: 'name',
            headerName: 'Event',
            flex: 1,
            minWidth: 100,
            renderCell: (params) =>
                params.row.id === editRowId ? (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                            height: '100%',
                            padding: '0 8px',
                        }}>
                        <TextField
                            value={editedEvent?.name || ''}
                            onChange={(e) =>
                                handleInputChange('name', e.target.value)
                            }
                            onKeyDown={(e) => {
                                if (e.key === ' ') {
                                    e.stopPropagation();
                                }
                            }}
                            size="small"
                            fullWidth
                            slotProps={{
                                input: {
                                    style: {
                                        lineHeight: '1.5',
                                    },
                                },
                            }}
                            sx={{
                                '& .MuiOutlinedInput-notchedOutline': {
                                    border: 'none',
                                },
                            }}
                        />
                    </Box>
                ) : (
                    params.row.name
                ),
        },
        {
            field: 'party',
            headerName: 'Party',
            width: 200,
            maxWidth: 300,
            renderCell: (params) => {
                if (params.row.id === editRowId) {
                    return (
                        <Select
                            value={editedEvent?.party}
                            onChange={(e) =>
                                handleInputChange('party', e.target.value)
                            }
                            style={{
                                marginLeft: '-1em',
                                width: 'auto',
                            }}
                            size="small"
                            fullWidth
                            sx={{
                                '& .MuiOutlinedInput-notchedOutline': {
                                    border: 'none',
                                },
                            }}>
                            {PARTY_ITEMS.map((party) => (
                                <MenuItem key={party.id} value={party.id}>
                                    {party.name}
                                </MenuItem>
                            ))}
                        </Select>
                    );
                }
                const party = PARTY_ITEMS.find(
                    (item) => item.id === params.value
                );
                return party ? party.name : '-';
            },
        },
        {
            field: 'dependencies',
            headerName: 'Dependencies',
            flex: 1,
            minWidth: 300,
            renderCell: (params) => {
                const handleAddDependencyClick = () => {
                    setIsDependencySelectorVisible(true);
                };

                const availableDependencies = props.rows.filter(
                    (row: TimelineEvent) =>
                        row.id !== params.row.id &&
                        editedEvent?.dependencies?.every(
                            (dep: TimelineEventDependency) =>
                                dep.dependencyEventId !== row.id
                        )
                );

                if (params.row.id === editRowId) {
                    return (
                        <Box
                            display="flex"
                            height="100%"
                            flexDirection="column"
                            alignItems="start"
                            justifyContent="center">
                            {editedEvent?.dependencies?.length > 0 && (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '8px',
                                        alignItems: 'start',
                                        justifyContent: 'center',
                                        marginTop: '8px',
                                        marginBottom: '8px',
                                    }}>
                                    {editedEvent.dependencies.map(
                                        (
                                            dep: TimelineEventDependency,
                                            index: number
                                        ) => {
                                            const eventName = props.rows.find(
                                                (row: TimelineEvent) =>
                                                    row.id ===
                                                    dep.dependencyEventId
                                            )?.name;

                                            const eventIndex =
                                                props.rows.findIndex(
                                                    (row: TimelineEvent) =>
                                                        row.id ===
                                                        dep.dependencyEventId
                                                );

                                            return (
                                                <Box
                                                    key={index}
                                                    component="span"
                                                    sx={{
                                                        display: 'inline-block',
                                                        backgroundColor:
                                                            '#e0f7fa',
                                                        borderRadius: '12px',
                                                        padding: '4px 8px',
                                                        fontSize: '0.875rem',
                                                        fontWeight: 500,
                                                        color: '#00796b',
                                                        border: '1px solid #b2dfdb',
                                                        lineHeight: 1,
                                                        marginBottom: '8px',
                                                    }}>
                                                    <span
                                                        className="text-bold"
                                                        style={{
                                                            display:
                                                                'inline-flex',
                                                            alignItems:
                                                                'center',
                                                        }}>
                                                        {`${eventIndex + 1}.`}
                                                        <span
                                                            style={{
                                                                display:
                                                                    'inline-block',
                                                                maxWidth:
                                                                    '100px',
                                                                whiteSpace:
                                                                    'nowrap',
                                                                overflow:
                                                                    'hidden',
                                                                textOverflow:
                                                                    'ellipsis',
                                                                marginLeft:
                                                                    '4px',
                                                            }}
                                                            title={eventName}>
                                                            {eventName ||
                                                                'Unknown'}
                                                        </span>
                                                    </span>
                                                    <span>
                                                        {` ${dep.offsetDays} days`}
                                                    </span>
                                                    <FontAwesomeIcon
                                                        title="Remove dependency"
                                                        icon={faTimes}
                                                        className="cursor-pointer"
                                                        style={{
                                                            marginLeft: '6px',
                                                        }}
                                                        size="lg"
                                                        onClick={() =>
                                                            handleRemoveDependencyById(
                                                                dep.dependencyEventId
                                                            )
                                                        }
                                                    />
                                                </Box>
                                            );
                                        }
                                    )}
                                </Box>
                            )}

                            {!isDependencySelectorVisible &&
                                editedEvent?.dependencies?.length <
                                    MAX_DEPENDENCIES_AMOUNT &&
                                availableDependencies.length > 0 && (
                                    <Box
                                        sx={{
                                            display: 'inline-block',
                                            cursor: 'pointer',
                                            textDecoration: 'underline',
                                            color: 'primary.main',
                                            lineHeight: '1.5',
                                        }}
                                        onClick={handleAddDependencyClick}>
                                        <u>Add dependency</u>
                                    </Box>
                                )}

                            {isDependencySelectorVisible && (
                                <Box
                                    component="span"
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}>
                                    <Select
                                        value={selectedDependencyId}
                                        onChange={(e) =>
                                            setSelectedDependencyId(
                                                e.target.value
                                            )
                                        }
                                        style={{
                                            marginLeft: '-1em',
                                        }}
                                        size="small"
                                        displayEmpty
                                        sx={{
                                            lineHeight: '1.5',
                                            '& .MuiOutlinedInput-notchedOutline':
                                                {
                                                    border: 'none',
                                                },
                                        }}>
                                        <MenuItem value="">
                                            Select dependency
                                        </MenuItem>
                                        {availableDependencies.map(
                                            (row: TimelineEvent) => {
                                                const eventIndex =
                                                    props.rows.findIndex(
                                                        (dep: TimelineEvent) =>
                                                            dep.id === row.id
                                                    );

                                                return (
                                                    <MenuItem
                                                        key={row.id}
                                                        value={row.id}>
                                                        <span
                                                            style={{
                                                                display:
                                                                    'inline-flex',
                                                                alignItems:
                                                                    'center',
                                                            }}>
                                                            <span
                                                                style={{
                                                                    display:
                                                                        'inline-block',
                                                                    maxWidth:
                                                                        '150px',
                                                                    whiteSpace:
                                                                        'nowrap',
                                                                    overflow:
                                                                        'hidden',
                                                                    textOverflow:
                                                                        'ellipsis',
                                                                }}
                                                                title={
                                                                    row.name
                                                                }>
                                                                <span className="text-bold ml-1 mr-1">
                                                                    {eventIndex +
                                                                        1}
                                                                    .
                                                                </span>
                                                                {row.name}
                                                            </span>
                                                        </span>
                                                    </MenuItem>
                                                );
                                            }
                                        )}
                                    </Select>

                                    <TextField
                                        type="number"
                                        value={selectedDependencyOffsetDays}
                                        onChange={(e) =>
                                            setSelectedDependencyOffsetDays(
                                                Math.max(
                                                    0,
                                                    Number(e.target.value)
                                                )
                                            )
                                        }
                                        disabled={!selectedDependencyId}
                                        size="small"
                                        slotProps={{
                                            input: {
                                                style: {
                                                    height: '50px',
                                                    width: '50px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                },
                                            },
                                        }}
                                        sx={{
                                            '& .MuiOutlinedInput-notchedOutline':
                                                {
                                                    border: 'none',
                                                },
                                        }}
                                    />

                                    <FontAwesomeIcon
                                        icon={faCheck}
                                        className={`cursor-pointer ${
                                            selectedDependencyId &&
                                            selectedDependencyOffsetDays > -1
                                                ? 'text-success'
                                                : 'text-muted'
                                        }`}
                                        size="lg"
                                        onClick={confirmDependency}
                                    />
                                </Box>
                            )}
                        </Box>
                    );
                }
                return (
                    <>
                        {params.row.dependencies.length > 0 && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '8px',
                                    alignItems: 'center',
                                    height: '100%',
                                    justifyContent: 'center',
                                }}>
                                {params.row.dependencies.map(
                                    (
                                        dep: TimelineEventDependency,
                                        index: number
                                    ) => {
                                        const eventName = props.rows.find(
                                            (row: TimelineEvent) =>
                                                row.id === dep.dependencyEventId
                                        )?.name;

                                        const eventIndex = props.rows.findIndex(
                                            (row: TimelineEvent) =>
                                                row.id === dep.dependencyEventId
                                        );

                                        return (
                                            <Box
                                                key={index}
                                                component="span"
                                                sx={{
                                                    display: 'inline-block',
                                                    backgroundColor: '#e0f7fa',
                                                    borderRadius: '12px',
                                                    padding: '4px 8px',
                                                    fontSize: '0.875rem',
                                                    fontWeight: 500,
                                                    color: '#00796b',
                                                    border: '1px solid #b2dfdb',
                                                    lineHeight: 1,
                                                    width: 'auto',
                                                    alignSelf: 'start',
                                                }}>
                                                <span
                                                    className="text-bold"
                                                    style={{
                                                        display: 'inline-flex',
                                                        alignItems: 'center',
                                                        marginRight: '6px',
                                                    }}>
                                                    {`${eventIndex + 1}. `}
                                                    <span
                                                        style={{
                                                            display:
                                                                'inline-block',
                                                            maxWidth: '100px',
                                                            whiteSpace:
                                                                'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow:
                                                                'ellipsis',
                                                            marginLeft: '4px',
                                                        }}
                                                        title={eventName}>
                                                        {eventName || 'Unknown'}
                                                    </span>
                                                </span>
                                                <span
                                                    style={{
                                                        display: 'inline-flex',
                                                        alignItems: 'center',
                                                    }}>
                                                    {`${dep.offsetDays} days`}
                                                </span>
                                            </Box>
                                        );
                                    }
                                )}
                            </Box>
                        )}
                    </>
                );
            },
        },
        {
            field: 'duration',
            headerName: 'Duration (Days)',
            width: 150,
            renderCell: (params) =>
                params.row.id === editRowId ? (
                    <Box
                        sx={{
                            '& .MuiOutlinedInput-notchedOutline': {
                                border: 'none',
                            },
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                            height: '100%',
                            padding: '0 8px',
                        }}>
                        <TextField
                            type="number"
                            value={editedEvent?.duration || ''}
                            onChange={(e) =>
                                handleInputChange('duration', e.target.value)
                            }
                            size="small"
                            slotProps={{
                                input: {
                                    style: {
                                        lineHeight: '1.5',
                                        padding: '8px 12px',
                                    },
                                },
                            }}
                            sx={{
                                '& .MuiOutlinedInput-notchedOutline': {
                                    border: 'none',
                                },
                            }}
                        />
                    </Box>
                ) : (
                    params.row.duration
                ),
        },
        { field: 'dayStart', headerName: 'Start Day', width: 120 },
        { field: 'dayEnd', headerName: 'End Day', width: 120 },
        {
            field: 'linkedAction',
            headerName: 'Linked Action',
            width: 120,
            renderCell: (params) => {
                if (params.row.party === TimelineParty.VetValue) {
                    return <></>;
                }

                if (params.value) {
                    return (
                        <div
                            className={
                                params.id === editRowId
                                    ? 'disabled'
                                    : 'link-text'
                            }
                            onClick={() => {
                                if (params.id !== editRowId) {
                                    setActionEventRowId(params.row.id);
                                    setEditedEvent(params.row);
                                    setLinkActionPopupMode('preview');
                                    setIsLinkActionPopupVisible(true);
                                }
                            }}>
                            {'Preview Action'}
                        </div>
                    );
                }

                return (
                    <div
                        className={
                            params.id === editRowId ? 'disabled' : 'link-text'
                        }
                        onClick={() => {
                            if (params.id !== editRowId) {
                                setActionEventRowId(params.row.id);
                                setEditedEvent(params.row);
                                setLinkActionPopupMode('create');
                                setIsLinkActionPopupVisible(true);
                            }
                        }}>
                        {'Add Action'}
                    </div>
                );
            },
        },
        {
            field: 'action',
            headerName: 'Actions',
            width: 100,
            renderCell: (params) => (
                <div className="action-cell">
                    <div className="d-flex justify-content-between align-items-center">
                        <FontAwesomeIcon
                            className={`${
                                eyeActionIsDisabled
                                    ? 'text-muted'
                                    : 'cursor-pointer'
                            }`}
                            onClick={() =>
                                !eyeActionIsDisabled &&
                                console.log(
                                    `Eye clicked for row ID: ${params.id}`
                                )
                            }
                            size="lg"
                            icon={faEye}
                        />
                        {params.row.id === editRowId ? (
                            <FontAwesomeIcon
                                icon={faSave}
                                className="cursor-pointer"
                                size="lg"
                                onClick={() => handleSaveClick(params.row.id)}
                            />
                        ) : (
                            <FontAwesomeIcon
                                icon={faPenToSquare}
                                className="cursor-pointer"
                                size="lg"
                                onClick={() => handleEditClick(params.row)}
                            />
                        )}
                        <FontAwesomeIcon
                            className="cursor-pointer trash-icon"
                            onClick={() =>
                                handleRemoveRowWithDependencies(params.row.id)
                            }
                            size="lg"
                            icon={faTrashCan}
                        />
                    </div>
                </div>
            ),
        },
    ];

    const linkAction = (newAction: TimelineAction) => {
        if (editedEvent && actionEventRowId) {
            props.handleUpdateRow(actionEventRowId, {
                ...editedEvent,
                linkedAction: newAction,
            });
            setActionEventRowId(null);
            setEditedEvent(null);
            setIsLinkActionPopupVisible(false);
        }
    };

    return (
        <>
            <Box
                className="timeline-table"
                style={{
                    minHeight: '400px',
                    maxHeight: '1000px',
                    width: '100%',
                    overflow: 'auto',
                    scrollbarWidth: 'none',
                }}>
                <DataGrid
                    disableColumnFilter
                    disableColumnSorting
                    disableRowSelectionOnClick
                    disableColumnSelector
                    disableColumnMenu
                    disableDensitySelector
                    rows={props.rows.map((row, index) => ({
                        ...row,
                        rowIndex: index,
                    }))}
                    getRowHeight={(params) => {
                        const row = params.model as TimelineEvent;
                        const baseHeight = 58.6;
                        const dependencyHeight = 15;
                        const padding = 5;
                        const additionalHeightForEdit = 75;

                        const dependencies =
                            row.id === editRowId && editedEvent
                                ? editedEvent.dependencies || []
                                : row.dependencies || [];

                        const isEditingAndNeedsExtraHeight =
                            row.id === editRowId && dependencies.length > 0;

                        return dependencies.length > 0
                            ? baseHeight +
                                  dependencies.length * dependencyHeight +
                                  padding +
                                  (isEditingAndNeedsExtraHeight
                                      ? additionalHeightForEdit
                                      : 0)
                            : baseHeight +
                                  (isEditingAndNeedsExtraHeight
                                      ? additionalHeightForEdit
                                      : 0);
                    }}
                    getRowId={(row) => row.id}
                    columns={columns}
                    sx={finalStyles}
                    loading={props.isLoading}
                />
            </Box>
            {isLinkActionPopupVisible ? (
                <LinkActionPopup
                    mode={linkActionPopupMode}
                    close={() => {
                        setIsLinkActionPopupVisible(false);
                    }}
                    party={editedEvent.party}
                    linkAction={linkAction}
                    linkedAction={editedEvent.linkedAction}
                />
            ) : (
                <></>
            )}
        </>
    );
};

export default TimelineTable;
