import { GanttChartItem } from '../../models/GanttChartItem.model';

class GanttChartRegistry {
    groupData(list: GanttChartItem[]) {
        const groups: { [id: string]: GanttChartItem[] } = {};
        for (let i = 0; i < list.length; i++) {
            let item = list[i];
            if (!item) break;
            const key = item.groupName !== undefined ? item.groupName : item.id;
            if (groups[key] !== undefined) {
                groups[key].push(item);
            } else {
                groups[key] = [item];
            }
        }
        return groups;
    }

    getNumberOfRows(data: GanttChartItem[]) {
        let uniqueGroups = new Set();
        for (let i of data) {
            uniqueGroups.add(i.groupName ?? i.id);
        }
        return uniqueGroups.size;
    }
}
const instanceRegistry = new GanttChartRegistry();
export default instanceRegistry;
