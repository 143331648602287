export interface Profile {
    affiliation: string;
    graduationYear: number;
    veterinarySchool: string;
    memberSince: Date;
    isActive: boolean;
    practices: Array<Practice>;
    avatarPath: string;
}

export interface DirectMember {
    userId: number;
    id: number;
    firstName: string;
    lastName: string;
    fullName: string;
    initials: string;
    backgroundColorCode: string;
    foregroundColorCode: string;
    isActive: boolean;
    dateCreated: Date;
    profile: Profile;
    isPocMember: boolean;
    onlineState: number;
    numberOfPosts: number;
    isCharityAdmin: boolean;
    charityName?: string;
    isGiftCardContact?: boolean;
    isReferralContact?:boolean;
    isActiveCharityAffiliate?: boolean;
}

export interface Practice {
    id: number;
    name: string;
    city: string;
    state: string;
    practiceTypeId: number;
    website: string;
    roleName: string;
}

export interface DirectMemberOnlineStatus {
    userId: number;
    onlineState: number;
}

export enum ProfilePopupType {
    None = 1,
    Poc = 2,
    Pmg = 3,
    Donor = 4,
}