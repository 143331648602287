import React, { useLayoutEffect, useRef } from 'react';
import { TextareaAutosize } from '@mui/material';
import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid';
import './TextInputColumn.scss';

interface TextInputColumnInterface extends GridRenderEditCellParams {}

const TextInputColumn = (props: TextInputColumnInterface) => {
    const { id, value, field, hasFocus, error } = props;

    const apiRef = useGridApiContext();
    const ref = useRef(null);

    useLayoutEffect(() => {
        if (hasFocus) {
            ref.current.focus();
            const length = ref.current.value.length;
            ref.current.setSelectionRange(length, length);
        }
    }, [hasFocus]);

    const handleValueChange = (
        event: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const newValue = event.target.value;
        apiRef.current.setEditCellValue({
            id,
            field,
            value: newValue,
        });
    };

    return (
        <TextareaAutosize
            className={
                error
                    ? `Mui-error textarea-autosize-${id}-${field}`
                    : `textarea-autosize-${id}-${field}`
            }
            ref={ref}
            minRows={1}
            maxRows={5}
            value={value ?? ''}
            onChange={handleValueChange}
            onKeyDown={(event) => {
                if (event.code === 'Enter') {
                    event.stopPropagation();
                }
            }}
        />
    );
};

export default TextInputColumn;
