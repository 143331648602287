import React, { useEffect, useState } from 'react';
import './direct-chat.scss';
import DirectRoomsList from '../direct-rooms-list/direct-rooms-list';
import { DirectRoom } from '../models/direct-room';
import {
    setIsSelectedDirectRoom,
    setSelectedRoomId,
} from '../slices/rooms/direct-rooms-slice';
import { useDispatch } from 'react-redux';
import Messages from '../../clubhouse/messages/messages';
import { ComparationType } from '../../clubhouse/slices/messages/load-discussion-messages-by-likes.thunk';
import { loadRoomMessages } from '../slices/messages/load-room-messages.thunk';
import { setIsNewDirectMessageReceived } from '../slices/messages/direct-messages-slice';
import { refreshDirectRoomMessageList } from '../slices/messages/refresh-direct-room-message-list.thunk';
import createNotification from '../../utils/createNotification';
import MessageInputArea from '../../clubhouse/message-input-area/message-input-area';
import { sendDirectRoomMessage } from '../slices/messages/send-direct-roommessage.thunk';
import * as signalR from '@microsoft/signalr';
import { likeDirectMessage } from '../slices/messages/like-message.thunk';
import { Message } from '../../clubhouse/models/message';
import { onDirectSelected } from '../../utils/webViewHelper';
import { setSelectedThreadId } from '../../support-portal/slices/threads/support-threads-slice';
import { loadThreadMessages } from '../../support-portal/slices/messages/load-thread-messages.thunk';
import { sendSupportThreadMessage } from '../../support-portal/slices/messages/send-support-thread-message.thunk';
import { refreshSupportThreadMessageList } from '../../support-portal/slices/messages/refresh-support-thread-message-list.thunk';
import { setIsNewSupportMessageReceived } from '../../support-portal/slices/messages/support-threads-messages-slice';
import { likeSupportMessage } from '../../support-portal/slices/messages/like-support-message.thunk';
import { useStateSelector } from '../../store/selectors';
import { RoomItem } from '../models/room-item';
import { RoomMember } from '../models/room-member';
import { RoomType } from '../models/room-type-enum';
import { SelectedRoomState } from '../models/selected-room-state';
import { SupportThreadStatus } from '../../support-portal/models/SupportThread';
import { Button } from 'reactstrap';
import { Member } from '../../clubhouse/models/member';
import {
    SupportContextItemPrefixToAdmin,
    SupportSubContexts,
} from '../../support-portal/models/SupportContext';
import { SupportPortalMember } from '../../support-portal/models/SupportPortalMember';
import InfoTooltip from '../../components/info-tooltip/info-tooltip';

export interface DirectChatProps {
    roomItems: RoomItem[];
    directHubConnection: signalR.HubConnection;
    supportRequestsHubConnection: signalR.HubConnection;
    members: RoomMember[];
    currentUserId: number;
    messages: Array<Message>;
    isMessageLoading: boolean;
    isNewMessageReceived: boolean;
    isPageLoading: boolean;
    hasFirstPage: boolean;
    hasLastPage: boolean;
    onReadNewMessages: () => void;
    openMemberProfileModal: (id: number) => void;
    refresh: () => void;
    isNewChannelsLinkVisible: boolean;
    selectedRoomState: SelectedRoomState;
    setSelectedRoomState: (id: number, type: RoomType) => void;
    onBlockButtonClick: () => void;
    isBlockingRoom: (roomId: number, roomType: RoomType) => boolean;
    isUserBlocking: boolean | undefined;
}

const DirectChat = (props: DirectChatProps) => {
    onDirectSelected();

    const [shouldScrollBottom, setShouldScrollBottom] = useState(false);
    const dispatch: any = useDispatch();

    const axios = useStateSelector((state) => state.core.axios);

    const selectedThreadId = useStateSelector(
        (state) => state.supportThreads.selectedThreadId
    );
    const selectedContext = useStateSelector(
        (state) =>
            state.supportThreads.threads.find((t) => t.id === selectedThreadId)
                ?.supportContext
    );

    const currentUserId = useStateSelector(
        (state) => state.currentUser.user?.id
    );

    const anonymityNoteForAdmin =
        'Charity name is visible to donor, your name is hidden.';
    const anonymityNoteForUser =
        "Because you chose to remain fully anonymous in your donation to this charity, the charity representative knows only that they are communicating with the donor, but they do not know the donor's (your) identity. Only you have the ability to identify yourself to this charity.";

    const loadMessagesPage = (isAscending: boolean) => {
        if (
            (!isAscending && !props.hasLastPage) ||
            (isAscending && !props.hasFirstPage)
        ) {
            if (props.selectedRoomState.type === RoomType.Direct) {
                dispatch(
                    loadRoomMessages({
                        roomId: props.selectedRoomState.id,
                        isAscending: isAscending,
                        pageSize: null,
                    })
                );
            }

            if (props.selectedRoomState.type === RoomType.SupportThread) {
                dispatch(
                    loadThreadMessages({
                        threadId: props.selectedRoomState.id,
                        isAscending: isAscending,
                        pageSize: null,
                    })
                );
            }
        }
    };

    const showNewMessages = (): void => {
        switch (props.selectedRoomState.type) {
            case RoomType.Direct:
                dispatch(
                    refreshDirectRoomMessageList({
                        roomId: props.selectedRoomState.id,
                    })
                );
                break;
            case RoomType.SupportThread:
                dispatch(
                    refreshSupportThreadMessageList({
                        threadId: props.selectedRoomState.id,
                    })
                );
                break;
            default:
                break;
        }
    };

    const openAttachment = (attachmentId: number, messageId: number) => {
        let getAttachmentUrl = '';
        switch (props.selectedRoomState.type) {
            case RoomType.Direct:
                getAttachmentUrl = `/api/direct/${props.selectedRoomState.id}/messages/${messageId}/attachments/${attachmentId}`;
                break;
            case RoomType.SupportThread:
                getAttachmentUrl = `/api/supportRequests/${props.selectedRoomState.id}/messages/${messageId}/attachments/${attachmentId}`;
                break;
        }
        axios.get(getAttachmentUrl).then((response: any) => {
            if (response.status === 200) {
                window.open(response.data, '_blank');
            } else {
                createNotification(
                    'Unexpected error occurred while opening an attachment.',
                    'error'
                );
            }
        });
    };

    const getCurrentRoom = () =>
        props.roomItems.find(
            (r) =>
                r.room.id === props.selectedRoomState.id &&
                r.roomType === props.selectedRoomState.type
        );

    const getRoomName = () => {
        const currentRoom = getCurrentRoom();
        let currentRoomOponentId = 0;
        switch (currentRoom.roomType) {
            case RoomType.Direct:
                currentRoomOponentId = currentRoom.room?.members?.find(
                    (r) => r.id !== props.currentUserId
                )?.id;
                break;
            case RoomType.SupportThread:
                const parsedRoomMembers = JSON.parse(
                    JSON.stringify(props.members)
                );
                const roomMember: SupportPortalMember = parsedRoomMembers.find(
                    (f: RoomMember) =>
                        f.member.userId !== props.currentUserId &&
                        f.roomType === RoomType.SupportThread
                ).member;
                if (roomMember.isAdmin) {
                    return mapFullName(roomMember.lastName);
                }
                currentRoomOponentId = roomMember.id;
                break;
            default:
                break;
        }

        const opponentRoomMember = props.members.find(
            (m) => m.member.id == currentRoomOponentId
        );
        const anonymousMember = currentRoom.room.members.find(
            (m) => m.id == currentRoomOponentId && m.isAnonymousUser
        );
        if (anonymousMember) {
            return mapAnonymousName(anonymousMember.anonymousMemberUniqueCode);
        }

        const opponent = opponentRoomMember?.member;
        const isCurrentUserAnonymous = currentRoom.room.members.find(
            (m) => m.id != opponent.userId
        ).isAnonymousUser;
        if (isCurrentUserAnonymous) {
            return `${opponent?.charityName} (A)`;
        }
        return `${opponent?.fullName}${
            opponent?.isCharityAdmin &&
            opponent?.charityName &&
            !isCurrentUserAnonymous
                ? ` - ${opponent?.charityName}`
                : ''
        }`;
    };

    const sendMessage = (messageText: string, files: Array<number>) => {
        switch (props.selectedRoomState.type) {
            case RoomType.Direct:
                return sendDirectMessage(messageText, files);
            case RoomType.SupportThread:
                return sendSupportMessage(messageText, files);
        }
    };

    const sendDirectMessage = (messageText: string, files: Array<number>) => {
        if (
            props.directHubConnection?.state !==
            signalR.HubConnectionState.Connected
        ) {
            return false;
        }

        dispatch(
            sendDirectRoomMessage({
                messageText,
                roomId: props.selectedRoomState.id,
                connection: props.directHubConnection,
                attachmentsIds: files,
            })
        );
        return true;
    };

    const sendSupportMessage = (messageText: string, files: Array<number>) => {
        if (
            props.supportRequestsHubConnection.state !==
            signalR.HubConnectionState.Connected
        ) {
            return false;
        }

        dispatch(
            sendSupportThreadMessage({
                htmlContent: messageText,
                threadId: props.selectedRoomState.id,
                connection: props.supportRequestsHubConnection,
                attachmentsIds: files,
            })
        );
        return true;
    };

    const likeMessage = (messageId: number) => {
        switch (props.selectedRoomState.type) {
            case RoomType.Direct:
                return addLikeToDirectMessage(messageId);

            case RoomType.SupportThread:
                return addLikeToSupportMessage(messageId);
            default:
                break;
        }
    };

    const addLikeToDirectMessage = (messageId: number) => {
        if (
            props.supportRequestsHubConnection?.state !==
            signalR.HubConnectionState.Connected
        ) {
            return false;
        }

        dispatch(
            likeDirectMessage({
                messageId: messageId,
                connection: props.directHubConnection,
                chatId: props.selectedRoomState.id,
            })
        );
        return true;
    };

    const addLikeToSupportMessage = (messageId: number) => {
        if (
            props.supportRequestsHubConnection?.state !==
            signalR.HubConnectionState.Connected
        ) {
            return false;
        }

        dispatch(
            likeSupportMessage({
                messageId: messageId,
                connection: props.supportRequestsHubConnection,
                chatId: props.selectedRoomState.id,
            })
        );
        return true;
    };

    useEffect(() => {
        setShouldScrollBottom(false);
    }, [props.messages]);

    useEffect(() => {
        setShouldScrollBottom(true);
        showNewMessages();
    }, [props.selectedRoomState]);

    const getRoomOpponent = (room: DirectRoom): RoomMember => {
        switch (props.selectedRoomState.type) {
            case RoomType.Direct:
                let opponent = room?.members?.find(
                    (m) => m.id !== props.currentUserId
                );
                let member = structuredClone(
                    props.members?.find((m) => m.member.userId === opponent?.id)
                );
                const anonymousMember = room.members.find(
                    (m) => m.isAnonymousUser
                );
                if (anonymousMember && member) {
                    member.member.fullName = mapAnonymousName(
                        anonymousMember.anonymousMemberUniqueCode
                    );
                    member.member.firstName = mapAnonymousName(
                        anonymousMember.anonymousMemberUniqueCode
                    );
                    member.member.lastName = '';
                    member.member.initials = '?';
                }
                return member;
            case RoomType.SupportThread:
                return props.members.find(
                    (f) =>
                        f.member.userId !== props.currentUserId &&
                        f.roomType === RoomType.SupportThread
                );
            default:
                break;
        }
    };

    const mapAnonymousName = (uniqueCode: number) => {
        return `Anonymous ${uniqueCode ?? ''}`;
    };

    const isReadonlyMode = () => {
        if (props.selectedRoomState.type === RoomType.SupportThread) {
            const currentRoom = getCurrentRoom();
            return currentRoom.room.status === SupportThreadStatus.Stopped;
        }

        if (
            props.isBlockingRoom(
                props.selectedRoomState.id,
                props.selectedRoomState.type
            )
        ) {
            return true;
        }

        const currentUser = props.members.find(
            (m) => m.member.id == props.currentUserId
        ).member;
        const opponent = getRoomOpponent(getCurrentRoom().room).member;

        if (opponent?.isGiftCardContact || opponent?.isReferralContact) return false;

        if (opponent.isCharityAdmin || currentUser.isCharityAdmin) {
            const isActiveCharityAdmin =
                opponent.charityName || currentUser.charityName;
            return !isActiveCharityAdmin;
        }

        if (
            currentUser.isActiveCharityAffiliate !== null ||
            opponent.isActiveCharityAffiliate !== null
        ) {
            return (
                (currentUser.isActiveCharityAffiliate !== null &&
                    !currentUser.isActiveCharityAffiliate) ||
                (opponent.isActiveCharityAffiliate !=null &&
                    !opponent.isActiveCharityAffiliate)
            );
        }

        const doesOpponentHaveActiveProfile = !!opponent?.profile?.isActive;
        const doesCurrentUserHaveActiveProfile =
            !!currentUser?.profile?.isActive;

        const isOpponentActive =
            opponent?.isActive &&
            (opponent?.profile?.isActive == null ||
                doesOpponentHaveActiveProfile);
        const isCurrentUserActive =
            currentUser?.isActive &&
            (currentUser?.profile?.isActive == null ||
                doesCurrentUserHaveActiveProfile);

        const isRoomActive = isOpponentActive && isCurrentUserActive;
        return !isRoomActive;
    };

    const mapMembers = (): Array<Member> => {
        const room = getCurrentRoom();
        const anonymousMemberId = room.room.members.find(
            (m) => m.isAnonymousUser
        )?.id;
        return props.members.map((m) => ({
            id: m.member.userId,
            clubhouseId: 0,
            ...m.member,
            fullName:
                room.roomType == RoomType.SupportThread
                    ? mapFullName(m.member.lastName)
                    : anonymousMemberId
                    ? mapAnonymousName(
                          room.room.members.find((m) => m.id != currentUserId)
                              .anonymousMemberUniqueCode
                      )
                    : m.member.fullName,
            initials:
                (anonymousMemberId &&
                    anonymousMemberId != currentUserId &&
                    !m.member.isCharityAdmin) ||
                (anonymousMemberId == currentUserId && m.member.isCharityAdmin)
                    ? '?'
                    : m.member.initials,
        }));
    };
    const mapFullName = (lastName: string): string => {
        const supportContext = SupportContextItemPrefixToAdmin.find(
            (f) => f.id === selectedContext
        )?.name;
        if (
            supportContext &&
            props.selectedRoomState.type !== RoomType.Direct
        ) {
            return (
                `${supportContext} ${lastName}` +
                `${
                    SupportSubContexts.find((f) => f.id == selectedContext)
                        ? ' | ' +
                          SupportSubContexts.find(
                              (f) => f.id == selectedContext
                          ).name
                        : ''
                }`
            );
        } else {
            return 'System';
        }
    };

    const setIsNewMessageReceived = (event: any) => {
        switch (props.selectedRoomState.type) {
            case RoomType.Direct:
                dispatch(setIsNewDirectMessageReceived(event.target));
                break;
            case RoomType.SupportThread:
                dispatch(setIsNewSupportMessageReceived(event.target));
                break;
        }
    };

    return (
        <div className="direct-chat">
            <div
                className={
                    props.selectedRoomState.id
                        ? 'rooms-list'
                        : 'rooms-list-only'
                }>
                <DirectRoomsList
                    selectedRoomState={props.selectedRoomState}
                    setSelectedRoomState={(
                        roomId: number,
                        roomType: RoomType
                    ) => {
                        props.setSelectedRoomState(roomId, roomType);
                        if (roomType == RoomType.Direct) {
                            dispatch(setIsSelectedDirectRoom(true));
                            dispatch(setSelectedRoomId(roomId));
                        }

                        if (roomType == RoomType.SupportThread) {
                            dispatch(setIsSelectedDirectRoom(false));
                            dispatch(setSelectedThreadId(roomId));
                        }
                    }}
                    roomItems={props.roomItems}
                    isBlockingRoom={props.isBlockingRoom}
                    members={props.members}
                    currentUserId={props.currentUserId}
                    openMemberProfileModal={(id) =>
                        props.openMemberProfileModal(id)
                    }
                    isNewChannelsLinkVisible={props.isNewChannelsLinkVisible}
                    refresh={props.refresh}></DirectRoomsList>
            </div>
            {props.selectedRoomState.id &&
            getRoomOpponent(getCurrentRoom().room) ? (
                <div className="messages-container">
                    <div className="app-inner-layout__top-pane practice-title">
                        <div className="pane-left">
                            <h4 className="mb-0 practice-mgt-title">
                                {getRoomName()}
                                <div className="post-title">Posts</div>
                            </h4>
                        </div>
                        {getCurrentRoom().room.members.find(
                            (m) => m.id != currentUserId
                        )?.isAnonymousUser ? (
                            <InfoTooltip
                                idText={`anonymity-tooltip-for-admin-${
                                    getCurrentRoom().room.id
                                }`}
                                text={anonymityNoteForAdmin}
                                place="bottom"></InfoTooltip>
                        ) : (
                            []
                        )}
                        {getCurrentRoom().room.members.find(
                            (m) => m.id == currentUserId
                        )?.isAnonymousUser ? (
                            <InfoTooltip
                                idText={`anonymity-tooltip-for-user-${
                                    getCurrentRoom().room.id
                                }`}
                                text={anonymityNoteForUser}
                                place="bottom"></InfoTooltip>
                        ) : (
                            []
                        )}
                        {props.isUserBlocking !== undefined ? (
                            <div className="pane-right">
                                <Button
                                    onClick={() => {
                                        props.onBlockButtonClick();
                                    }}
                                    color="primary">
                                    {props.isUserBlocking ? 'Unblock' : 'Block'}{' '}
                                    Member
                                </Button>
                            </div>
                        ) : (
                            []
                        )}
                    </div>
                    <div className="messages">
                        <Messages
                            updateContent={() => {}}
                            openAttachment={openAttachment}
                            numberOfLikes={null}
                            comparationType={ComparationType.More}
                            authorizedMemberId={props.currentUserId}
                            members={mapMembers()}
                            messages={props.messages}
                            isAdmin={false}
                            loadMessagesPage={loadMessagesPage}
                            loadMessagesPageByLikes={() => {}}
                            isMessageLoading={props.isMessageLoading}
                            resetFilter={() => {}}
                            isPageLoading={props.isPageLoading}
                            showNewMessages={showNewMessages}
                            shouldScrollBottom={shouldScrollBottom}
                            setShouldScrollBottom={setShouldScrollBottom}
                            isNewMessageReceived={props.isNewMessageReceived}
                            hasFirstPage={props.hasFirstPage}
                            setIsNewMessageReceived={(e: any) =>
                                setIsNewMessageReceived(e)
                            }
                            selectedDiscussionId={props.selectedRoomState.id}
                            likeMessage={likeMessage}
                            isReadonlyMode={isReadonlyMode()}
                            onReadNewMessages={props.onReadNewMessages}
                            isDisableKbContent={false}
                            showProfile={(id) => {
                                if (
                                    props.selectedRoomState.type ===
                                    RoomType.Direct
                                ) {
                                    props.openMemberProfileModal(id);
                                }
                            }}></Messages>
                    </div>
                    <MessageInputArea
                        sendMessage={(message: string, files: Array<number>) =>
                            sendMessage(message, files)
                        }
                        discussionId={props.selectedRoomState.id}
                        isKbAvailable={false}
                        isReadonlyMode={isReadonlyMode()}></MessageInputArea>
                </div>
            ) : (
                []
            )}
        </div>
    );
};

export default DirectChat;
