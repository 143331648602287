export interface PracticeOption {
    option: string;
    free: string;
    paid: string;
    tooltip?: string;
}

interface BasePracticeOwnerProps {
    annualSubscription: string;
    freePmgMembershipFee: string;
    paidPmgMembershipFee: string;
}

export interface IndependentPracticeOwnerProps extends BasePracticeOwnerProps {}

export interface CorporateGroupLeaderProps extends BasePracticeOwnerProps {
    monthlySubscription: string;
}

export const independentPracticeOwnerText =
    'Enhanced access to the EcoSystem requires both a paid subscription and verification of ' +
    'your identity as an independent practice owner. Upgrading to an enhanced membership requires that you enter your credit card ' +
    'information. Upon verification as an independent practice owner, your card will be changed annually until such time that you discontinue your enhanced membership.';

export const corporatePracticeLeaderText =
    'Enhanced access to the EcoSystem requires both a paid subscription and verification of ' +
    'your identity as a corporate practice leader. Upgrading to an enhanced membership requires that you enter your credit card information. ' +
    'Upon verification as a corporate practice leader, your card will be changed annually until such time that you discontinue your enhanced membership.';

export const independentPracticeOwnerOptions = (
    props: IndependentPracticeOwnerProps
): PracticeOption[] => [
    {
        option: 'Cost',
        free: 'Free',
        paid: `$${props.annualSubscription}/Year`,
    },
    {
        option: 'Announcements',
        free: 'Included',
        paid: 'Included',
    },
    {
        option: 'Independent Practice Owner Community (IPOC)',
        free: 'NA',
        paid: 'Included',
    },
    {
        option: 'Knowledge Library',
        free: 'Basic Content Included and/or Available',
        paid: 'Basic and Enhanced Content Included and/or Available',
    },
    {
        option: 'Affiliated Members',
        free: 'NA',
        paid: 'Available',
    },
    {
        option: 'Valuation Reports',
        free: 'Monthly F&E Included, CAPVAR available',
        paid: 'Unlimmited F&E Included, APVAR/CAPVAR available',
    },
    {
        option: 'Practice Management Groups (PMGs)',
        free: `Available, $${props.freePmgMembershipFee}/Year`,
        paid: `Available, $${props.paidPmgMembershipFee}/Year`,
    },
    {
        option: 'Current Brokerage Services',
        free: 'Available',
        paid: 'Available',
    },
    {
        option: 'Direct Messaging',
        free: 'Included, limited',
        paid: 'Included, unlimited',
        tooltip:
            'You may direct message with your referees, any gift card provider or recipient, members of a PMG in which you are a member of good standing, any nonprofit which you donate to, and the VetValue EcoSystem administrator.',
    },
    /*Disabled since Sprint 26.5 (Feedback V5) until further notice*/
    // {
    //     option: 'Notifications',
    //     free: 'Included, standard',
    //     paid: 'Included, customizable',
    // },
    {
        option: 'Gift Card Purchases',
        free: 'Available',
        paid: 'Available',
    },
    {
        option: 'Frequency Discounts',
        free: 'Applicable',
        paid: 'Applicable',
    },
];

export const corporatePracticeLeaderOptions = (
    props: CorporateGroupLeaderProps
): PracticeOption[] => [
    {
        option: 'Cost',
        free: 'Free',
        paid: `$${props.annualSubscription}/Year`,
    },
    {
        option: 'Announcements',
        free: 'Included',
        paid: 'Included',
    },
    {
        option: 'Practice Leader Community (PLC)',
        free: 'NA',
        paid: 'Included',
    },
    {
        option: 'Knowledge Library',
        free: 'Basic Content Included and/or Available',
        paid: 'Basic and Enhanced Content Included and/or Available',
    },
    {
        option: 'Affiliated Members',
        free: 'NA',
        paid: 'Available',
    },
    {
        option: 'Valuation Reports',
        free: 'Monthly F&E Included, CAPVAR available',
        paid: 'Unlimmited F&E Included, APVAR/CAPVAR available',
    },
    {
        option: 'Practice Management Groups (PMGs)',
        free: `Available, $${props.freePmgMembershipFee}/Year`,
        paid: `Available, $${props.paidPmgMembershipFee}/Year`,
    },
    {
        option: 'Direct Messaging',
        free: 'Included, limited',
        paid: 'Included, unlimited',
        tooltip:
            'You may direct message with your referees, any gift card provider or recipient, members of a PMG in which you are a member of good standing, any nonprofit which you donate to, and the VetValue EcoSystem administrator.',
    },
    /*Disabled since Sprint 26.5 (Feedback V5) until further notice*/
    // {
    //     option: 'Notifications',
    //     free: 'Included, standard',
    //     paid: 'Included, customizable',
    // },
    {
        option: 'Gift Card Purchases',
        free: 'Available',
        paid: 'Available',
    },
    {
        option: 'Frequency Discounts',
        free: 'Applicable',
        paid: 'Applicable',
    },
];
