import { FormControlLabel, Radio } from '@mui/material';
import React from 'react';

interface ExpandableRadioButtonProps {
    value: any;
    label: string;
    isExpanded: boolean;
    expandableContent: React.JSX.Element | string;
    disabled: boolean;
}

const ExpandableRadioButton = (props: ExpandableRadioButtonProps) => {
    return (
        <>
            <FormControlLabel
                value={props.value}
                control={<Radio />}
                label={props.label}
                disabled={props.disabled}
            />
            {props.isExpanded && props.expandableContent}
        </>
    );
};

export default ExpandableRadioButton;
