import React, { useEffect, useState } from 'react';
import KnowledgeBaseContentTile from '../KnowledgeBaseContentLibrary/KnowledgeBaseContentTile';
import './LibraryItemsWrapperComponent.scss';
import { USER_ROLES, PRACTICE_OWNERSHIP_TYPES } from '../../../utils/constants';
import { ASSOCIATE_MEMBER_RIGHTS_ENUM } from '../../../utils/associateMembersConstants';
import { useStateSelector } from '../../../store/selectors';
import { KnowledgeBaseContent } from '../Models/KnowledgeBaseContent';
import { KnowledgeBaseContentLevel } from '../Models/Enums/KnowledgeBaseContentLevel.enum';

interface LibraryItemsWrapperComponentProps {
    contentList: any[];
    setInitialData(): void;
    isSelectable?: boolean;
    selectItem?(contentId: number): void;
    deselectItem?(contentId: number): void;
    isPremiumContent: boolean;
}

const LibraryItemsWrapperComponent = (
    props: LibraryItemsWrapperComponentProps
) => {
    const limits = useStateSelector((s) => s.currentUser.limits);
    const [isSaveToShelfAvailable, setIsSaveToShelfAvailable] = useState(
        calculateAccessToShelfIfPremiumContent()
    );

    useEffect(() => {
        setIsSaveToShelfAvailable(calculateAccessToShelfIfPremiumContent());
    }, []);

    function calculateAccessToShelfIfPremiumContent(): boolean {
        if (!props.isPremiumContent) {
            return true;
        }

        var userSummary = JSON.parse(
            localStorage.getItem('loggedInUserSummary')
        );
        var roleId = userSummary.roleId;

        if (
            roleId === USER_ROLES.ADMIN ||
            roleId === USER_ROLES.PRACTICE_OWNER
        ) {
            return true;
        }

        if (roleId == USER_ROLES.PRACTICE_SECONDARY_USER) {
            return userSummary.associateMember?.some((x: any) =>
                x.practices.some((p: any) =>
                    p.acceptedAccessRights.includes(
                        ASSOCIATE_MEMBER_RIGHTS_ENUM.KnowledgeBase
                    )
                )
            );
        }

        return false;
    }

    const isKbContentDisabled = (content: KnowledgeBaseContent) => {
        return limits.hasPaidSubscription
            ? false
            : content.contentLevel === KnowledgeBaseContentLevel.General;
    };

    const whetherRenderTile = (content: KnowledgeBaseContent): boolean => {
        const userSummary = JSON.parse(
            localStorage.getItem('loggedInUserSummary')
        );
        const practiceOwnerType = userSummary.practiceOwnerType;

        const isRestrictedType = 
            practiceOwnerType === PRACTICE_OWNERSHIP_TYPES.FORMER || 
            practiceOwnerType === PRACTICE_OWNERSHIP_TYPES.FUTURE;

        const isGeneralContentLevel = content.contentLevel === KnowledgeBaseContentLevel.General;

        return !(isRestrictedType && isGeneralContentLevel);
    }

    return (
        <>
            <div className="library-items-wrapper">
                {props.contentList.map((cont, index): JSX.Element | null =>
                    index % 2 === 0 && whetherRenderTile(cont) ? (
                        <KnowledgeBaseContentTile
                            isSelectable={props.isSelectable}
                            updateTables={() => props.setInitialData()}
                            data={cont}
                            selectItem={(id: any) => props.selectItem(id)}
                            deselectItem={props.deselectItem}
                            isSaveToShelfVisible={isSaveToShelfAvailable}
                            showNotificationFlag={true}
                            isDisableKbContent={isKbContentDisabled(cont)}
                        />
                    ) : null
                )}
            </div>
            <div className="library-items-wrapper">
                {props.contentList.map((cont, index): JSX.Element | null =>
                    index % 2 === 1 && whetherRenderTile(cont) ? (
                        <KnowledgeBaseContentTile
                            isSelectable={props.isSelectable}
                            updateTables={() => props.setInitialData()}
                            data={cont}
                            selectItem={props.selectItem}
                            deselectItem={props.deselectItem}
                            isSaveToShelfVisible={isSaveToShelfAvailable}
                            showNotificationFlag={true}
                            isDisableKbContent={isKbContentDisabled(cont)}
                        />
                    ) : null
                )}
            </div>
        </>
    );
};
export default LibraryItemsWrapperComponent;
