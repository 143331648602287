import { createAsyncThunk } from '@reduxjs/toolkit';
import { DataRequestNotificationsState } from '../data-request-notifications.state';
import { ResetQuestionInfo } from '../data-request-notifications.slice';
import { resetQuestionsNotifications } from '../data-forms-status-mapper';
import { updateTabsState } from '../project-tabs-status-mapper';

export interface ResetQuestionsSeenStatusByIdsArguments {
    resetQuestionsInfo: ResetQuestionInfo[];
    formId: number;
}

export const resetQuestionsSeenStatusByIds = createAsyncThunk(
    'dataRequestNotifications/resetQuestionsSeenStatusByIds',
    (args: ResetQuestionsSeenStatusByIdsArguments, dispatch: any) => {
        const notificationsState =
            dispatch.getState().dataRequestsNotifications;
        const result = resetNotifications(
            args.resetQuestionsInfo,
            args.formId,
            notificationsState
        );

        return result;
    }
);

const resetNotifications = (
    questionsInfo: ResetQuestionInfo[],
    formId: number,
    state: DataRequestNotificationsState
) => {
    const updatedState = { ...state };

    const updatedQuestionsStatus = resetQuestionsNotifications(
        updatedState.questionsSeenStatus,
        questionsInfo,
        formId
    );
    updateTabsState(
        updatedState,
        updatedQuestionsStatus,
        updatedState.tableCellsSeenStatus,
        updatedState.tablesNotificationsStatus
    );

    updatedState.questionsSeenStatus = updatedQuestionsStatus;
    return updatedState;
};
