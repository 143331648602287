import { faFile } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Badge, Button } from 'reactstrap';
import {
    CONTENT_LEVEL,
    KNOWLEDGE_BASE_TYPES,
} from '../../../utils/knowledgeBaseConstants';
import createNotification from '../../../utils/createNotification';
import Helpers from '../../../utils/helper';
import './KnowledgeBaseContentTile.scss';
import KnowledgeBaseContentViewModal from './KnowledgeBaseContentViewModal';
import { USER_ROLES } from '../../../utils/constants';
import { ASSOCIATE_MEMBER_RIGHTS_ENUM } from '../../../utils/associateMembersConstants';
import { useStateSelector } from '../../../store/selectors';
import { NotificationFlag } from '../../../components/NotificationFlag/NotificationFlag';
import { NotificationFlagsCategories } from '../../../models/NotificationFlags/NotificationFlagsCategories';

interface KnowledgeBaseContentTileProps {
    data: any;
    isSaveToShelfVisible: boolean;
    isSelectable: boolean;
    isDisableKbContent: boolean;
    updateTables(): void;
    showNotificationFlag: boolean;
    deselectItem?(contentId: number): void;
    selectItem?(contentId: number): void;
    onContentUpdate?(value: boolean): void;
}

const KnowledgeBaseContentTile = (props: KnowledgeBaseContentTileProps) => {
    const [fileIcon, setFileIcon] = useState(null);
    const axios = useStateSelector((state) => state.core.axios);
    const [isPreviewModalVisible, setIsPreviewModalVisible] = useState(false);
    const [isSelectable] = useState(props.isSelectable);
    const [isContentReadonly, setIsContentReadonly] = useState(
        props.isDisableKbContent && !props.data.isPurchased
    );
    const [data, setData] = useState(props.data);
    const [isUserHasAccessToUpdateShelf, setIsUserHasAccessToUpdateShelf] =
        useState(false);
    const [isSaveToShelfVisible] = useState(props.isSaveToShelfVisible);
    const [isDisableKbContent, setIsDisableKbContent] = useState(
        props.isDisableKbContent && !props.data.isPurchased
    );

    useEffect(() => {
        const loggedInUserSummary = localStorage.getItem('loggedInUserSummary');
        const summary = JSON.parse(loggedInUserSummary);
        const isUserHaveAccess =
            summary.roleId === USER_ROLES.PRACTICE_OWNER ||
            summary.roleId === USER_ROLES.USER ||
            (summary.roleId === USER_ROLES.PRACTICE_SECONDARY_USER &&
                summary.associateMember &&
                summary.associateMember.some((x: any) =>
                    x.practices.some((p: any) =>
                        p.acceptedAccessRights.includes(
                            ASSOCIATE_MEMBER_RIGHTS_ENUM.KnowledgeBase
                        )
                    )
                ));
        if (
            (summary.hasReferralRequest &&
                summary.roleId == USER_ROLES.USER &&
                !summary.practices?.some((s: any) => s.status == 1)) ||
            summary.roleId == USER_ROLES.CHARITY_ADMIN
        ) {
            setIsContentReadonly(true);
        }
        setIsUserHasAccessToUpdateShelf(
            isUserHaveAccess && !isDisableKbContent
        );
    }, []);

    useEffect(() => {
        setData(props.data);
        if (
            (props.data.isAvailable === false || props.isDisableKbContent) &&
            !props.data.isPurchased
        ) {
            setIsContentReadonly(true);
        }
    }, [props]);

    useEffect(() => {
        if (data.shouldBeUpdated) {
            updateContent();
        }
    }, [data]);

    useEffect(() => {
        setFileIcon(
            KNOWLEDGE_BASE_TYPES.find((t) => t.id == props.data.type)?.icon ??
                faFile
        );
    }, [props]);

    const updateContent = () => {
        axios(`api/KnowledgeBase/content/${data.id}`).then((response) => {
            if (!response.data.isError) {
                props.onContentUpdate(response.data.data.isAddedToShelf);
            }
        });
    };

    const openPreviewModal = () => {
        setIsPreviewModalVisible(true);
    };

    const updateMyShelf = (content: any) => {
        if (isUserHasAccessToUpdateShelf) {
            const postObject = {
                contentId: content.id,
                isAddedToShelf: !content.isAddedToShelf,
            };
            axios
                .post('api/KnowledgeBase/update-shelf', postObject)
                .then((response) => {
                    if (
                        Helpers.isResponseSuccessful(
                            response,
                            'An error occured while updating My Shelf.'
                        )
                    ) {
                        createNotification(
                            'My Shelf updated successfully.',
                            'success'
                        );
                        const newData = { ...data };
                        newData.isAddedToShelf = !content.isAddedToShelf;
                        setData(newData);
                        props.updateTables();
                    }
                })
                .catch((error) => {
                    createNotification(error.message, 'error');
                });
        }
    };

    const getNotificationFlag = () => {
        const code = data.contentLevel < 1 ?
            `${NotificationFlagsCategories.KnowledgeLibraryCode}.${data.id}` : ``;

        return (
        <NotificationFlag
            eventCode={`${code}`}
            flagClassName="group-order-nf"></NotificationFlag>
        );
    };

    return (
        <>
            <div
                key={`kb-library-content-${props.data.id}`}
                className={
                    isContentReadonly
                        ? 'content-tile disable'
                        : data.isSelected
                        ? 'content-tile selected-content'
                        : 'content-tile'
                }
                onClick={() => {
                    openPreviewModal();
                }}>
                {props.showNotificationFlag ? getNotificationFlag() : []}
                <div>
                    <div className="productNameImageWrapper">
                        <h6
                            className={
                                isContentReadonly ? 'intro-text-readonly' : ''
                            }>
                            {data.title}
                        </h6>
                        <div className="action-button-wrapper">
                            {data.contentLevel > 0 ? (
                                <>
                                    <h6 className="content-level">
                                        {CONTENT_LEVEL.find(
                                            (l) => l.id == data.contentLevel
                                        )?.name ?? ''}
                                    </h6>
                                </>
                            ) : (
                                []
                            )}
                            {isSelectable ? (
                                <Button
                                    className="select-button"
                                    style={
                                        data.isSelected
                                            ? {
                                                  backgroundColor: '#d92550',
                                              }
                                            : {
                                                  backgroundColor: '#3ac47d',
                                              }
                                    }
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        data.isSelected
                                            ? props.deselectItem(data.id)
                                            : props.selectItem(data.id);
                                    }}>
                                    {data.isSelected ? 'Deselect' : 'Select'}
                                </Button>
                            ) : (
                                []
                            )}
                            <FontAwesomeIcon
                                icon={fileIcon}
                                className="file-icon"
                            />
                        </div>
                    </div>
                    <p className="date_author">
                        {moment(data.postedOn).format('MMM D, YYYY')} | By{' '}
                        {data.author}
                    </p>
                </div>
                <div>
                    <p
                        className={
                            'text-desc' +
                            (isContentReadonly ? ' intro-text-readonly' : '')
                        }>
                        {data.introText}
                    </p>
                    <a className="open-valuation-product">Read More</a>
                </div>
                <div style={{ marginTop: 10 }}>
                    <div className="badgeButtonwrapper">
                        <div className="badgeCollections">
                            {data.topics.map((t: any) => (
                                <Badge className="productBadge">{t.tag}</Badge>
                            ))}
                        </div>
                        <div
                            className="saveExtra"
                            style={
                                isSaveToShelfVisible ? {} : { display: 'none' }
                            }>
                            <div
                                className={
                                    isContentReadonly
                                        ? data.isAddedToShelf
                                            ? 'btnOuterLine noBorder disabled-saveMySelfBtn'
                                            : 'btnOuterLine disabled-saveMySelfBtn'
                                        : data.isAddedToShelf
                                        ? 'btnOuterLine noBorder'
                                        : 'btnOuterLine'
                                }>
                                <Button
                                    className={
                                        isContentReadonly
                                            ? 'saveMySelfBtn btn btn-secondary disabled-saveMySelfBtn'
                                            : 'saveMySelfBtn btn btn-secondary'
                                    }
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        updateMyShelf(data);
                                    }}>
                                    {data.isAddedToShelf
                                        ? 'Remove from'
                                        : 'Save to'}
                                    <span>My Shelf</span>
                                </Button>
                            </div>
                            <span className="saveExtraText">
                                {data.popularity
                                    ? `% Popularity:${' '}
                                ${(data.popularity * 100).toFixed(0)}%${' '}`
                                    : ' '}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            {isPreviewModalVisible && !isContentReadonly ? (
                <KnowledgeBaseContentViewModal
                    updateMyShelf={updateMyShelf}
                    isAddedToShelf={data.isAddedToShelf}
                    data={data}
                    isVisible={isPreviewModalVisible}
                    isDisableKbContent={isDisableKbContent}
                    setIsVisible={
                        setIsPreviewModalVisible
                    }></KnowledgeBaseContentViewModal>
            ) : (
                []
            )}
        </>
    );
};

export default KnowledgeBaseContentTile;
